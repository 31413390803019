import React from 'react';
import PropTypes from 'prop-types';
import IconNike from 'components/shared/icons/icon-nike-verified';
import { Card, ItemTitle, Link, Text } from 'components/shared/uniform';
import { getTwitterProfileLink } from 'utility/twitterUtility';

import styles from './styles.scss';

const renderEssentialListItem = (label, value, verified, link) => {
  if (!value) {
    return null;
  }

  const TextOrLinkComponent = link ? Link : Text;

  return (
    <li key={label} className={styles['essential-item']}>
      <ItemTitle isCaps className={styles['essential-item__label']}>
        {`${label}: `}
      </ItemTitle>
      <TextOrLinkComponent
        className={styles['essential-item__value']}
        href={link}
        target="_blank"
        type="article">
        {value}
      </TextOrLinkComponent>
      {verified && (
      <span className="nike-verified">
        <IconNike/>
      </span>
      )}
    </li>
  );
};

const EssentialsCard = ({ // eslint-disable-line react/no-multi-comp
    graduationYear,
    height,
    heightWeightVerified,
    jersey,
    nikeFootballRating,
    nikeFootballRatingVerified,
    positions,
    twitter,
    weight,
  }) => {
  const rows = [
    renderEssentialListItem('Jersey #', jersey),
    renderEssentialListItem('Position(s)', positions),
    renderEssentialListItem('Height', height, heightWeightVerified),
    renderEssentialListItem('Weight', weight, heightWeightVerified),
    renderEssentialListItem('Nike Football Rating', nikeFootballRating, nikeFootballRatingVerified),
    renderEssentialListItem('Class of', graduationYear),
  ];

  if (twitter) {
    const twitterLink = getTwitterProfileLink(twitter);
    rows.push(renderEssentialListItem('Twitter', '@' + twitter, false, twitterLink));
  }

  // Don't render the card if we don't have any relevant data
  if (!rows.some(r => !!r)) {
    return null;
  }

  return (
    <div className={styles['essentials-card']} data-qa-id="essentials-card">
      <Card>
        <ul>{rows}</ul>
      </Card>
    </div>
  );
};

EssentialsCard.propTypes = {
  graduationYear: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  heightWeightVerified: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  jersey: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  nikeFootballRating: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  nikeFootballRatingVerified: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  positions: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  twitter: PropTypes.string,
  weight: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

export default EssentialsCard;
