import $ from 'jquery';
import _ from 'underscore';
import {
  REQUEST_PROFILE_FOLLOWERS,
  RECEIVE_PROFILE_FOLLOWERS,
  FAIL_PROFILE_FOLLOWERS_REQUEST,
  REQUEST_FOLLOW_FEED_USER,
  RECEIVE_FEED_USER_FOLLOWED,
  FAIL_FEED_USER_FOLLOW_REQUEST,
  REQUEST_UNFOLLOW_FEED_USER,
  RECEIVE_FEED_USER_UNFOLLOWED,
  FAIL_FEED_USER_UNFOLLOW_REQUEST,
} from 'common/actions';

const defaultState = {
  // True if equest has been sent, no response yet
  requesting: false,

  // Last request was successful but returned no results,
  // or last request failed
  allComplete: false,

  // Error object if request not in 200 range
  error: null,

  // Oldest follower datetime (allows for pagination)
  oldestDateTime: null,

  // The follower objects
  users: [],
};

function followers(state = defaultState, action) {
  const newState = $.extend(true, {}, defaultState, state);
  switch (action.type) {
  // Actions around populating the followers list
  case REQUEST_PROFILE_FOLLOWERS:
    newState.requesting = true;
    newState.allComplete = false;
    break;
  case RECEIVE_PROFILE_FOLLOWERS:
    const json = action.payload.json;

    // We are no longer requesting
    newState.requesting = false;

    if (Array.isArray(json)) {
      // Parse dates
      const newUsers = json.map(item => {
        item.dateFollowed = new Date(item.dateFollowed);
        return item;
      });

      // Add new users to existing
      newState.users = _.uniq(newState.users.concat(newUsers), item => item.userId);
      newState.users = _.sortBy(newState.users, item => -item.dateFollowed.getTime());

      // If no new users, mark complete
      if (json.length === 0) {
        newState.allComplete = true;
      }

      // Set oldest datetime
      newState.oldestDateTime = new Date();
      const timestamps = _.map(newState.users, item => item.dateFollowed.getTime());
      const minTime = _.min(timestamps);
      newState.oldestDateTime.setTime(minTime);
    }
    break;
  case FAIL_PROFILE_FOLLOWERS_REQUEST:
    newState.requesting = false;
    newState.allComplete = true;
    newState.error = action.payload;
    break;

  // Actions around following followers of the profile
  case REQUEST_FOLLOW_FEED_USER:
  case REQUEST_UNFOLLOW_FEED_USER:
    if (!action.payload.isCurrentProfile) {
      const affectedFollower = newState.users
        .find(follower => follower.userId === action.payload.feedUserRelatedId);
      if (affectedFollower) {
        affectedFollower.followRequestPending = true;
      }
    }
    break;
  case RECEIVE_FEED_USER_FOLLOWED:
    if (!action.payload.isCurrentProfile) {
      const affectedFollower = newState.users
        .find(follower => follower.userId === action.payload.feedUserRelatedId);
      if (affectedFollower) {
        affectedFollower.followRequestPending = false;
        affectedFollower.currentUserIsFollowing = true;
        affectedFollower.followerCount += 1;
      }
    }
    break;
  case RECEIVE_FEED_USER_UNFOLLOWED:
    if (!action.payload.isCurrentProfile) {
      const affectedFollower = newState.users
        .find(follower => follower.userId === action.payload.feedUserRelatedId);
      if (affectedFollower) {
        affectedFollower.followRequestPending = false;
        affectedFollower.currentUserIsFollowing = false;
        affectedFollower.followerCount -= 1;
      }
    }
    break;
  case FAIL_FEED_USER_FOLLOW_REQUEST:
  case FAIL_FEED_USER_UNFOLLOW_REQUEST:
    if (!action.payload.isCurrentProfile) {
      const affectedFollower = newState.users
        .find(follower => follower.userId === action.payload.feedUserRelatedId);
      if (affectedFollower) {
        affectedFollower.followRequestPending = false;
        affectedFollower.followRequestError = action.payload;
      }
    }
    break;
  default:
    return state;
  }
  return newState;
}

export default followers;
