import React from 'react';
import PropTypes from 'prop-types';
import g11n from 'hudl-g11n';

import { Card, Link, Subhead, Text } from 'components/shared/uniform';
import CardStat from '../CardStat';
import ExpandableLinkList from '../ExpandableLists/ExpandableLinkList';
import ExpandableTextList from '../ExpandableLists/ExpandableTextList';
import IconRecruiter from 'components/shared/icons/icon-recruiter';
import RecruitUpsell from '../RecruitUpsell';
import NoRecruiterAccess from '../NoRecruiterAccess';

const { FormattedMessage, FormattedHTMLMessage } = g11n.ReactIntl;

import styles from './styles.scss';

const AcademicCard = ({ academic, isRecruitDisabled, recruitingSupportGuideUrl }) => {
  if (!academic) {
    return null;
  }

  const headerIcon = recruitingSupportGuideUrl
    ? ''
    : <IconRecruiter className={styles['academic-card_recruiter-icon']} includeTooltip />;

  const header = (
    <div className={styles['academic-card__header']}>
      <Subhead level="default">
        Academic {headerIcon}
      </Subhead>
    </div>
  );

  if (academic.isAccessRestricted) {
    return (
      <div className={styles['academic-card']} data-qa-id="academic-card-restricted">
        <Card header={header}>
          <div className={styles['academic-card__no-access-body']}>
            <NoRecruiterAccess />
          </div>
        </Card>
      </div>
    );
  } else if (academic.shouldUpsellRecruit) {
    return (
      <div className={styles['academic-card']} data-qa-id="academic-card-upsell">
        <Card header={header}>
          <div className={styles['academic-card__no-access-body']}>
            <RecruitUpsell />
          </div>
        </Card>
      </div>
    );
  }

  let cardNotice = null;
  if (recruitingSupportGuideUrl) {
    const noticeTitleKey = isRecruitDisabled
      ? 'profiles.recruit.learn_more_title.opted-out'
      : 'profiles.recruit.learn_more_title.opted-in';
    cardNotice = (
      <div
        className="uni-note uni-note--small uni-note--information uni-margin--one-and-half--btm"
      >
        <IconRecruiter size="small"/>
        <Text level="small" className="uni-margin--quarter--left">
          <FormattedHTMLMessage id={noticeTitleKey} />
          <FormattedMessage
            id="profiles.recruit.learn_more_message"
            values={{
              ctaLink: (
                <Link href={recruitingSupportGuideUrl} target="_blank" type="article">
                  <FormattedMessage id="profiles.recruit.learn_more_cta" />
                </Link>
              ),
            }}
          />
        </Text>
      </div>
    );
  }

  return (
    <div className={styles['academic-card']} data-qa-id="academic-card">
      <Card header={header}>
        {cardNotice}
        <div className={styles['academic-card__body']}>
          <CardStat
            label="GPA"
            value={academic.gpa ?? undefined}
          />
          <CardStat
            label="SAT"
            value={academic.sat ?? undefined}
          />
          <CardStat
            label="ACT"
            value={academic.act ?? undefined}
          />
        </div>
        <div>
          <ExpandableLinkList
              header="Transcripts"
              items={academic.transcripts}
              isDefaultOpen={academic.transcripts && academic.transcripts.length === 1}
            />
            <br/>
          <ExpandableTextList
            header="Academic Awards"
            items={academic.awards}
            isDefaultOpen={academic.awards && academic.awards.length === 1}
          />
        </div>
      </Card>
    </div>
  );
};

AcademicCard.propTypes = {
  academic: PropTypes.object,
  isRecruitDisabled: PropTypes.bool,
  recruitingSupportGuideUrl: PropTypes.string,
};

export default AcademicCard;
