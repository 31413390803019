import _ from 'underscore';
import React from 'react';
import Dialog from '../../shared/dialog/dialog';
import AvatarEditor from 'hudl-react-avatar-editor';
import Slider from './slider';
import { Button, ButtonRow, Note, Text } from 'components/shared/uniform';

import './image-cropper-modal.scss';

class ImageCropperModal extends React.Component {
  static propTypes = {
    onClose: React.PropTypes.func.isRequired,
    title: React.PropTypes.string,
    className: React.PropTypes.string.isRequired,
    recommendedWidth: React.PropTypes.number.isRequired,
    recommendedHeight: React.PropTypes.number.isRequired,
    minWidth: React.PropTypes.number.isRequired,
    minHeight: React.PropTypes.number.isRequired,
    hideCroppedWarning: React.PropTypes.bool,
    isSquare: React.PropTypes.bool,
    onChooseADifferentImage: React.PropTypes.func.isRequired,
    onBeforeSave: React.PropTypes.func.isRequired,
    onSave: React.PropTypes.func.isRequired,
    saveText: React.PropTypes.string.isRequired,
    imageSrc: React.PropTypes.string.isRequired,
    imageId: React.PropTypes.number.isRequired,
    requesting: React.PropTypes.bool,
    error: React.PropTypes.instanceOf(Error),
    imageType: React.PropTypes.string.isRequired,
    user: React.PropTypes.shape({
      userId: React.PropTypes.string.isRequired,
    }),
    saveArgs: React.PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      border: 50,
    };
  }

  componentDidMount() {
    // Wait until image has loaded so we can get all the dimensions
    this.getTrueImageDimensions().then(dimensions => {
      this.dimensions = dimensions;
      this.debouncedResize = _.debounce(this.resizeHandler.bind(this), 200);
      window.addEventListener('resize', this.debouncedResize);
      this.debouncedResize();
    });
  }

  componentWillUnmount() {
    if (this.debouncedResize) {
      window.removeEventListener('resize', this.debouncedResize);
      delete this.debouncedResize;
    }
  }

  getTrueImageDimensions() {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      const dimensions = {};
      img.onload = function onImageLoad() {
        dimensions.width = this.width;
        dimensions.height = this.height;
        resolve(dimensions);
      };
      img.src = this.props.imageSrc;
      // make sure the load event fires for cached images too
      if ( img.complete || img.complete === undefined ) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }

      this.sourceImage = img;
    });
  }

  resizeHandler() {
    let containerWidth;
    let containerHeight;
    let minScale = 1;
    let border = 50;

    if (this.props.isSquare) {
      const maxWidth = Math.min(this.imageContainer.clientWidth - border * 2, this.dimensions.width);
      const maxHeight = Math.min(this.imageContainer.clientHeight - border * 2, this.dimensions.height);
      const minDimension = Math.min(maxWidth, maxHeight);
      containerWidth = minDimension;
      containerHeight = minDimension;
    } else {
      const aspectRatio = this.dimensions.width / this.dimensions.height;
      containerWidth = Math.min(this.imageContainer.clientWidth, this.dimensions.width);
      const desiredContainerHeight = containerWidth / this.props.recommendedWidth * this.props.recommendedHeight;
      containerHeight = Math.min(this.imageContainer.clientHeight - border * 2, this.dimensions.height);
      if (containerHeight > desiredContainerHeight) {
        const diff = containerHeight - desiredContainerHeight;
        border = diff / 2;
        containerHeight = desiredContainerHeight;
      }
      const containerAspectRatio = containerWidth / containerHeight;

      if (containerAspectRatio > aspectRatio) {
        // The image will be stretched to fit the width of the canvas with the top and bottom getting cropped.
        // Lower/raise minScale to make sure the min is equal to entire image.
        minScale = 1;
      }
    }

    const maxScale = Math.min(this.dimensions.height / this.props.minHeight,
      this.dimensions.width / this.props.minWidth);

    let scale = this.state.scale;
    if (scale < 1) {
      scale = 1;
    } else if (scale > maxScale) {
      scale = maxScale;
    }

    this.setState({
      containerWidth,
      containerHeight,
      scale,
      minScale,
      maxScale,
      borderRadius: this.props.isSquare ? containerWidth / 2 : 0,
    });
  }

  setScale(scale) {
    this.setState({
      scale,
    });
  }

  renderSlider() {
    const min = this.state.minScale || 1;
    const max = this.state.maxScale || 1;
    return (
      <Slider
        min={min}
        max={max}
        step={0.1}
        defaultValue={1}
        onChange={this.setScale.bind(this)}
        imageType={this.props.imageType}
      />
    );
  }

  onSave() {
    const rect = this.refs.avatarEditor.getCroppingRect();
    let width = rect.width;
    let x = rect.x;
    if (width > 1) {
      width = 1;
      x = 0;
    }
    if (width + x > 1) {
      x = 1 - width;
    }
    let height = rect.height;
    let y = rect.y;
    if (height > 1) {
      height = 1;
      y = 0;
    }
    if (height + y > 1) {
      y = 1 - height;
    }

    width = width * this.dimensions.width;
    height = height * this.dimensions.height;
    x = x * this.dimensions.width;
    y = y * this.dimensions.height;

    if (width < this.props.minWidth) {
      const diff = this.props.minWidth - width;
      if (diff / 2 < x && x + width + diff / 2 <= this.dimensions.width) {
        x -= diff / 2;
        width += diff;
      } else if (width + diff + x <= this.dimensions.width) {
        width += diff;
        x = 0;
      } else {
        width = Math.min(this.dimensions.width, this.props.minWidth);
        x = this.dimensions.width - width;
      }
    }

    if (height < this.props.minHeight) {
      const diff = this.props.minHeight - height;
      if (diff / 2 < y && y + height + diff / 2 <= this.dimensions.height) {
        y -= diff / 2;
        height += diff;
      } else if (height + diff + y <= this.dimensions.height) {
        height += diff;
        y = 0;
      } else {
        height = Math.min(this.dimensions.height, this.props.minHeight);
        y = this.dimensions.height - height;
      }
    }

    if (this.props.isSquare && Math.round(width) !== Math.round(height)) {
      if (width < height) {
        const diff = height - width;
        y += diff / 2;
        height -= diff;
      } else {
        const diff = width - height;
        x += diff / 2;
        width -= diff;
      }
    }

    const roundedX = Math.round(x);
    const roundedY = Math.round(y);
    const roundedWidth = Math.round(width);
    const roundedHeight = Math.round(height);

    // Build data URL in case this is being staged instead of saved immediately
    this.refs.cropCanvas.width  = roundedWidth;
    this.refs.cropCanvas.height = roundedHeight;
    const context = this.refs.cropCanvas.getContext('2d');
    context.drawImage(
      this.sourceImage,
      roundedX,
      roundedY,
      roundedWidth,
      roundedHeight,
      0,
      0,
      roundedWidth,
      roundedHeight);
    const base64ImageData = this.refs.cropCanvas.toDataURL();
    context.clearRect(0, 0, this.refs.cropCanvas.width, this.refs.cropCanvas.height);
    this.props.onSave({
      imageId: this.props.imageId,
      url: this.props.imageSrc,
      width: roundedWidth,
      height: roundedHeight,
      x: roundedX,
      y: roundedY,
      imageType: this.props.imageType,
      imageParentId: this.props.user.userId,
      croppedDataUrl: base64ImageData,
      ...this.props.saveArgs,
    });
  }

  onImageMouseDown() {
    this.setState({
      imageMouseDown: true,
    });
  }

  onImageMouseUp() {
    this.setState({
      imageMouseDown: false,
    });
  }

  render() {
    let error;
    if (this.state.error) {
      error = <Note type="critical">{this.state.errorReason}</Note>;
    }

    let croppedWarning;
    if (!this.props.hideCroppedWarning) {
      croppedWarning = (
        <p className="image-cropper-additional-info">
          Some areas may be cropped based on screen size.
        </p>
      );
    }

    let saveButtonContents;
    if (this.props.requesting) {
      saveButtonContents = <div className="save-button-spinner"></div>;
    } else {
      saveButtonContents = this.props.saveText;
    }

    let avatarEditor;
    if (this.dimensions) {
      avatarEditor = (
        <AvatarEditor
          key={this.state.containerWidth + 'x' + this.state.containerHeight}
          image={this.props.imageSrc}
          width={this.state.containerWidth || 250}
          height={this.state.containerHeight || 250}
          border={this.state.border}
          borderRadius={this.state.borderRadius || 0}
          color={[233, 233, 233, 0.6]} // RGBA
          scale={this.state.scale || this.state.minScale || 1}
          slice={this.props.isSquare ? null : 'horizontal'}
          onMouseDown={this.onImageMouseDown.bind(this)}
          onMouseUp={this.onImageMouseUp.bind(this)}
          ref="avatarEditor"
        />
      );
    }

    const imageDragCtaStyles = {
      display: this.state.imageMouseDown ? 'none' : 'block',
    };

    const slider = this.renderSlider();
    return (
      <Dialog onClose={this.props.onClose} title={this.props.title || 'Crop Image'}
        className={this.props.className + ' image-cropper-modal'} disableExitOnOverlayClick>
        {error}
        <div className="image-cropper-content" ref={ref => {this.imageContainer = ref;}}>
          {avatarEditor}
          <div className="image-drag-cta" style={imageDragCtaStyles}>
            Drag to Reposition
          </div>
          {slider}
        </div>
        <div className="image-cropper-footer">
          <div className="image-cropper-requirements-text">
            <Text level="micro">
              <p className="image-cropper-min-dimensions">
                Recommended image size is at least{' '}
                {this.props.recommendedWidth} x {this.props.recommendedHeight} pixels.
              </p>
              <p className="image-cropper-max-file-size">
                Maximum file size is 10MB.
              </p>
              {croppedWarning}
            </Text>
          </div>
          <ButtonRow space="half">
            <Button type="cancel" onClick={this.props.onChooseADifferentImage}>
              Cancel
            </Button>
            <Button type="primary" onClick={this.onSave.bind(this)}>
              {saveButtonContents}
            </Button>
          </ButtonRow>
        </div>
        <canvas ref="cropCanvas" style={{display: 'none'}} />
      </Dialog>
    );
  }
}

export default ImageCropperModal;
