import {
  CLEAR_STAGED_UPDATES,
  REQUEST_TEAM_TAGLINE_UPDATE,
  RECEIVE_TEAM_TAGLINE_UPDATED,
  TIMEOUT_TEAM_TAGLINE_UPDATED,
  FAIL_TEAM_TAGLINE_UPDATE,
  UPDATE_TEAM_TAGLINE_ERROR,
} from 'common/actions';

const defaultState = {
  value: '',
  saveSuccess: false,
  saveError: false,
  error: false,
};

function tagline(state = {}, action) {
  const newState = $.extend(true, {}, defaultState, state);
  switch (action.type) {
  case CLEAR_STAGED_UPDATES:
    newState.value = newState.savedValue;
    newState.saveSuccess = false;
    newState.saveError = false;
    break;
  case REQUEST_TEAM_TAGLINE_UPDATE:
    break;
  case RECEIVE_TEAM_TAGLINE_UPDATED:
    newState.value = action.payload.teamTagline;
    newState.savedValue = action.payload.teamTagline;
    newState.saveSuccess = true;
    newState.saveError = false;
    break;
  case FAIL_TEAM_TAGLINE_UPDATE:
    newState.saveSuccess = false;
    newState.saveError = true;
    break;
  case TIMEOUT_TEAM_TAGLINE_UPDATED:
    newState.saveSuccess = false;
    newState.saveError = false;
    break;
  case UPDATE_TEAM_TAGLINE_ERROR:
    newState.error = action.payload.isError;
    if (newState.error) {
      newState.errorMessage = action.payload.errorMessage;
    }
    break;
  default:
    return state;
  }

  return newState;
}

export default tagline;
