import _ from 'underscore';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import classNames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import url from 'url.js';
import { AthleteSharingEntryPoints as RecruitSharingEntryPoints } from '@hudl/hudl-recruit';
import FeedUserTypes from 'common/feed-user-type';
import ImageCropperModal from 'components/shared/image-upload-modal/image-cropper-modal';
import ImageUploadModal from 'components/shared/image-upload-modal/image-upload-modal';
import Notification from '../shared/notification';
import ProfileBanner from 'components/shared/profile-banner';
import ProfileHeader from '../shared/profile-header';
import DeleteHighlightDialog from '../shared/delete-highlight-dialog/delete-highlight-dialog';
import FollowersDialog from '../shared/followers-dialog/followers-dialog';
import MessagingDialog from '../shared/messaging-dialog/messaging-dialog';
import SharingDialog from '../shared/sharing-dialog/sharing-dialog';
import {
  closeDialog,
  openDialog,
  fetchProfileFollowers,
  fetchProfileFollowing,
  followFeedUser,
  unfollowFeedUser,
  tempProfileImageUploaded,
  beforeUserProfileSave,
  updateUserImage,
  tempBannerImageUploaded,
  beginEditPictureUpdate,
  pageView,
  dismissNotification,
  undoNotificationAction,
} from 'common/actions';
import CustomizeProp from 'common/prop-types/customize';
import * as DialogTypes from 'common/dialog-types';
import PersonalizationUtility from 'utility/personalization-utility';
import AdTrackingUtil from 'utility/ad-tracking-utility';
import CommunityContentContainers from 'common/container-type';
import CommunityContentContainerTypes from 'common/container-subtype';
import CommunityContentSections from 'common/container-section';
import CommunityContentPlayers from 'common/container-player';
import DisplayAd from 'components/shared/display-ad';
import GoogleAdRefresher from '../../utility/google-ad-refresher';
import ads from '../../utility/ads';
import { IS_RECRUIT_SHARING_ENABLED } from '../../utility/recruit-utility';
import DisplayAdModel from '../../utility/ads/display-ad-model';
import ToastManager from 'components/shared/toast-manager';
import { MediaSnowplowManager } from '../../utility/page-tracking-utility';
import browserDetect from '../../utility/browser-detect';

class App extends React.Component {
  static propTypes = {
    customize: CustomizeProp.isRequired,
    forcePublicView: React.PropTypes.bool.isRequired,
    canDismissFeedItems: React.PropTypes.bool,
    presetFilterType: React.PropTypes.number,
    isMobile: React.PropTypes.bool,
    isAthleteOptedInToRecruit: React.PropTypes.bool,
    hasHighlightsDisabled: React.PropTypes.bool,
    user: React.PropTypes.shape({
      followCount: React.PropTypes.number.isRequired,
      followingCount: React.PropTypes.number.isRequired,
      graduationYear: React.PropTypes.number,
      heightInInches: React.PropTypes.number,
      jersey: React.PropTypes.string,
      mostRelevantTeam: React.PropTypes.shape({
        locationUrl: React.PropTypes.string,
        profileUrl: React.PropTypes.string,
        schoolLocation: React.PropTypes.string,
        sportId: React.PropTypes.number,
        teamName: React.PropTypes.string,
        teamId: React.PropTypes.string,
      }),
      primaryColor: React.PropTypes.string,
      primaryName: React.PropTypes.string.isRequired,
      profileLogoUri: React.PropTypes.string,
      mobileProfileLogoUri: React.PropTypes.string,
      profileBannerUri: React.PropTypes.string.isRequired,
      mobileProfileBannerUri: React.PropTypes.string.isRequired,
      profileUrlBase: React.PropTypes.string.isRequired,
      secondaryColor: React.PropTypes.string,
      tagline: React.PropTypes.string,
      userId: React.PropTypes.string.isRequired,
      videoViewCount: React.PropTypes.number.isRequired,
      recruiterVideoViewCount: React.PropTypes.number.isRequired,
      recruiter: React.PropTypes.number.isRequired,
      showRecruiterActivityEnabled: React.PropTypes.bool,
      weightInPounds: React.PropTypes.number,
      athleteCommitment: React.PropTypes.object,
    }).isRequired,
    currentDialog: React.PropTypes.shape({
      dialogType: React.PropTypes.oneOf(_.values(DialogTypes)),
      data: React.PropTypes.object,
    }),
    followers: React.PropTypes.shape({
      oldestDateTime: React.PropTypes.instanceOf(Date),
    }).isRequired,
    following: React.PropTypes.shape({
      oldestDateTime: React.PropTypes.instanceOf(Date),
    }).isRequired,
    seoFriendlyName: React.PropTypes.string.isRequired,
    dispatch: React.PropTypes.func.isRequired,
    currentUser: React.PropTypes.object,
    routing: React.PropTypes.shape({
      locationBeforeTransitions: React.PropTypes.shape({
        pathname: React.PropTypes.string.isRequired,
      }),
    }),
    notification: React.PropTypes.shape({
      type: React.PropTypes.string,
      message: React.PropTypes.string,
      active: React.PropTypes.bool,
      showUndo: React.PropTypes.bool,
      undoAction: React.PropTypes.string,
    }),
    children: React.PropTypes.oneOfType([
      React.PropTypes.element,
      React.PropTypes.arrayOf(React.PropTypes.element),
    ]),
    recruiterAccess: React.PropTypes.object,
    mediaSnowplowTrackerContext: React.PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    // Load first batch of followers
    this.props.dispatch(fetchProfileFollowers());
    this.props.dispatch(fetchProfileFollowing());
    if (this.props.currentUser && this.props.currentUser.userId === this.props.user.userId) {
      this.props.dispatch(pageView(this.props.currentUser.userId));
    }

    // Make sure the navbar is showing when changing between tabs.
    // If going back to the timeline, restore the last scroll location.
    browserHistory.listen(location => {
      const header = document.querySelector('.prof-header');
      if (header && header.getBoundingClientRect) {
        const scrollArea = document.querySelector('.parallax');
        const rect = header.getBoundingClientRect();
        const navPosition = rect.bottom + scrollArea.scrollTop;
        const tab = this.getCurrentTab(location);

        if (tab === 'timeline' && this.timelinePosition && this.timelinePosition > navPosition) {
          scrollArea.scrollTop = this.timelinePosition;
        } else if (rect.bottom < 0) {
          scrollArea.scrollTop = navPosition;
        }
      }
    });

    if (this.props.user.mostRelevantTeam.teamId) {
      PersonalizationUtility.addRecentTeamId(this.props.user.mostRelevantTeam.teamId);
    }

    new GoogleAdRefresher([400, 700, 1000, 1055]); // eslint-disable-line no-new
    MediaSnowplowManager.initialize(this.props.mediaSnowplowTrackerContext)
    MediaSnowplowManager.trackPagePings();
    MediaSnowplowManager.trackPage('AthletePage');
  }

  getDialog() {
    if (!this.props.currentDialog) {
      return undefined;
    }

    const dispatch = this.props.dispatch;

    switch (this.props.currentDialog.dialogType) {
    case DialogTypes.NONE:
      return undefined;
    case DialogTypes.DELETE_HIGHLIGHT_DIALOG:
      return (
        <DeleteHighlightDialog
          key="delete-highlight-dialog"
          dispatch={dispatch}
          onClose={() => dispatch(closeDialog(DialogTypes.DELETE_HIGHLIGHT_DIALOG))}
          {...this.props.currentDialog.data}
        />
      );
    case DialogTypes.FOLLOWERS_DIALOG:
      return (
        <FollowersDialog
          key="followers-dialog"
          title="Followers"
          followers={this.props.followers}
          addUserToModal={!!(this.props.currentUser && this.props.currentUser.isFollowing)}
          user={this.props.currentUser}
          onClose={() => dispatch(closeDialog(DialogTypes.FOLLOWERS_DIALOG))}
          onScrollNearBottom={() => dispatch(fetchProfileFollowers(this.props.followers.oldestDateTime))}
          onFollowUnfollow={this.onFollowUnfollow.bind(this)}
        />
      );
    case DialogTypes.FOLLOWING_DIALOG:
      return (
        <FollowersDialog
          key="following-dialog"
          title="Following"
          followers={this.props.following}
          addUserToModal={!!(this.props.currentUser && this.props.currentUser.isFriend)}
          user={this.props.currentUser}
          onClose={() => dispatch(closeDialog(DialogTypes.FOLLOWING_DIALOG))}
          onScrollNearBottom={() => dispatch(fetchProfileFollowing(this.props.following.oldestDateTime))}
          onFollowUnfollow={this.onFollowUnfollow.bind(this)}
        />
      );
    case DialogTypes.SHARE_PROFILE_DIALOG:
      const includeRecruitSharing =
        IS_RECRUIT_SHARING_ENABLED &&
        this.props.currentUser && this.props.currentUser.isPrivilegedUser && this.props.currentUser.isOnTeamInRecruit &&
        this.props.customize && this.props.customize.recruitOptIn && this.props.customize.recruitOptIn.value;
      return (
        <SharingDialog
          key="share-profile-dialog"
          onClose={() => dispatch(closeDialog(DialogTypes.SHARE_PROFILE_DIALOG))}
          user={this.props.currentUser}
          pageUrl={`${window.location.origin}${this.props.user.profileUrlBase}/${this.props.seoFriendlyName}`}
          twitterText={`Check out ${this.props.user.primaryName} on @Hudl`}
          title="Share Profile"
          usageLogData={{
            OwnerId: this.props.user.userId,
            Type: 'User',
          }}
          recruitSharingEntryPoint={includeRecruitSharing && RecruitSharingEntryPoints.AthleteProfile}
          recruitSharingAthleteId={includeRecruitSharing && this.props.user.userId}
        />
      );
    case DialogTypes.EDIT_PROFILE_IMAGE_DIALOG:
      return (
        <ImageUploadModal
          key="image"
          onClose={() => dispatch(closeDialog(DialogTypes.EDIT_PROFILE_IMAGE_DIALOG))}
          onImageUploaded={(imageSrc, imageId) => {
            dispatch(tempProfileImageUploaded(imageSrc, imageId));
            dispatch(openDialog(DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG));
          }}
          title="Choose Your Profile Picture"
          className="edit-user-profile-image-dialog"
          recommendedWidth={400}
          recommendedHeight={400}
          minWidth={180}
          minHeight={180}
          hideCroppedWarning
          isSquare
          errorMessage={this.state.errorMsg}
        />
        );
    case DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG:
      return (
        <ImageCropperModal
          key="image-area"
          title="Crop Photo"
          className="edit-user-profile-image-dialog"
          recommendedWidth={400}
          recommendedHeight={400}
          minWidth={180}
          minHeight={180}
          onClose={() => dispatch(closeDialog(DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG))}
          onChooseADifferentImage={() => dispatch(closeDialog(DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG))}
          onBeforeSave={() => dispatch(beforeUserProfileSave())}
          onSave={(args) => dispatch(updateUserImage(args))}
          saveText="Save"
          imageSrc={this.props.customize.profileImage.tempValue}
          imageId={this.props.customize.profileImage.tempImageId}
          imageType="PROFILE"
          requesting={this.props.customize.profileImage.requesting}
          error={this.props.customize.profileImage.error}
          user={this.props.user}
          saveArgs={{
            userId: this.props.currentUser.userId,
          }}
          isSquare
        />
    );
    case DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG:
      return (
        <ImageCropperModal
          key="banner-image-area"
          title="Crop Banner"
          className="edit-banner-image-dialog"
          recommendedWidth={1500}
          recommendedHeight={400}
          minWidth={1200}
          minHeight={340}
          onClose={() => dispatch(closeDialog(DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG))}
          onChooseADifferentImage={() => dispatch(closeDialog(DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG))}
          onBeforeSave={() => dispatch(beforeUserBannerSave())}
          onSave={(args) => dispatch(updateUserImage(args))}
          saveText="Save"
          imageSrc={this.props.customize.bannerImage.tempValue}
          imageId={this.props.customize.bannerImage.tempImageId}
          imageType="USER_BANNER"
          requesting={this.props.customize.bannerImage.requesting}
          error={this.props.customize.bannerImage.error}
          user={this.props.user}
          saveArgs={{
            userId: this.props.currentUser.userId,
          }}
        />
      );
    case DialogTypes.EDIT_BANNER_IMAGE_DIALOG:
      return (
        <ImageUploadModal
          key="edit-banner"
          onClose={() => dispatch(closeDialog(DialogTypes.EDIT_BANNER_IMAGE_DIALOG))}
          onImageUploaded={(imageSrc, imageId) => {
            dispatch(tempBannerImageUploaded(imageSrc, imageId));
            dispatch(openDialog(DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG));
          }}
          title="Choose Your Banner Picture"
          className="edit-banner-image-dialog"
          recommendedWidth={1500}
          recommendedHeight={400}
          minWidth={1200}
          minHeight={340}
          errorMessage={this.state.errorMsg}
        />
      );
    case DialogTypes.SHARE_VIDEO_DIALOG:
      const dialogData = this.props.currentDialog.data;

      let userNamePossessive = this.props.user.primaryName;
      if (userNamePossessive[-1] === 's') {
        userNamePossessive += '\'';
      } else {
        userNamePossessive += '\'s';
      }

      return (
        <SharingDialog
          key="share-video-dialog"
          onClose={() => dispatch(closeDialog(DialogTypes.SHARE_VIDEO_DIALOG))}
          user={this.props.currentUser}
          pageUrl={window.location.origin + dialogData.url}
          twitterText={dialogData.tweetText || `Check out ${userNamePossessive} ${dialogData.title} on @Hudl`}
          title="Share This Highlight"
          usageLogData={{
            OwnerId: this.props.user.userId,
            UserId: this.props.user && this.props.user.userId,
            OwnerType: 'User',
            Type: dialogData.type,
            Origin: 'UserProfile',
            [(dialogData.type === 'VideoContent') ? 'ContentId' : 'ReelId']: dialogData.id,
          }}
        />
      );
    case DialogTypes.MESSAGING_DIALOG:
      return (
        <MessagingDialog
          key="messaging-dialog"
          userId={this.props.user.userId}
          onClose={() => dispatch(closeDialog(DialogTypes.MESSAGING_DIALOG))}
        />
      );
    default:
      return undefined;
    }
  }

  onFollowUnfollow(feedUserTypeId, feedUserRelatedId, isFollow) {
    const isCurrentProfile = feedUserTypeId === FeedUserTypes.User
      && feedUserRelatedId === this.props.user.userId;

    const logData = {
      adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
      container: CommunityContentContainers.Profile,
      containerType: CommunityContentContainerTypes.User,
      containerSection: CommunityContentSections.Timeline,
      player: CommunityContentPlayers.Unknown,
    };

    if (isFollow) {
      this.props.dispatch(followFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile, logData));
    } else {
      this.props.dispatch(unfollowFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile, logData));
    }
  }

  getNavItems() {
    const navItems = [{
      key: 'profiles.tab_timeline',
      name: 'timeline',
    }];

    // Hide Videos in the navigation if feature privilege is present
    if (!this.props.hasHighlightsDisabled) {
      navItems.push({
        key: 'profiles.tab_highlights',
        name: 'highlights',
      });
    }

    navItems.push({
      key: 'profiles.tab_about',
      name: 'about',
    });

    // Show Games/Contact tab for all recruiters and tab decides content based on access level
    const isRecruiter = this.props.recruiterAccess &&
      (this.props.recruiterAccess.hasRecruiterAccess || this.props.recruiterAccess.canUpgradeForAccess);
    const isVideoOnlyRecruiter = isRecruiter &&
      this.props.recruiterAccess.isVideoOnly && !this.props.recruiterAccess.isAccessRestricted;

    // If the athlete's teams are in Recruit then the athlete/coaches should be able to preview Recruit content
    const isOnTeamInRecruit = this.props.currentUser && this.props.currentUser.isOnTeamInRecruit;

    if (isRecruiter || isOnTeamInRecruit) {
      navItems.push({
        key: 'profiles.tab_games',
        name: 'games',
        isRecruiterOnly: true,
      });

      if (!isVideoOnlyRecruiter) {
        navItems.push({
          key: 'profiles.tab_contact',
          name: 'contact',
          isRecruiterOnly: true,
        });
      }
    }

    return navItems;
  }

  getCurrentTab(location = this.props.routing.locationBeforeTransitions) {
    const path = location ? location.pathname : 'timeline';
    const indexOfLastSlash = path.lastIndexOf('/');
    const result = path.substring(indexOfLastSlash + 1);
    if (!['about', 'highlights', 'contact', 'games'].some(s => s === result)) {
      return 'timeline';
    }
    return result;
  }

  togglePublicView() {
    const loc = url.parse(document.location.href, true);
    if (loc.get && loc.get.forcePublicView) {
      if (loc.get.forcePublicView !== 'false') {
        delete loc.get.forcePublicView;
      } else {
        loc.get.forcePublicView = 'true';
      }
    } else {
      loc.get = loc.get || {};
      loc.get.forcePublicView = 'true';
    }
    if (loc.query) {
      delete loc.query;
    }

    window.location = url.build(loc);
  }

  onChangeTab(name, changeWindowLocation, ignoreSeoFriendlyName) {
    const dispatch = this.props.dispatch;
    let newRoute;
    if (ignoreSeoFriendlyName) {
      newRoute = `/profile/${this.props.user.userId}/${name}`;
    } else {
      newRoute = `/profile/${this.props.user.userId}/${this.props.seoFriendlyName}/${name}`;
    }
    newRoute += document.location.search + document.location.hash;
    if (changeWindowLocation) {
      window.location = window.location.origin + newRoute;
    } else {
      const scrollArea = document.querySelector('.parallax');
      const currentTab = this.getCurrentTab();
      if (currentTab === 'timeline') {
        this.timelinePosition = scrollArea.scrollTop;
      }

      dispatch(routerActions.push(newRoute));
    }
  }

  onFollowersCountClick(e) {
    e.preventDefault();
    this.props.dispatch(openDialog(DialogTypes.FOLLOWERS_DIALOG));
    return false;
  }

  onFollowingCountClick(e) {
    e.preventDefault(e);
    this.props.dispatch(openDialog(DialogTypes.FOLLOWING_DIALOG));
    return false;
  }

  onProfileImageUpload(imageSrc, imageId) {
    const dispatch = this.props.dispatch;
    dispatch(tempProfileImageUploaded(imageSrc, imageId));
    dispatch(openDialog(DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG));
  }

  onBannerImageUpload(imageSrc, imageId) {
    const dispatch = this.props.dispatch;
    dispatch(tempBannerImageUploaded(imageSrc, imageId));
    dispatch(openDialog(DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG));
  }

  beginEditOfPicture(imageType) {
    let authUser = undefined;
    if (this.props.currentUser) {
      authUser = this.props.currentUser.userId;
    }
    const dispatch = this.props.dispatch;
    dispatch(beginEditPictureUpdate(imageType, 'AthletePage', authUser, this.props.user.userId, false));
  }

  onErrorPicture(errorMsg, isProfPicture) {
    this.setState({
      error: true,
      errorMsg,
    });
    if (isProfPicture) {
      this.props.dispatch(openDialog(DialogTypes.EDIT_PROFILE_IMAGE_DIALOG));
    } else {
      this.props.dispatch(openDialog(DialogTypes.EDIT_BANNER_IMAGE_DIALOG));
    }
  }

  getTagline() {
    const { user } = this.props;

    let tagline = user.description ? user.description.trim() : '';
    if (tagline.length) {
      return tagline;
    }

    const divider = ' / ';
    if (user.jersey) {
      tagline = '#' + user.jersey;
    }

    if (user.graduationYear) {
      if (tagline) tagline += divider;
      tagline += 'Class of ' + user.graduationYear;
    }

    if (user.heightInInches) {
      if (tagline) tagline += divider;
      const feet = Math.floor(user.heightInInches / 12);
      const inches = user.heightInInches % 12;
      tagline += `${feet}'${inches}"`;
    }

    if (user.weightInPounds) {
      if (tagline) tagline += divider;
      tagline += `${user.weightInPounds}lbs`;
    }

    if (user.positions) {
      if (tagline) tagline += divider;
      tagline += user.positions;
    }

    return tagline;
  }

  renderMobileAd() {
    const currentTab = this.getCurrentTab();
    const adModel = {
      'about': new DisplayAdModel('div-gpt-ad-1481043502728-0', 'Display_AthleteAbout_Mobile'),
      'highlights': new DisplayAdModel('div-gpt-ad-1455210563910-0', 'Display_AthleteAllVideos'),
      'timeline': new DisplayAdModel('div-gpt-ad-1470663806072-0', 'Display_AthleteTimeline_Mobile'),
    }[currentTab];

    return adModel && (
      <DisplayAd
        id={adModel.getId()}
        isMobile
        sizes={[ads.sizes.MobileLeaderboard]}
        path={adModel.getPath()}
        adSizeMapping={[ads.mappings.mobileLeaderboard, ads.mappings.leaderboard]}
      />
    );
  }

  render() {
    const isLoggedIn = !!(this.props.currentUser && this.props.currentUser.userId);
    const onChangeTab = this.onChangeTab.bind(this);
    const isMobile = this.props.isMobile || browserDetect.isModernIPad;
    const classes = classNames({
      'loggedin': isLoggedIn,
      'mobile': isMobile,
      'prof-flex-height': true,
    });

    return (
      <div className={classes}>
        <div className="parallax-wrapper">
          <div className="parallax">
            <ProfileBanner
              beginEditOfPicture={(e) => this.beginEditOfPicture(e)}
              customize={this.props.customize}
              isLoggedIn={isLoggedIn}
              isMobile={this.props.isMobile}
              isPrivilegedUser={this.props.currentUser && this.props.currentUser.isPrivilegedUser}
              feedUserType={FeedUserTypes.User}
              forcePublicView={this.props.forcePublicView}
              onChangeBannerImage={this.onBannerImageUpload.bind(this)}
              onErrorPicture={(errorMsg, isProfPic) => this.onErrorPicture(errorMsg, isProfPic)}
              profileBannerUri={this.props.user.profileBannerUri}
              mobileProfileBannerUri={this.props.user.mobileProfileBannerUri}
            />
            <ProfileHeader
              forcePublicView={this.props.forcePublicView}
              showRecruiterActivityEnabled={this.props.user.showRecruiterActivityEnabled}
              primaryName={this.props.user.primaryName}
              tagline={this.getTagline()}
              highlightViewCount={this.props.user.videoViewCount}
              highlightRecruiterViewCount={this.props.user.recruiterVideoViewCount}
              followCount={this.props.user.followCount}
              followingCount={this.props.user.followingCount}
              displayFollowingCount
              feedUserType={FeedUserTypes.User}
              feedUserId={this.props.user.userId}
              isMobile={this.props.isMobile}
              isAthleteOptedInToRecruit={this.props.isAthleteOptedInToRecruit}
              isUserProfile
              jersey={this.props.user.jersey}
              location={this.props.user.mostRelevantTeam && this.props.user.mostRelevantTeam.schoolLocation}
              locationUrl={this.props.user.mostRelevantTeam && this.props.user.mostRelevantTeam.locationUrl}
              primaryTeam={{
                name: this.props.user.mostRelevantTeam && this.props.user.mostRelevantTeam.teamName,
                profileUrl: this.props.user.mostRelevantTeam && this.props.user.mostRelevantTeam.profileUrl,
                sportId: this.props.user.mostRelevantTeam && this.props.user.mostRelevantTeam.sportId,
              }}
              profileColor={this.props.user.primaryColor}
              secondaryColor={this.props.user.secondaryColor}
              profileLogoUri={this.props.user.profileLogoUri}
              mobileProfileLogoUri={this.props.user.mobileProfileLogoUri}
              user={this.props.currentUser}
              onErrorPicture={(errorMsg, isProfPic) => this.onErrorPicture(errorMsg, isProfPic)}
              onFollowersCountClick={(e) => this.onFollowersCountClick(e)}
              onFollowingCountClick={(e) => this.onFollowingCountClick(e)}
              onMessagingButtonClick={() => this.props.dispatch(openDialog(DialogTypes.MESSAGING_DIALOG))}
              onShareButtonClick={() => this.props.dispatch(openDialog(DialogTypes.SHARE_PROFILE_DIALOG))}
              onPublicViewButtonClick={this.togglePublicView}
              onEditProfileClick={() => window.location.href = `/athlete/${this.props.user.userId}/manage`}
              onFollowUnfollow={this.onFollowUnfollow.bind(this)}
              isPrivilegedUser={this.props.currentUser && this.props.currentUser.isPrivilegedUser}
              currentTab={this.getCurrentTab()}
              beginEditOfPicture={(e) => this.beginEditOfPicture(e)}
              onChangeProfileImage={this.onProfileImageUpload.bind(this)}
              onChangeTab={onChangeTab}
              navItems={this.getNavItems()}
              customize={this.props.customize}
              athleteCommitment={this.props.athleteCommitment}
            >
              {React.cloneElement(this.props.children, Object.assign({ onChangeTab: onChangeTab }, this.props))}
            </ProfileHeader>
          </div>
        </div>
        <ReactCSSTransitionGroup transitionName="react-modal" transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
          {this.getDialog()}
        </ReactCSSTransitionGroup>
        {this.props.notification && this.props.notification.active
          ? <Notification
              type={this.props.notification.type}
              onDismiss={() => this.props.dispatch(dismissNotification())}
              onUndo={() => this.props.dispatch(undoNotificationAction(this.props.notification.undoAction))}
              showUndo={this.props.notification.showUndo}>
              {this.props.notification.message}
            </Notification>
          : null}
        {isMobile && this.renderMobileAd()}
        <ToastManager />
      </div>
    );
  }
}

function select(state) {
  return state;
}

export default connect(select)(App);
