export function getSportByNumber(sport) {
  switch (sport) {
  case 1:
    return 'football';
  case 2:
    return 'basketball';
  case 3:
    return 'wrestling';
  case 4:
    return 'volleyball';
  case 5:
    return 'baseball';
  case 6:
    return 'soccer';
  case 7:
    return 'lacrosse';
  case 8:
    return 'golf';
  case 9:
    return 'gymnastics';
  case 10:
    return 'softball';
  case 11:
    return 'swimming';
  case 12:
    return 'trackandfield';
  case 13:
    return 'hockey';
  case 14:
    return 'fieldhockey';
  case 15:
    return 'waterpolo';
  case 16:
    return 'cheer';
  case 17:
    return 'dance';
  case 18:
    return 'cricket';
  case 21:
    return 'rugby';
  case 22:
    return 'tennis';
  default:
    return 'othersport';
  }
}

export function getSportByName(sport) {
  const sportName = sport ? sport : 'other';
  switch (sportName.toLowerCase()) {
  case 'football':
    return 'football';
  case 'basketball':
    return 'basketball';
  case 'wrestling':
    return 'wrestling';
  case 'volleyball':
    return 'volleyball';
  case 'baseball':
    return 'baseball';
  case 'soccer':
    return 'soccer';
  case 'lacrosse':
    return 'lacrosse';
  case 'golf':
    return 'golf';
  case 'gymnastics':
    return 'gymnastics';
  case 'softball':
    return 'softball';
  case 'swimminganddiving':
    return 'swimming';
  case 'track':
    return 'trackandfield';
  case 'track & field':
    return 'trackandfield';
  case 'icehockey':
    return 'hockey';
  case 'fieldhockey':
    return 'fieldhockey';
  case 'waterpolo':
    return 'waterpolo';
  case 'cheerandspirit':
    return 'cheer';
  case 'danceanddrill':
    return 'dance';
  case 'cricket':
    return 'cricket';
  case 'rugby':
    return 'rugby';
  case 'tennis':
    return 'tennis';
  default:
    return 'othersport';
  }
}
