/**
 * React Smooth Modal Dialog
 *
 * This is a React equivalent of the Marionette smooth-modal dialog. It's recommended to wrap this component
 * within a custom dialog component, all within a ReactCSSTransitionGroup. The transition group must stay in
 * the DOM for enter and leave transitions to work.
 *
 * @param {function} onClose - A callback function which removes this dialog from the DOM.
 * @param {array} [buttons] - Optional array of footer buttons as React elements.
      The parent should attach any handlers to them.
 * @param {string} [className] - Optional string of class names to apply to this dialog
 * @param {string} [title] - Optional title text for the dialog header bar
 *
 * Example:
 * <ReactCSSTransitionGroup transitionName="smooth-modal">
 *   <MyCustomDialog key="some-unique-key"/>
 * </ReactCSSTransitionGroup>
 *
 * [MyCustomDialog.jsx]
 * <Dialog title="Example header" buttons={[ <button>...</button>, ... ]} onClose={this.closeDialog}>
 *   Dialog body goes here.
 * </Dialog>
 */
import $ from 'jquery';
import React from 'react';
import './dialog.scss';
import { disablePageScroll, enablePageScroll } from 'utility/display-helpers';
import { Icon } from 'components/shared/uniform';

class Dialog extends React.Component {
  static propTypes = {
    buttons: React.PropTypes.arrayOf(React.PropTypes.element),
    children: React.PropTypes.any,
    className: React.PropTypes.string,
    onClose: React.PropTypes.func.isRequired,
    title: React.PropTypes.string,
    disableExitOnOverlayClick: React.PropTypes.bool,
  }

  componentDidMount() {
    disablePageScroll();
    this.onKeyUpBound = this.onKeyUp.bind(this);
    $(document).on('keyup', this.onKeyUpBound);
  }

  componentWillUnmount() {
    enablePageScroll();
    $(document).off('keyup', this.onKeyUpBound);
  }

  overlayClick(e) {
    // Only perform if it event hasn't bubbled
    if (e.target === e.currentTarget && !this.props.disableExitOnOverlayClick) {
      this.props.onClose();
    }
  }

  onKeyUp(e) {
    // on escape key
    if (e.which === 27) {
      this.props.onClose();
    }
  }

  getFooter() {
    if (this.props.buttons) {
      return (
        <footer>
          {this.props.buttons}
        </footer>
      );
    }

    return null;
  }

  render() {
    return (
      <div className={`react-modal-overlay ${this.props.className || ''}`} onClick={this.overlayClick.bind(this)}>
        <div className="react-modal">
          <header>
            <h3 className="uni-headline--3">{this.props.title}</h3>
            <div className="uni-dismiss uni-modal__close" onClick={this.props.onClose}>
              <Icon size="large" type="uidismiss" />
            </div>
          </header>
          <div className="react-modal-content">
            {this.props.children}
          </div>
          {this.getFooter()}
        </div>
      </div>
    );
  }
}

export default Dialog;
