import React from 'react';
import { AvatarTeam, Button, Subhead, Text } from 'components/shared/uniform';
import { getSportByName } from 'components/shared/uniform/sport-helpers';

class TeamHistory extends React.Component {
  static propTypes = {
    user: React.PropTypes.shape({
      description: React.PropTypes.string,
      jersey: React.PropTypes.string,
      positions: React.PropTypes.string,
      primaryName: React.PropTypes.string.isRequired,
      firstName: React.PropTypes.string,
      lastName: React.PropTypes.string,
      profileBannerUri: React.PropTypes.string.isRequired,
      profileLogoUri: React.PropTypes.string,
      profileUrlBase: React.PropTypes.string.isRequired,
      teams: React.PropTypes.arrayOf(React.PropTypes.shape({
        name: React.PropTypes.string.isRequired,
        location: React.PropTypes.string.isRequired,
        logoUri: React.PropTypes.string,
        primaryColor: React.PropTypes.string,
        secondaryColor: React.PropTypes.string,
        startYear: React.PropTypes.number,
        endYear: React.PropTypes.number,
        profileUrl: React.PropTypes.string.isRequired,
        sportName: React.PropTypes.string,
        teamId: React.PropTypes.string.isRequired,
      })),
      userId: React.PropTypes.string,
      featuredReelId: React.PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      teamNumber: 3,
      viewLess: false,
    };
  }

  onClickMoreButton = () => {
    this.setState({
      teamNumber: this.totalTeams,
      viewLess: true,
    });
  }

  onClickLessButton = () => {
    this.setState({
      teamNumber: 3,
      viewLess: false,
    });
  }

  renderTeams() {
    let teams = this.props.user.teams;
    if (!this.props.user.teams || !this.props.user.teams.length) {
      return null;
    }
    this.moreTeams = teams.length > 3;
    if (teams.length > 3) {
      teams = teams.slice(0, this.state.teamNumber);
    }
    return (
      <ul className="col-team-list team-history">
        {teams.map(team => {
          let seasonYears = team.startYear;
          if (team.endYear !== team.startYear) {
            seasonYears += ' - ' + team.endYear;
          }

          return (
            <li key={team.teamId}>
              <a href={team.profileUrl} className="team-container">
                <AvatarTeam
                  className="uni-margin--half--right"
                  size="large"
                  imageUrl={team.logoUri}
                  sport={getSportByName(team.sportName)}
                  primaryColor={team.primaryColor}
                  secondaryColor={team.secondaryColor} />
                <div className="team-text">
                  <span href={team.profileUrl} className="team-name">
                    <Subhead level="subtle">{team.name}</Subhead>
                  </span>
                  <div className="team-subtext uni-margin--quarter--top">
                    <Text level="small">{team.location.trim()}</Text>
                    <Text level="micro" color="nonessential">{seasonYears}</Text>
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
  render() {
    if (!this.props.user.teams || !this.props.user.teams.length) {
      return null;
    }
    return (
      <div className="teams">
        <Subhead className="uni-margin--one--btm">Team History</Subhead>
        {this.renderTeams()}
        {this.moreTeams ?
          (<div style={{ 'textAlign': 'right' }}>
            <Button
              size="small"
              type="subtle"
              style="minimal"
              icon={this.state.viewLess ? 'uicollapseregionvertical' : 'uiexpandregionvertical'}
              onClick={this.state.viewLess ? this.onClickLessButton : this.onClickMoreButton}>
              {this.state.viewLess ? 'Show Less' : 'Show More'}
            </Button>
          </div>)
          : null}
      </div>
    );
  }
}
export default TeamHistory;
