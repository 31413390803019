import getParsedFontSize from './get-parsed-font-size';
import Mapping from './mapping';

function normalizedSizeMapping(browser) {
  const fontSize = getParsedFontSize();

  // Just normalize the x breakpoint - that is what the styling bases off of
  return [
    Math.floor(browser[0] * fontSize / 16),
    browser[1],
  ];
}

function normalizedSizeMappings(mappings) {
  return mappings.map(x => {
    return new Mapping(normalizedSizeMapping(x.browser), x.ad);
  });
}

export default normalizedSizeMappings;
