import { combineReducers } from 'redux';
import bannerImage from './banner-image-reducer';
import color from './color-reducer';
import profileImage from './profile-image-reducer';
import tagline from './tagline-reducer';

const customize = combineReducers({
  bannerImage,
  color,
  profileImage,
  tagline,
  recruitOptIn: state => state || null,
});

export default customize;
