import React from 'react';
import UsageLogger from 'common/usage-logger';
import FeedUserLogger from 'utility/feed-user-logger';
import Dialog from '../dialog/dialog';
import Clipboard from 'clipboard';
import { Button, Subhead, Text } from 'components/shared/uniform';
import {
  AthleteSharingForm as RecruitSharingForm,
  AthleteSharingProvider as RecruitSharingProvider,
} from '@hudl/hudl-recruit';

import './sharing-dialog.scss';

const _gaq = window._gaq || [];

class SharingDialog extends React.Component {
  static propTypes = {
    onClose: React.PropTypes.func.isRequired,
    user: React.PropTypes.shape({
      userId: React.PropTypes.string,
      isPrivilegedUser: React.PropTypes.boolean,
    }),
    title: React.PropTypes.string.isRequired,
    twitterText: React.PropTypes.string.isRequired,
    pageUrl: React.PropTypes.string.isRequired,
    usageLogData: React.PropTypes.object,
    recruitSharingEntryPoint: React.PropTypes.number,
    recruitSharingAthleteId: React.PropTypes.string,
  }

  constructor() {
    super();
    this.state = {};
    this.usageLogger = new UsageLogger();
  }

  componentDidMount() {
    this.clipboard = new Clipboard('#share-modal__url-share-button', {
      text: () => { return this.props.pageUrl; },
    });

    this.clipboard.on('success', this.onCopyUrl.bind(this));

    this.clipboard.on('error', () => {
      this.setState({ copyError: true }, () => {
        this.selectUrl();
      });
    });
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  logShare(network, text, hashtags) {
    const logData = {
      PageUrl: this.props.pageUrl,
      Network: network,
      UserId: this.props.user && this.props.user.userId,
      ...this.props.usageLogData,
    };
    if (text) {
      logData.Text = text;
    }
    if (hashtags) {
      logData.HashTags = hashtags;
    }
    this.usageLogger.logShare(logData);

    const ownerId = this.props.usageLogData && this.props.usageLogData.OwnerId;
    FeedUserLogger.logProfileShare(ownerId, network);
  }

  onCopyUrl() {
    this.setState({ urlCopied: true });
    setTimeout(() => {
      this.setState({ urlCopied: false });
    }, 800);

    this.logShare('CopyLink');
  }

  selectUrl = () => {
    this.refs.url.select();
  }

  shareFacebook = () => {
    const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.props.pageUrl);
    const windowName = 'facebook_share_dialog';
    const features = 'width=626,height=436';
    window.open(url, windowName, features);
    _gaq.push(['_trackSocial', 'facebook', 'share', this.props.pageUrl]);

    this.logShare('Facebook');
  }

  shareTwitter = () => {
    const text = this.props.twitterText;
    const hashtags = 'hudl';
    const pageUrl = encodeURIComponent(this.props.pageUrl);
    const url = `http://twitter.com/share?url=${pageUrl}&text=${encodeURIComponent(text)}&hashtags=${hashtags}`;
    const windowName = 'twitterwindow';
    const features = 'height=450, width=550';
    window.open(url, windowName, features);
    _gaq.push(['_trackSocial', 'twitter', 'tweet', this.props.pageUrl]);

    this.logShare('Twitter', text, hashtags);
  }

  render() {
    const includeRecruitSharing = !!this.props.recruitSharingAthleteId && !!this.props.recruitSharingEntryPoint;
    return (
      <Dialog
        onClose={this.props.onClose}
        title={this.props.title}
        className={'sharing-dialog'}
      >
        <div className={'ko-field copy-field' + (this.state.copyError ? '' : ' hidden')}>
          <input type="text" readOnly value={this.props.pageUrl} onClick={this.selectUrl} ref="url"/>
        </div>
        {
          includeRecruitSharing && (
            <div className="sharing-content--public">
              <Subhead>Share Public Profile</Subhead>
              <div className="sharing-content--public-subtitle">
                <Text level="small" color="subtle">
                  Public profile viewers can only see highlights and basic information.
                </Text>
              </div>
            </div>
          )
        }
        <div className="sharing-content">
          <Button
            type="secondary"
            style="minimal"
            size="small"
            collapsePadding
            icon="share"
            text="Share on Facebook"
            onClick={this.shareFacebook}
            qaId="share-modal__facebook-share-button"
          />
          <Button
            type="secondary"
            style="minimal"
            size="small"
            collapsePadding
            icon="share"
            text="Share on Twitter"
            onClick={this.shareTwitter}
            qaId="share-modal__twitter-share-button"
          />
          <div id="share-modal__url-share-button">
            <Button
              type="secondary"
              style="minimal"
              size="small"
              collapsePadding
              icon="edit" // Uniform V4 has a "Copy" icon, which can replace this after Profiles is upgraded
              text="Copy the URL"
              status={this.state.urlCopied ? 'success' : ''}
              qaId="share-modal__url-share-button"
            />
          </div>
        </div>
        {
          includeRecruitSharing && (
            <div className="sharing-content--recruit">
              <RecruitSharingProvider
                athleteId={this.props.recruitSharingAthleteId}
                entryPoint={this.props.recruitSharingEntryPoint}
              >
                <RecruitSharingForm />
              </RecruitSharingProvider>
            </div>
          )
        }
      </Dialog>
    );
  }
}

export default SharingDialog;
