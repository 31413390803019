import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StatusProvider, SaveProspectButton as RecruitSaveProspectButton } from '@hudl/hudl-recruit';
import { checkCanSaveProspect } from 'utility/recruit-utility';

const SaveProspectButton = ({ canSaveProspect, recruiterAccess }) => {
  if (!canSaveProspect) {
    return null;
  }

  return (
    <StatusProvider prospectId={recruiterAccess.athleteUserId} recruitTeamId={recruiterAccess.teamIdForSavingProspect}>
      <RecruitSaveProspectButton />
    </StatusProvider>
  );
};

SaveProspectButton.propTypes = {
  canSaveProspect: PropTypes.bool,
  recruiterAccess: PropTypes.shape({
    athleteUserId: PropTypes.string,
    teamIdForSavingProspect: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  return {
    canSaveProspect: checkCanSaveProspect(state),
    recruiterAccess: state.recruiterAccess,
  };
};

export default connect(mapStateToProps)(SaveProspectButton);
