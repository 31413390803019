import { UsageLogEvent } from 'hudl-base';
import { model } from 'hudl-base';
import ProfileType from 'common/container-subtype';
const clientModel = model.get();

const navItems = ['Timeline', 'Highlights', 'Team', 'Schedule', 'Videos', 'About', 'Games', 'Contact'];

export const UserRoles = {
  Athlete: 'Athlete',
  Coach: 'Coach',
  Recruiter: 'Recruiter',
  Public: 'Public',
};

function getAdditionalLogData() {
  const additionalLogData = {
    ...(clientModel.deviceInfoForLogging || {}),
  };

  return additionalLogData;
}

class UsageLogger {
  logBeginEditPicture(imageType, profileType, currentUser, profileId, isTeam) {
    if (isTeam) {
      new UsageLogEvent({
        logger: 'Profile',
        eventName: 'ImageEditing',
        data: {
          ...(getAdditionalLogData()),
          op: profileType,
          func: 'StartEdit' + imageType,
          authUser: currentUser,
          team: profileId,
        },
      }).log();
    } else {
      new UsageLogEvent({
        logger: 'Profile',
        eventName: 'ImageEditing',
        data: {
          ...(getAdditionalLogData()),
          op: profileType,
          func: 'StartEdit' + imageType,
          authUser: currentUser,
          athlete: profileId,
        },
      }).log();
    }
  }

  logCompleteEditPicture(imageType, profileType, currentUser, profileId, isTeam) {
    if (isTeam) {
      new UsageLogEvent({
        logger: 'Profile',
        eventName: 'ImageEditing',
        data: {
          ...(getAdditionalLogData()),
          op: profileType,
          func: 'CompleteEdit' + imageType + 'Picture',
          authUser: currentUser,
          team: profileId,
        },
      }).log();
    } else {
      new UsageLogEvent({
        logger: 'Profile',
        eventName: 'ImageEditing',
        data: {
          ...(getAdditionalLogData()),
          op: profileType,
          func: 'CompleteEdit' + imageType + 'Picture',
          authUser: currentUser,
          athlete: profileId,
        },
      }).log();
    }
  }

  trackPageView() {
    // This version of google analytics is deprecated as is
    // When updated, we should use a library like https://github.com/mozilla/react-ga
    if (window._gaq && window._gaq.push) {
      window._gaq.push(['_trackPageview', window.location.pathname]);
    }
  }

  logHudlAccountLocation(location, hudlAccountId, isPerformanceCenterAccount, logGoogleAnalytics) {
    const path = location ? location.pathname : 'timeline';
    const indexOfLastSlash = path.lastIndexOf('/');
    let tab = path.substring(indexOfLastSlash + 1);

    // We redirect from /page/vanity-url/timeline to just /page/vanity-url so don't log on the initial time
    if (tab === 'timeline') return;

    // We redirect from /page/vanity-url/library to /page/vanity-url/videos so don't log on the initial time
    if (tab === 'library') return;

    // If home, that means we're at the timeline
    if (!navItems.find(name => name.toLowerCase() === tab)) {
      tab = 'timeline';
    }
    // Capitalize first character to match rest of log arguments
    tab = tab.charAt(0).toUpperCase() + tab.slice(1);

    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileView',
      data: {
        ...(getAdditionalLogData()),
        op: 'HudlAccountPage',
        func: 'View',
        type: isPerformanceCenterAccount ? 'PerformanceCenter' : 'Hudl',
        hudlAccount: hudlAccountId,
        tab,
      },
    }).log();

    if (logGoogleAnalytics) {
      this.trackPageView();
    }
  }

  logTeamLocation(location, teamId, forcePublicView, logGoogleAnalytics) {
    const path = location ? location.pathname : 'timeline';
    const indexOfLastSlash = path.lastIndexOf('/');
    let tab = path.substring(indexOfLastSlash + 1);

    // We redirect from /team/v2/12345/seo-friendly-name/timeline to just
    // /team/v2/12345/seo-friendly-name so don't log on the initial time
    if (tab === 'timeline') return;

    // We redirect from /team/v2/12345/seo-friendly-name/videos to
    // /team/v2/12345/seo-friendly-name/highlights so don't log on the initial time
    if (tab === 'videos') return;

    // If home, that means we're at the timeline
    if (!navItems.find(name => name.toLowerCase() === tab)) {
      tab = 'timeline';
    }
    // Capitalize first character to match rest of log arguments
    tab = tab.charAt(0).toUpperCase() + tab.slice(1);

    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileView',
      data: {
        ...(getAdditionalLogData()),
        op: 'TeamPage',
        func: 'View',
        publicView: forcePublicView ? 'True' : 'False',
        team: teamId,
        tab,
      },
    }).log();

    if (logGoogleAnalytics) {
      this.trackPageView();
    }
  }

  logUserLocation(location, userId, isPublic, logGoogleAnalytics, isInitialLoad, role) {
    const path = location ? location.pathname : 'timeline';
    const indexOfLastSlash = path.lastIndexOf('/');
    let tab = path.substring(indexOfLastSlash + 1);

    // We redirect from /profile/12345/seo-friendly-name/timeline to just
    // /profile/12345/seo-friendly-name so don't log on the initial time
    if (tab === 'timeline') return false;

    // We redirect from /profile/12345/seo-friendly-name/videos to
    // /profile/12345/seo-friendly-name/highlights so don't log on the initial time
    if (tab === 'videos') return false;

    // If home, that means we're at the timeline
    if (!navItems.find(name => name.toLowerCase() === tab)) {
      tab = 'timeline';
    }
    // Capitalize first character to match rest of log arguments
    tab = tab.charAt(0).toUpperCase() + tab.slice(1);

    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileView',
      data: {
        ...(getAdditionalLogData()),
        op: 'AthletePage',
        func: 'View',
        publicView: isPublic ? 'True' : 'False',
        profileOwner: userId,
        tab,
        isInitialLoad,
        role,
      },
    }).log();

    if (logGoogleAnalytics) {
      this.trackPageView();
    }

    return true;
  }

  logShare(sharingData) {
    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileShare',
      data: {
        ...(getAdditionalLogData()),
        op: 'Profile',
        func: 'Share',
        ...sharingData,
      },
    }).log();
  }

  logHighlightFilter(originalFilter, newFilter, profileType, tabType = 'Highlights') {
    let oFilter = null;
    let nFilter = null;
    if (originalFilter === 'all') {
      oFilter = 'AllHighlights';
    } else if (originalFilter === 'premium') {
      oFilter = 'HighlightReels';
    } else {
      oFilter = 'Season';
    }

    if (newFilter === 'all') {
      nFilter = 'AllHighlights';
    } else if (newFilter === 'premium') {
      nFilter = 'HighlightReels';
    } else {
      nFilter = 'Season';
    }

    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileHighlightFilter',
      data: {
        ...(getAdditionalLogData()),
        op: profileType,
        func: 'ChangeFiltering',
        tab: tabType,
        originalFilter: oFilter,
        newFilter: nFilter,
      },
    }).log();
  }

  logHighlightSort(originalSorting, newSorting, profileType, tabType = 'Highlights') {
    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileHighlightSort',
      data: {
        ...(getAdditionalLogData()),
        op: profileType,
        func: 'ChangeSorting',
        tab: tabType,
        originalSorting: originalSorting === 'recent' ? 'MostRecent' : 'MostViewed',
        newSorting: newSorting === 'recent' ? 'MostRecent' : 'MostViewed',
      },
    }).log();
  }

  logGamePreviewClick(userId, teamId, eventId) {
    new UsageLogEvent({
      logger: 'Profile',
      eventName: 'ProfileGamePreviewClick',
      data: {
        ...(getAdditionalLogData()),
        op: 'ProfileGamePreview',
        func: 'Click',
        profileOwner: userId || teamId,
        profileType: userId ? ProfileType.User : ProfileType.Team,
        gameEvent: eventId,
      },
    }).log();
  }

  logHighlightTutorialClick() {
    new UsageLogEvent({
        logger: 'Profile',
        eventName: 'ProfileHighlightTutorialClick',
        data: {
          ...(getAdditionalLogData()),
          op: 'ProfileHighlightTutorial',
          func: 'Click',
          url: window.location.href,
        },
      }).log();
  }
}

export default UsageLogger;
