import { connect } from 'react-redux';
import AcademicCard from './AcademicCard';

const mapStateToProps = (state) => {
  return {
    academic: state.about.academic,
    isRecruitDisabled: state.currentUser.isPrivilegedUser && !state.customize.recruitOptIn.value,
    recruitingSupportGuideUrl: state.recruitingSupportGuideUrl,
  };
};

export default connect(mapStateToProps)(AcademicCard);
