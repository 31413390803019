import React from 'react';
import _ from 'underscore';
import embeddedData from 'hudl-base/src/embedded-data';

import ButtonGroup from 'components/shared/button-group/button-group';
import Highlight from './highlight';
import HighlightType from './highlight-type';
import NoReelsMessage from './no-reels-message';
import TabWrapper from 'components/shared/profile-tabs/tab-wrapper';
import UsageLogger from 'common/usage-logger';
import { disablePageScroll, enablePageScroll } from 'utility/display-helpers';
import {
  changeHighlightSort,
  changeHighlightView,
} from 'common/actions';
import { FormModifier, SingleSelect } from 'components/shared/uniform';
import ProfileType from 'common/container-subtype';
import g11n from 'hudl-g11n';

import './highlights-tab.scss';
import DeleteClipsModal from 'components/shared/delete-clip-modal/delete-clips-modal';
import StorageUtility from 'utility/storage-utility';

const defaultSort = 'views';
const defaultView = 'all';
const deleteHighlightClipId = 'has-shown-delete-highlight-clip';
const usageLogger = new UsageLogger();
const UniformUiComponents = window.__uniformui_Components;

class HighlightsTab extends React.Component {
  static propTypes = {
    tabType: React.PropTypes.string,
    profileType: React.PropTypes.string,
    dispatch: React.PropTypes.func,
    forcePublicView: React.PropTypes.bool,
    isPrivilegedUser: React.PropTypes.bool.isRequired,
    highlightsArePrivate: React.PropTypes.bool.isRequired,
    highlightEditorIdentityData: React.PropTypes.shape({
      userId: React.PropTypes.string,
      teamId: React.PropTypes.string,
      isAdmin: React.PropTypes.bool,
      isAthlete: React.PropTypes.bool,
      isCoach: React.PropTypes.bool,
      sport: React.PropTypes.string,
    }),
    ownerType: React.PropTypes.number.isRequired,
    ownerId: React.PropTypes.string.isRequired,
    userCanAccessPrivate: React.PropTypes.bool.isRequired,
    highlights: React.PropTypes.shape({
      createHighlightUrl: React.PropTypes.string,
      highlightSupportUrl: React.PropTypes.string,
      libraryUrl: React.PropTypes.string,
      reels: React.PropTypes.arrayOf(React.PropTypes.object),
      seasons: React.PropTypes.arrayOf(React.PropTypes.shape({
        orderUrl: React.PropTypes.string,
        year: React.PropTypes.number.isRequired,
      })),
      useUniversalReelEditor: React.PropTypes.bool.isRequired,
      universalEditorFeatures: React.PropTypes.arrayOf(React.PropTypes.number).isRequired,
      sort: React.PropTypes.string,
      view: React.PropTypes.string,
    }),
    getPrivacyMessage: React.PropTypes.func.isRequired,
    getCreateHighlightMessage: React.PropTypes.func.isRequired,
    getNoHighlightsMessage: React.PropTypes.func.isRequired,
    onShareVideoClick: React.PropTypes.func.isRequired,
    canPinVideos: React.PropTypes.bool,
    onPinHighlight: React.PropTypes.func,
    onUnpinHighlight: React.PropTypes.func,
    featuredReelId: React.PropTypes.string,
  }

  static defaultProps = {
    onPinHighlight: () => {},
    onUnpinHighlight: () => {},
    canPinVideos: false,
  }

  constructor() {
    super();
    this.getButtons = this.getButtons.bind(this);
    this.getHighlights = this.getHighlights.bind(this);
    this.getViewOptions = this.getViewOptions.bind(this);
    this._onChangeSort = this._onChangeSort.bind(this);

    this.state = {
        hasShownDeleteClipModal: StorageUtility.get(deleteHighlightClipId) === 'true',
    };
  }

  _onChangeView(option) {
    let initialOption = this.props.highlights.view;
    if (initialOption === undefined) {
      initialOption = defaultView.value;
    }
    this.props.dispatch(changeHighlightView(option.value));
    // if same season is clicked on it will log twice so check is needed
    if (initialOption !== option.value) {
      usageLogger.logHighlightFilter(initialOption, option.value, this.props.profileType, this.props.tabType);
    }
  }

  _onChangeSort(option) {
    const initialOption = this.props.highlights.sort || defaultSort.value;
    this.props.dispatch(changeHighlightSort(option));
    usageLogger.logHighlightSort(initialOption, option, this.props.profileType, this.props.tabType);
  }

  setHasShownDeleteClipModal = val => {
    StorageUtility.set(deleteHighlightClipId, val);
    this.setState({hasShownDeleteClipModal: val});
  }

  getButtons() {
    const buttons = [];
    const view = this.props.highlights.view;

    if (view && view !== 'all') {
      const seasonYear = parseInt(view, 10);
      const season = this.props.highlights.seasons.find(hlSeason => {
        return hlSeason.year === seasonYear;
      });

      if (season && season.orderUrl) {
        // Show the order season button if there are game highlights for the selected year
        const seasonHighlights = this.props.highlights.reels.filter(highlight => {
          return highlight.season === seasonYear;
        });

        if (seasonHighlights.some(highlight => highlight.type === HighlightType.game)) {
          buttons.push(
            <a key="order-button" className="order-button" href={season.orderUrl}>
              <UniformUiComponents.Button
                size="small"
                style={this.props.isPrivilegedUser && !this.props.forcePublicView ? 'minimal' : null}
                text="Purchase This Season"
                type="secondary"/>
            </a>
          );
        }
      }
    }

    if (this.props.isPrivilegedUser && !this.props.forcePublicView) {
      let onClickCreate;

      if (this.props.highlights.useUniversalReelEditor) {
        const {
          ownerType,
          ownerId,
          highlights: {
            universalEditorFeatures: featurePrivileges,
          },
          highlightEditorIdentityData,

        } = this.props;

        const options = {
          ownerType,
          ownerId,
          analyticsIdentifyData: highlightEditorIdentityData,
          featurePrivileges,
          endpoints: {
            uploadCredentials: embeddedData.services.highlightsUploadCredentials,
            presign: embeddedData.services.highlightsPresign,
          },
        };

        // If we're using the universal editor, open a modal on click
        onClickCreate = () => {
          window.HighlightsEditor.loadReel(undefined, options,
            () => location.reload(),
            () => enablePageScroll(),
            () => disablePageScroll()
          );
        };
      } else {
        // If we're not using the universal editor, go to the new reel page
        onClickCreate = () => {
          window.location.href = this.props.highlights.createHighlightUrl;
        };
      }

      const onHighlightTutorial = () => {
        usageLogger.logHighlightTutorialClick();
        const athleteUrl = 'https://support.hudl.com/s/article/create-premium-highlights-hudl-v3';
        const teamUrl = 'https://support.hudl.com/s/article/create-premium-highlights-for-your-team-hudl-v3';
        window.open(
            this.props.profileType === ProfileType.Team ? teamUrl : athleteUrl,
            '_blank'
          );
      };
      buttons.push(
        <div className="highlight-tutorials" key="highlight-tutorials">
          <UniformUiComponents.Button
            onClick={onHighlightTutorial}
            size="small"
            text={g11n.format(`profiles.highlights_tutorial`)}
            type="subtle"/>
        </div>
      );

      buttons.push(
        <div className="create-button" key="create-button">
          <UniformUiComponents.Button
            onClick={onClickCreate}
            size="small"
            text="Create Highlight"
            type="secondary"/>
        </div>
      );
    }

    return buttons;
  }

  getHighlights() {
    let highlights = this.props.highlights.reels;

    const view = this.props.highlights.view;
    if (view && view !== 'all') {
      // Filter by season
      highlights = highlights.filter(highlight => {
        return highlight.season + '' === view;
      });
    }

    const sort = this.props.highlights.sort || defaultSort;
    if (sort === 'recent') {
      highlights = _.sortBy(highlights, highlight => {
        return highlight.unixTime * -1;
      });
    } else if (sort === 'views') {
      highlights = _.sortBy(highlights, highlight => {
        return highlight.views * -1;
      });
    }


    const showPrivileged = this.props.isPrivilegedUser && !this.props.forcePublicView;
    if (showPrivileged && view === 'premium' && highlights.length === 0) {
      return <NoReelsMessage/>;
    }

    return _.map(highlights, highlight => {
      const showActionButtons = showPrivileged && highlight.currentUserCanEdit;
      return (
        <Highlight key={highlight.legacyReelId}
          dispatch={this.props.dispatch}
          onShareVideoClick={this.props.onShareVideoClick}
          showActionButtons={showActionButtons}
          useUniversalReelEditor={this.props.highlights.useUniversalReelEditor}
          universalEditorFeatures={this.props.highlights.universalEditorFeatures}
          ownerType={this.props.ownerType}
          ownerId={this.props.ownerId}
          highlightEditorIdentityData={this.props.highlightEditorIdentityData}
          isPrivilegedUser={this.props.isPrivilegedUser}
          userCanAccessPrivate={this.props.userCanAccessPrivate}
          canPinVideos={this.props.canPinVideos}
          onPinHighlight={this.props.onPinHighlight}
          onUnpinHighlight={this.props.onUnpinHighlight}
          isFeaturedHighlight={highlight.legacyReelId === this.props.featuredReelId && !!this.props.featuredReelId}
          {...highlight}
          isDraft={highlight.isDraft}/>
        );
    });
  }

  getViewOptions() {
    const options = [
      { value: 'all', label: 'All Years' },
    ];

    this.props.highlights.seasons.forEach(season => {
      const year = season.year;
      options.push(
        { value: year + '', label: year + '' },
      );
    });

    return options;
  }

  getView() {
    const viewBy = this.props.highlights.view || defaultView;

    if (viewBy === 'all') {
      return 'all';
    }

    const year = this.props.highlights.seasons.find(s => s.year + '' === viewBy).year; // eslint-disable-line react/prop-types, max-len
    return year + '';
  }

  render() {
    if (this.props.highlightsArePrivate &&
      (!this.props.userCanAccessPrivate || (this.props.forcePublicView))) {
      return (
        <TabWrapper>
          {this.props.getPrivacyMessage()}
        </TabWrapper>
      );
    }

    if (this.props.highlights.reels.length === 0) {
      if (this.props.isPrivilegedUser && !this.props.forcePublicView) {
        return (
          <TabWrapper>
            {this.props.getCreateHighlightMessage()}
          </TabWrapper>
        );
      }

      return (
        <TabWrapper>
          {this.props.getNoHighlightsMessage()}
        </TabWrapper>
      );
    }

    const sortBy = this.props.highlights.sort || defaultSort;
    const showDeleteClipsModal =
      this.state.hasShownDeleteClipModal || this.props.highlightEditorIdentityData.userId === undefined;

    return (
      <TabWrapper>
        <main className="tab-panel highlights">
          <div className="video-controls">
            <div className="controls-left">
              <ButtonGroup buttons={[
                {
                  label: 'Most Views',
                  value: 'views',
                  isSelected: sortBy === 'views',
                },
                {
                  label: 'Newest',
                  value: 'recent',
                  isSelected: sortBy === 'recent',
                },
              ]} onButtonPressed={this._onChangeSort}/>

              <FormModifier size="small">
                <SingleSelect
                  value={this.getView()}
                  options={this.getViewOptions()}
                  onChange={this._onChangeView.bind(this)}
                  hideDismiss
                />
              </FormModifier>
            </div>
            <div className={'controls-right ' +
                (this.props.isPrivilegedUser && !this.props.forcePublicView ? 'privileged' : 'public')}>
              {this.getButtons()}
            </div>
          </div>

          <div className="videos-list compact-margin">
            {this.getHighlights()}
          </div>
          <DeleteClipsModal isOpen={showDeleteClipsModal} onClose={this.setHasShownDeleteClipModal} />
        </main>
      </TabWrapper>
    );
  }
}

export default HighlightsTab;
