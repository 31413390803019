import React from 'react';
import ClipIcon from 'hudl-react-kickoff/icons/Clip';

const NoReelsMessage = () => {
  return (
    <div className="tab-blank-state">
      <h1>You haven't created any highlight reels. Get started now.</h1>
      <ClipIcon className="clip-icon blank-icon"/>
      <p>
        Combine several highlights to make one awesome reel with great effects.<br/>
        Then share it with your team, recruiters, and fans.
      </p>
    </div>
  );
};

export default NoReelsMessage;
