import React from 'react';
import PropTypes from 'prop-types';
import g11n from 'hudl-g11n';

import TabWrapper from 'components/shared/profile-tabs/tab-wrapper';
import RecruitUpsell from 'components/user-profile/cards/RecruitUpsell';
import NoRecruiterAccess from 'components/user-profile/cards/NoRecruiterAccess';
import PersonalContactCard from 'components/user-profile/cards/ContactCard/PersonalContactCard';
import TeamContactCard from 'components/user-profile/cards/ContactCard/TeamContactCard';
import IconRecruiter from 'components/shared/icons/icon-recruiter';

import {
  Link,
  Text,
} from 'components/shared/uniform';

const { FormattedMessage, FormattedHTMLMessage } = g11n.ReactIntl;

import styles from './styles.scss';

const UserContactTab = ({
  contactInfo,
  currentUser,
  customize,
  recruiterAccess,
  recruitingSupportGuideUrl,
}) => {
  let content = (
    <div className={styles['contact-tab__notice']}>
      <div className={styles['contact-tab__incomplete']} data-qa-id="contact-tab-empty">
        <NoRecruiterAccess size="large"/>
      </div>
    </div>
  );

  if (contactInfo.isAccessRestricted) {
    content = (
      <div className={styles['contact-tab__notice']} data-qa-id="contact-tab-incomplete">
        <NoRecruiterAccess size="large"/>
      </div>
    );
  } else if (contactInfo.shouldUpsellRecruit) {
    content = (
      <div className={styles['contact-tab__notice']}>
        <RecruitUpsell size="large"/>
      </div>
    );
  }

  const isPrivilegedUser = currentUser && currentUser.isPrivilegedUser;
  const hasContacts = (contactInfo.personalContacts && contactInfo.personalContacts.length)
    || (contactInfo.teamContacts && contactInfo.teamContacts.length);
  if (isPrivilegedUser || (hasContacts && recruiterAccess.hasRecruiterAccess)) {
    content = (
      <div className={styles['contact-tab__contacts']}>
        {contactInfo.personalContacts && contactInfo.personalContacts.map(c => <PersonalContactCard {...c} />)}
        {contactInfo.teamContacts && contactInfo.teamContacts.map(c => <TeamContactCard {...c} />)}
      </div>
    );
  }

  let tabNotice = null;
  if (isPrivilegedUser && recruitingSupportGuideUrl) {
    const noticeTitleKey = !customize.recruitOptIn.value
      ? 'profiles.recruit.learn_more_title.opted-out'
      : 'profiles.recruit.learn_more_title.opted-in';
    tabNotice = (
      <div className="uni-note uni-note--medium uni-note--information uni-margin--one-and-half--btm">
        <IconRecruiter />
        <Text>
          <FormattedHTMLMessage id={noticeTitleKey} />
          <FormattedMessage
            id="profiles.recruit.learn_more_message"
            values={{
              ctaLink: (
                <Link href={recruitingSupportGuideUrl} target="_blank" type="article">
                  <FormattedMessage id="profiles.recruit.learn_more_cta" />
                </Link>
              ),
            }}
          />
        </Text>
      </div>
    );
  }

  return (
    <TabWrapper>
      <main className="tab-panel" data-qa-id="contact-tab">
        <div className={styles['contact-tab__panel']}>
          {tabNotice}
          <div className={styles['contact-tab__content']}>
            {content}
          </div>
        </div>
      </main>
    </TabWrapper>
  );
};

UserContactTab.propTypes = {
  contactInfo: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.shape({
      relationship: PropTypes.string,
      name: PropTypes.string,
      streetAddress: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    })),
    shouldUpsellRecruit: PropTypes.bool,
    isAccessRestricted: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    isPrivilegedUser: PropTypes.bool,
  }),
  customize: PropTypes.shape({
    recruitOptIn: PropTypes.shape({
      value: PropTypes.bool,
    }),
  }),
  recruiterAccess: PropTypes.shape({
    hasRecruiterAccess: PropTypes.bool,
  }),
  recruitingSupportGuideUrl: PropTypes.string,
};

export default UserContactTab;
