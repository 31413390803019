import $ from 'jquery';
import { data, Service } from 'hudl-base';

// this is the shim for window.performance.timing
let utcRequestStartMilliseconds = 0;
if (!window.performance || !window.performance.timing) {
  window.performance = window.performance || {};
  window.performance.timing = window.performance.timing || {};
  if (!utcRequestStartMilliseconds) {
    utcRequestStartMilliseconds = data.get('requestStartInMilliseconds');
    window.performance.timing.requestStart = utcRequestStartMilliseconds;
  }
}

$(window).load(() => {
  setTimeout(() => {
    if (!window.performance.timing.loadEventEnd) {
      const utcLoadEventEndMilliseconds = (new Date()).getTime();
      window.performance.timing.loadEventEnd = utcLoadEventEndMilliseconds;
    }
    utcRequestStartMilliseconds = window.performance.timing.requestStart;

    const pageLoadInMilliseconds =
      Math.abs(window.performance.timing.loadEventEnd - utcRequestStartMilliseconds);
    const requestFinishMilliseconds =
      Math.abs(window.performance.timing.responseEnd - utcRequestStartMilliseconds);
    const domLoadingMilliseconds =
      Math.abs(window.performance.timing.domLoading - utcRequestStartMilliseconds);
    const domCompleteMilliseconds =
      Math.abs(window.performance.timing.domComplete - utcRequestStartMilliseconds);
    const domInteractiveMilliseconds =
      Math.abs(window.performance.timing.domInteractive - utcRequestStartMilliseconds);
    let object = {
      pageLoadInMilliseconds: pageLoadInMilliseconds,
      requestFinishMilliseconds: requestFinishMilliseconds,
      domLoadingMilliseconds: domLoadingMilliseconds,
      domCompleteMilliseconds: domCompleteMilliseconds,
      domInteractiveMilliseconds: domInteractiveMilliseconds,
    };

    const pageData = data.get('pageData') || {};
    const { video, team, athlete, author } = pageData;
    if (video) {
      // video page
      const videoContentId = video && video.communityContentId ? video.communityContentId.relatedId : '';
      object = { ...object,
        ownerId: pageData.owner.id,
        contentId: videoContentId,
        ownerType: pageData.owner.feedUserType,
      };
    } else if (team) {
      object = { ...object,
        teamId: team.teamId,
      };
    } else if (athlete) {
      object = { ...object,
        athleteId: athlete.athleteId,
      };
    } else if (author) {
      object = { ...object,
        authorId: author.authorId,
      };
    }
    new Service('logPageLoad').post(object);
  }, 0);
});

function getPerformanceTimeFromNowInMilliseconds() {
  const utcTimeAtNowInMilliseconds = (new Date()).getTime();
  return Math.abs(utcTimeAtNowInMilliseconds - window.performance.timing.requestStart);
}

export default {
  getTimeFromLoadInMilliseconds: getPerformanceTimeFromNowInMilliseconds,
};
