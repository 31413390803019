import React from 'react';
import g11n from 'hudl-g11n';
const FormattedMessage = g11n.ReactIntl.FormattedMessage;

class LocalizedMessage extends React.Component {
  static propTypes = {
    id: React.PropTypes.string.isRequired,
    values: React.PropTypes.object,
  }

  render() {
    return <FormattedMessage id={this.props.id} values={this.props.values}/>;
  }
}

export default LocalizedMessage;
