import React from 'react';

const PinIcon = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24" height="24" viewBox="0 0 24 24">
      {/* eslint-disable max-len */}
      <path d="M0.146 23.854c0.195 0.195 0.512 0.195 0.707 0L9.5 15.207l4.146 4.147C13.744 19.451 13.872 19.5 14 19.5s0.256-0.049 0.354-0.146l1.5-1.5C15.947 17.761 16 17.632 16 17.5v-3.793l4.5-4.5 0.646 0.647c0.195 0.195 0.512 0.195 0.707 0l2-2c0.195-0.195 0.195-0.512 0-0.708l-7-7c-0.195-0.194-0.512-0.194-0.707 0l-2 2c-0.195 0.196-0.195 0.513 0 0.708L14.793 3.5l-4.5 4.5H6.5C6.368 8 6.24 8.053 6.146 8.146l-1.5 1.5c-0.195 0.196-0.195 0.513 0 0.708L8.793 14.5l-8.646 8.646C-0.049 23.341-0.049 23.659 0.146 23.854z"/>
      {/* eslint-enable max-len */}
    </svg>
  );
};

PinIcon.propTypes = {
  className: React.PropTypes.string,
};

export default PinIcon;
