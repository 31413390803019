import React from 'react';
import { Link, Text } from 'components/shared/uniform';
import ExpandableList from './ExpandableList';

class ExpandableLinkList extends React.Component {
  static propTypes = {
    items: React.PropTypes.arrayOf(React.PropTypes.object),
    header: React.PropTypes.string,
    isExpandable: React.PropTypes.bool,
    showCount: React.PropTypes.bool,
    isDefaultOpen: React.PropTypes.bool,
  }

  renderItems(items) {
    return (
      <ul>
        {items.map((item) => {
          return (
            <li>
              <Text level="small">
                <Link href={item.downloadUri} target="_blank">
                  {item.label}
                </Link>
              </Text>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    return (
      <ExpandableList
        onRenderItems={this.renderItems}
        {...this.props}
      />
    );
  }
}

export default ExpandableLinkList;
