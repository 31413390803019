import { isArray, debounce } from 'underscore';
import getParsedFontSize from 'utility/ads/get-parsed-font-size';

// Breakpoints based on 16px font-size
function googleAdRefresher(adContainerBreakpoints) {
  if (!adContainerBreakpoints || !isArray(adContainerBreakpoints)) {
    throw new Error('adContainerBreakpoints must be an array of the CSS breakpoint widths for the ad container');
  }

  const fontSize = getParsedFontSize();
  const sortedAdContainerBreakpoints = adContainerBreakpoints.map(breakpoint => {
    return (breakpoint * fontSize / 16);
  }).sort((a, b) => {
    return b - a;
  });


  function getGoogleAdWidthContainer() {
    let i;

    // Find which CSS breakpoint we are currently in
    const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    for (i = 0; i < sortedAdContainerBreakpoints.length; i++) {
      if (pageWidth > sortedAdContainerBreakpoints[i]) {
        return i;
      }
    }

    return sortedAdContainerBreakpoints.length;
  }

  let currentGoogleAdWidthContainer = getGoogleAdWidthContainer();

  window.addEventListener('resize', debounce(() => {
    if (!window.googletag) {
      return;
    }

    const newGoogleAdWidthContainer = getGoogleAdWidthContainer();

    // If the ad container size has changed, refresh the Google Ad
    if (currentGoogleAdWidthContainer !== newGoogleAdWidthContainer) {
      window.googletag.pubads().refresh();
      currentGoogleAdWidthContainer = newGoogleAdWidthContainer;
    }
  }, 200));
}

export default googleAdRefresher;
