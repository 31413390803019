// Cache this value so we don't recompute it constantly.
let _fontSize;
const _defaultFontSize = 16;
export default function() {
  if (_fontSize) {
    return _fontSize;
  }

  try {
    const documentFontSize = window.getComputedStyle(document.body).fontSize;
    if (documentFontSize && documentFontSize !== '16px' && documentFontSize.endsWith('px')) {
      _fontSize = parseInt(documentFontSize.substr(0, documentFontSize.length - 2), 10);
    } else {
      _fontSize = _defaultFontSize;
    }
  } catch (e) {
    _fontSize = _defaultFontSize;
  }
  return _fontSize;
}
