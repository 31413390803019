import $ from 'jquery';
import _ from 'underscore';

import { CHANGE_HIGHLIGHT_SORT, CHANGE_HIGHLIGHT_VIEW, REMOVE_HIGHLIGHT } from 'common/actions';

function highlights(state = {}, action) {
  const newState = $.extend(true, {}, state);
  switch (action.type) {
  // Actions around following the team
  case CHANGE_HIGHLIGHT_SORT:
    newState.sort = action.payload.sort;
    break;
  case CHANGE_HIGHLIGHT_VIEW:
    newState.view = action.payload.view;
    break;
  case REMOVE_HIGHLIGHT:
    const legacyReelId = action.payload.legacyReelId;
    newState.reels = _.reject(state.reels, { legacyReelId: legacyReelId });
    break;
  default:
    return state;
  }
  return newState;
}

export default highlights;
