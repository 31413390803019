/* eslint-disable max-len */
// Should be kept in sync with https://github.com/hudl/hudl-highlights/blob/master/Hudl.Highlights.Client/Dto/CommunityContentLog/ContainerPlayer.cs
export default {
  Unknown: 0,
  TimelineCard: 1,
  VideoPage: 2,
  EmbeddablePlayer: 3,
  Immersive: 4,
  NativeSocial: 5,
  Partner: 6,
  VideoManagementPlayer: 7,
};
