import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
  <g>
    <circle cx="14" cy="14" r="1" fill="#F5A11C"/>
    <circle cx="14" cy="14" r="1" fill="#FFF"/>
    <g>
      {/* eslint-disable max-len */}
      <path d="M18.6 5.4C18.9 6.2 19.1 7 19.1 7.8 19.1 9.2 18.6 10.5 17.8 11.7 17.3 12.4 16.7 13 15.9 13.6 16 13 16 12.4 16 11.8 16 11.3 16 10.7 15.9 10.2 15.8 8.3 15.2 6.5 14.5 4.9 13.3 2.7 11.7 0.9 9.7 0 9.7 0 9.7 0 9.7 0 9.7 0 9.7 0 9.7 0 10 1 10.1 2 10.1 3 10.1 4.6 9.8 6.1 9.1 7.5 8.6 8.4 8 9.3 6.9 10.2 6.3 10.7 2 13.3 2 18.6 2 20.4 2.5 22 3.2 23.4 4.5 25.7 6.6 27.4 9.2 28 8.5 26.8 7.5 25.1 7.3 23.4 7 21.9 7.3 20.1 8 19.3 8.2 20.5 9.6 21.5 10.5 22.3 11.4 21.4 12.1 20.6 12.3 19.3 12.5 18.6 12.4 17.6 12.2 17 13.8 17.2 15.3 20.2 15.2 23.1 15.2 25.7 14.3 26.8 13.6 28 20.9 27.2 25.2 20.7 25.2 15.5 25.2 10.9 22.4 6.9 18.6 5.4" fill="#F16621"/>
      <path d="M14.3 27.9C21.2 26.8 25.2 20.5 25.2 15.5 25.2 10.9 22.4 6.9 18.6 5.4 18.9 6.2 19.1 7 19.1 7.8 19.1 7.8 19.1 7.9 19.1 7.9 20.4 10.1 21.3 12.9 21.3 16.1 21.3 22.1 18.2 27.1 14.3 27.9" fill="#FBB251"/>
      <path d="M6.1 5.1C9.3 7.9 2.9 12 2.9 12 2.9 12 5.1 9.2 2.8 7 0 4.1 5.5 0 5.5 0 5.5 0 3.8 3.1 6.1 5.1" style={{fill: '#F16621', opacity: 0}}/>
      <path d="M6.1 5.1C9.3 7.9 2.9 12 2.9 12 2.9 12 5.1 9.2 2.8 7 0 4.1 5.5 0 5.5 0 5.5 0 3.8 3.1 6.1 5.1" style={{fill: '#F16621', opacity: 0}}/>
      <path d="M6.1 5.1C9.3 7.9 2.9 12 2.9 12 2.9 12 5.1 9.2 2.8 7 0 4.1 5.5 0 5.5 0 5.5 0 3.8 3.1 6.1 5.1" style={{fill: '#F16621', opacity: 0}}/>
      {/* eslint-enable max-len */}
    </g>
  </g>
</svg>
);
