import './setup-dfp';
import mappings from './mappings';
import sizes from './sizes';
import normalizeSizeMappings from './normalize-size-mapping';

export default {
  mappings,
  sizes,
  normalizeSizeMappings,
};
