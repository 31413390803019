import Mapping from './mapping';

const mappings = {
  leaderboard: new Mapping([744, 200], [728, 90]),
  mediumRectangle: new Mapping([985, 250], [300, 250]),
  mobileLeaderboard: new Mapping([335, 200], [320, 50]),
  empty: new Mapping([0, 0], [88, 31]),
};

export default {
  ...mappings,
  all: Object.values(mappings),
};
