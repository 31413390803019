import React from 'react';
import { Button, Icon, Subhead } from 'components/shared/uniform';
import styles from './styles.scss';
import EmptyCardValue from '../../EmptyCardValue';

class ExpandableList extends React.Component {
  static propTypes = {
    items: React.PropTypes.array,
    onRenderItems: React.PropTypes.func.isRequired,
    header: React.PropTypes.string,
    isDefaultOpen: React.PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: !props.isDefaultOpen,
      hasMultipleItems: props.items && props.items.length > 1,
    };
  }

  onToggleCollapsedState = () => {
    if (!this.state.hasMultipleItems) {
      return;
    }

    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }

  render() {
    const { items, header } = this.props;
    const { hasMultipleItems } = this.state;

    const expandIcon = hasMultipleItems ?
      (
        <Icon
          type={this.state.isCollapsed ? 'uiexpandregionvertical' : 'uicollapseregionvertical'}
        />
      ) : null;

    const renderedItems = !this.state.isCollapsed ?
      this.props.onRenderItems(items) : null;

    const itemsToDisplay = items && items.length > 0 ? renderedItems : <EmptyCardValue />;

    return (
      <div className={styles['expandible-list']}>
        <div className={styles['expandable-list__header']}>
          <Button
            size="small"
            type="subtle"
            style="minimal"
            onClick={this.onToggleCollapsedState}
          >
            <Subhead level="subtle">
              {hasMultipleItems ? `${header} (${items.length})` : header}
            </Subhead>
            {expandIcon}
          </Button>
        </div>
        <div className={styles['expandable-list__items']}>
          {itemsToDisplay}
        </div>
      </div>
    );
  }
}

export default ExpandableList;
