const {
  Alert,
  AvatarTeam,
  AvatarUser,
  Button,
  ButtonRow,
  Card,
  Environment,
  FormModifier,
  Headline,
  Icon,
  Input,
  ItemTitle,
  Link,
  Modal,
  Note,
  Radio,
  RadioGroup,
  SingleSelect,
  Spinner,
  Subhead,
  Text,
  Textarea,
  Toast,
  Tooltip,
} = window.__uniformui_Components;

export {
  Alert,
  AvatarTeam,
  AvatarUser,
  Button,
  ButtonRow,
  Card,
  Environment,
  FormModifier,
  Headline,
  Icon,
  Input,
  ItemTitle,
  Link,
  Modal,
  Note,
  Radio,
  RadioGroup,
  SingleSelect,
  Spinner,
  Subhead,
  Text,
  Textarea,
  Toast,
  Tooltip,
};
