import React from 'react';

import './tab-wrapper.scss';

class TabWrapper extends React.Component {
  static propTypes = {
    children: React.PropTypes.oneOfType([
      React.PropTypes.element,
      React.PropTypes.arrayOf(React.PropTypes.element),
    ]),
  }

  render() {
    return (
      <div className="profile-tab" role="tabpanel">
        {this.props.children}
      </div>
    );
  }
}

export default TabWrapper;
