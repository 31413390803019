import React from 'react';
import PropTypes from 'prop-types';
import IconNike from 'components/shared/icons/icon-nike-verified';
import { ItemTitle } from 'components/shared/uniform';
import EmptyCardValue from '../EmptyCardValue';

import styles from './styles.scss';

const CardStat = ({ classNames, label, value, isVerified }) => {
  let valueElements;
  // For string or number values, just wrap the value in a <p> tag
  if (typeof value === 'string' || value instanceof String) {
    valueElements = <p className={styles['card-stat__value']}>{value}</p>;
  } else if (typeof value === 'number') {
    valueElements = <p className={styles['card-stat__value']}>{value + ''}</p>;
  } else if (typeof value === 'undefined') {
    valueElements = <EmptyCardValue />;
  } else {
    // For non-string/number values, use whatever was passed
    valueElements = value;
  }

  // If stat is a verified value, add the verified icon next to it's value
  const verifiedElement = isVerified && (
    <div className={styles['card-stat__verified']}>
      <IconNike/>
    </div>
  );

  return (
    <div className={`${styles['card-stat']} ${classNames}`} key={label}>
      <div className={styles['card-stat__label']}>
        <ItemTitle isCaps>{label}</ItemTitle>
      </div>
      <div className={styles['card-stat__content']}>
        {valueElements}
        {verifiedElement}
      </div>
    </div>
  );
};

CardStat.propTypes = {
  classNames: PropTypes.string,
  isVerified: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
};

export default CardStat;
