import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Service } from 'hudl-base';
import g11n from 'hudl-g11n';

import UsageLogger from 'common/usage-logger';
import ProfileType from 'common/container-subtype';
import TabWrapper from 'components/shared/profile-tabs/tab-wrapper';
import Game from './game';
import ItemsLoadingExperience from './ItemsLoadingExperience';
import RecruitUpsell from 'components/user-profile/cards/RecruitUpsell';
import IconRecruiter from 'components/shared/icons/icon-recruiter';
import RestrictedAccess from './RestrictedAccess';
import gameShape from './gameShape';
import teamShape from './teamShape';
import seasonShape from './seasonShape';
import TeamSelection from './team-selection';

const { FormattedMessage, FormattedHTMLMessage } = g11n.ReactIntl;

import {
  changeGamesTeamView,
  changeGamesSeasonView,
  dismissToast,
  showToast,
  requestAthleteGames,
  clearAthleteGames,
} from 'common/actions';

import {
  FormModifier,
  Headline,
  Link,
  SingleSelect,
  Subhead,
  Text,
} from 'components/shared/uniform';

import './games-tab.scss';

class GamesTab extends Component {
  static propTypes = {
    profileType: PropTypes.oneOf([ProfileType.User, ProfileType.Team]),
    profileAthleteId: PropTypes.string,
    profileTeamId: PropTypes.string,
    isAccessRestricted: PropTypes.bool,
    isPrivilegedUser: PropTypes.bool,
    changeGamesTeamView: PropTypes.func,
    changeGamesSeasonView: PropTypes.func,
    dismissToast: PropTypes.func,
    games: PropTypes.arrayOf(gameShape),
    loading: PropTypes.bool,
    requestAthleteGames: PropTypes.func,
    clearAthleteGames: PropTypes.func,
    selectedTeam: teamShape,
    selectedSeason: seasonShape,
    shouldUpsellRecruit: PropTypes.bool,
    showToast: PropTypes.func,
    teams: PropTypes.objectOf(teamShape),
    userEmail: PropTypes.string,
    recruitingSupportGuideUrl: PropTypes.string,
  }

  constructor(props) {
    super(props);

    const { games, isAccessRestricted, selectedTeam } = props;
    if (!games && !isAccessRestricted && selectedTeam && !selectedTeam.shouldUpsellRecruit) {
      this.requestAthleteGames(selectedTeam);
    }

    this.state = {
      downloadVideoError: false,
      downloadVideoSuccess: false,
    };

    this.usageLogger = new UsageLogger();
  }

  logPreviewClick = (videoId) => () => {
    this.usageLogger.logGamePreviewClick(this.props.profileAthleteId, this.props.profileTeamId, videoId);
  }

  getSeasonViewOptions = () => {
    return Object.values(this.props.selectedTeam.seasons)
      .map(season => ({
        label: season.label,
        value: season.seasonId,
      }))
      .reverse();
  }

  _onChangeTeamView = (option) => {
    this.props.changeGamesTeamView(option.value);

    const newSelectedTeam = this.props.teams[option.value];
    if (newSelectedTeam.shouldUpsellRecruit) {
      this.props.clearAthleteGames();
    } else {
      this.requestAthleteGames(newSelectedTeam);
    }
  }

  _onChangeSeasonView = (option) => {
    this.props.changeGamesSeasonView(option.value);
  }

  getBreakdownDownloadUrl(eventId, athleteTeamId, breakdownId) {
    const downloadTagsService = new Service('downloadTagsAsRecruiter', { eventId, athleteTeamId, breakdownId });
    return downloadTagsService.getUrl();
  }

  getOpponentTeamProfileUrl(teamId) {
    const opponentTeamProfileService = new Service('teamProfile', { teamId });
    return opponentTeamProfileService.getUrl();
  }

  getGamesService(team) {
    const { isPrivilegedUser } = this.props;

    const athleteTeamId = team.teamId;
    if (isPrivilegedUser) {
      return new Service('getRecruitGamesAsOwner', { athleteTeamId });
    }

    const recruitTeamId = team.recruitTeamId;
    return new Service('getRecruitGamesAsRecruiter', { recruitTeamId, athleteTeamId });
  }

  requestAthleteGames(team) {
    const gamesService = this.getGamesService(team);
    this.props.requestAthleteGames(gamesService);
  }

  downloadGameVideo = (eventId, athleteTeamId) => () => {
    const { userEmail } = this.props;
    const downloadMessage = userEmail ? `Download sent to ${userEmail}` : 'Download requested';

    new Service('downloadGameVideoAsRecruiter', { eventId, athleteTeamId })
      .post()
      .fail(() => this.props.showToast({
        type: 'critical',
        message: 'Download failed. Try again',
        onDismiss: this.props.dismissToast,
      }))
      .done(() => this.props.showToast({
        type: 'confirmation',
        message: downloadMessage,
        onDismiss: this.props.dismissToast,
      }));
  }

  getGameVideoUrl(videoId) {
    const { selectedTeam } = this.props;

    const urlData = {
      recruitTeamId: selectedTeam.recruitTeamId,
      athleteTeamId: selectedTeam.teamId,
      cutupId: videoId,
      eventId: videoId,
    };

    const videoService = selectedTeam.canUseRecruitVideoExperience
      ? new Service('recruitVideoExperienceUrl', urlData)
      : new Service('recruitVspaUrl', urlData);

    return videoService && videoService.getUrl();
  }

  renderGames() {
    const { games, profileType, selectedSeason, selectedTeam, isPrivilegedUser } = this.props;

    if (selectedTeam && selectedTeam.shouldUpsellRecruit) {
      return (
        <div className="games-tab__panel">
          <div className="games-tab__notice">
            <RecruitUpsell size="large" profileType={profileType}/>
          </div>
        </div>
      );
    }

    if (!games || games === null || !selectedSeason) {
      return null;
    }

    const selectedSeasonLabel = selectedSeason.label;
    if (!games[selectedSeasonLabel]
      || !games[selectedSeasonLabel].some((game) => game.event || (game.cutups && game.cutups[0]))) {
      return (
        <div className="games-tab__panel">
          <div className="games-tab__notice">
            <Headline level="2">
              <span>There’s no content here yet.</span>
            </Headline>
          </div>
        </div>
      );
    }

    const seasonGames = games[selectedSeasonLabel];

    return seasonGames.map(game => {
      if (!game.event && !game.cutups) {
        return null;
      }

      const thumbnailUrl = game.cutups && game.cutups.length > 0 ?
        game.cutups[0].thumbnailPath : '';

      const hasBreakdown = (game.event && game.event.breakdownId)
        || (game.cutups && game.cutups.length > 0 && game.cutups[0].assistCutupId);

      // Scores can be null or 0-0 when we don't have a reliable score
      let result;
      if (game.score1 || game.score2) {
        const score1 = game.score1 || 0;
        const score2 = game.score2 || 0;

        let outcome;
        if (score1 > score2) {
          outcome = 'Win';
        } else if (score2 > score1) {
          outcome = 'Loss';
        } else {
          outcome = 'Tie';
        }

        result = `${score1} - ${score2} ${outcome}`;
      }

      const opponentTeamProfileUrl = game.opponent && game.opponent.teamId && game.opponent.teamId !== '0'
        ? this.getOpponentTeamProfileUrl(game.opponent.teamId)
        : '';

      const videoId = !!game.event
        ? game.event.eventId
        : game.cutups.length > 0 && game.cutups[0].cutupId;

      if (isPrivilegedUser) {
        return (
          <Game
            eventAt={game.eventAt}
            hasBreakdown={hasBreakdown}
            opponent={game.opponent}
            opponentTeamProfileUrl={opponentTeamProfileUrl}
            result={result}
            thumbnailUrl={thumbnailUrl}
            previewMode
            logPreviewClick={this.logPreviewClick(videoId)}
          />
        );
      }

      const gameUrl = this.getGameVideoUrl(videoId);

      const canDownload = !selectedTeam.canUseRecruitVideoExperience;
      const breakdownDownloadUrl = canDownload && selectedTeam.canUseBreakdownData && hasBreakdown
        ? this.getBreakdownDownloadUrl(game.event.eventId, selectedTeam.teamId, game.event.breakdownId)
        : '';
      const videoDownloadFunc = canDownload && !!game.event
        && this.downloadGameVideo(game.event.eventId, selectedTeam.teamId);

      return (
        <Game
          canDownload={!selectedTeam.canUseRecruitVideoExperience}
          breakdownDownloadUrl={breakdownDownloadUrl}
          downloadGameVideo={videoDownloadFunc}
          eventAt={game.eventAt}
          gameUrl={gameUrl}
          hasBreakdown={hasBreakdown}
          opponent={game.opponent}
          opponentTeamProfileUrl={opponentTeamProfileUrl}
          result={result}
          thumbnailUrl={thumbnailUrl}
        />
      );
    }).reverse();
  }

  render() {
    const {
      isAccessRestricted,
      loading,
      profileType,
      recruitingSupportGuideUrl,
      selectedSeason,
      selectedTeam,
      shouldUpsellRecruit,
    } = this.props;


    let tabNotice = null;
    if (recruitingSupportGuideUrl) {
      tabNotice = (
        <div
          className="games-tab__preview-notice uni-note uni-note--medium uni-note--information"
          data-qa-id="games-tab-preview-mode-message"
        >
          <IconRecruiter />
          <Text>
            <FormattedHTMLMessage id="profiles.tab_games.owner.preview_notice_title" />
            <FormattedMessage
              id="profiles.tab_games.owner.preview_notice_message"
              values={{
                ctaLink: (
                  <Link href={recruitingSupportGuideUrl} target="_blank" type="article">
                    <FormattedMessage id="profiles.tab_games.owner.preview_notice_cta" />
                  </Link>
                ),
              }}
            />
          </Text>
        </div>
      );
    }

    let content = !!selectedTeam && !!selectedSeason && (
      <div data-qa-id="games-tab">
        {tabNotice}
        <div className="games-controls">
          <div className="controls-left">
            <FormModifier size="small">
              <TeamSelection
                teams={Object.values(this.props.teams)}
                selectedOption={selectedTeam.teamId}
                onChange={this._onChangeTeamView}
                disabled={loading}
              />
            </FormModifier>

            <FormModifier size="small">
              <SingleSelect
                value={selectedSeason.seasonId}
                options={this.getSeasonViewOptions()}
                onChange={this._onChangeSeasonView}
                hideDismiss
                disabled={loading}
                label={<Subhead level="small">Season</Subhead>}
              />
            </FormModifier>
          </div>
        </div>

        <div className="games-list compact-margin">
          {loading && <ItemsLoadingExperience />}
          {!loading && this.renderGames()}
        </div>
      </div>
    );

    if (!selectedTeam || !selectedSeason) {
      const descriptionKey = profileType === ProfileType.User
        ? 'profiles.tab_games.error_message.description.athlete'
        : 'profiles.tab_games.error_message.description.team';
      const descriptionDefault = profileType === ProfileType.User
        ? 'We are unable to show games for this athlete.'
        : 'We are unable to show games for this team.';
      content = (
        <div className="games-tab__panel">
          <div className="games-tab__notice">
            <Headline level="2">
              <FormattedMessage
                id="profiles.tab_games.error_message.title"
                defaultMessage="Something went wrong"
              />
            </Headline>
            <div className="games-tab__notice-cta">
              <Text level="large">
                <FormattedMessage id={descriptionKey} defaultMessage={descriptionDefault}/>
              </Text>
              <Text level="large">
                <FormattedMessage
                  id="profiles.tab_games.error_message.cta"
                  defaultMessage="Please {ctaLink} for help."
                  values={{
                    ctaLink: (
                      <Link href="http://hudl.com/support/contact" target="_blank" type="article">
                        <FormattedMessage
                          id="profiles.tab_games.error_message.cta_link"
                          defaultMessage="contact Hudl Support"
                        />
                      </Link>
                    ),
                  }}
                />
              </Text>
            </div>
          </div>
        </div>
      );
    }

    if (isAccessRestricted) {
      content = (
        <div className="games-tab__panel" data-qa-id="games-tab">
          <RestrictedAccess/>
        </div>
      );
    } else if (shouldUpsellRecruit) {
      content = (
        <div className="games-tab__panel" data-qa-id="games-tab">
          <div className="games-tab__notice">
            <RecruitUpsell size="large" profileType={profileType}/>
          </div>
        </div>
      );
    }

    return (
      <TabWrapper>
        <main className="tab-panel games">
          {content}
        </main>
      </TabWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTeam = state.games.selectedTeamId ? state.games.teams[state.games.selectedTeamId] : null;
  const selectedSeason = state.games.selectedSeasonId ? selectedTeam.seasons[state.games.selectedSeasonId] : null;

  const isAthletePerspective = state.games.profileType === ProfileType.User;

  const currentUser = isAthletePerspective ? state.currentUser : state.user;

  return {
    profileType: state.games.profileType,
    profileAthleteId: isAthletePerspective ? state.user.userId : null,
    profileTeamId: !isAthletePerspective ? state.games.selectedTeamId : null,
    isAccessRestricted: state.games.isAccessRestricted,
    isPrivilegedUser: isAthletePerspective ? currentUser.isPrivilegedUser : currentUser.hasAnyRoleOnTeam,
    error: state.games.error,
    games: state.games.fullGames,
    loading: state.games.loading,
    recruitingSupportGuideUrl: state.recruitingSupportGuideUrl,
    selectedSeason,
    selectedTeam,
    shouldUpsellRecruit: state.recruiterAccess && state.recruiterAccess.canUpgradeForAccess,
    teams: state.games.teams,
    userEmail: state.games.userEmail,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeGamesTeamView,
  changeGamesSeasonView,
  dismissToast,
  showToast,
  requestAthleteGames,
  clearAthleteGames,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GamesTab);
