import {
  CLEAR_STAGED_UPDATES,
  FAIL_TEAM_IMAGE_UPDATE,
  FAIL_USER_IMAGE_UPDATE,
  RECEIVE_TEAM_IMAGE_UPDATED,
  RECEIVE_USER_IMAGE_UPDATED,
  REQUEST_TEAM_IMAGE_UPDATE,
  REQUEST_USER_IMAGE_UPDATE,
  STAGE_UPDATE,
  TEMP_PROFILE_IMAGE_UPLOADED,
} from 'common/actions';
import * as EditTypes from 'common/edit-types';

const defaultState = {
  tempValue: '',
  requesting: false,
  error: null,
};

function profileImage(state = {}, action) {
  const newState = $.extend(true, {}, defaultState, state);
  switch (action.type) {
  case CLEAR_STAGED_UPDATES:
    newState.value = newState.savedValue;
    newState.saveSuccess = false;
    newState.saveError = false;
    newState.tempValue = '';
    newState.tempImageId = undefined;
    newState.error = null;
    break;
  case STAGE_UPDATE:
    const edit = action.payload;
    if (edit.type !== EditTypes.LOGO_IMAGE) {
      return state;
    }

    newState.value = edit.args.croppedDataUrl;
    newState.saveSuccess = false;
    newState.saveError = false;
    break;
  case TEMP_PROFILE_IMAGE_UPLOADED:
    newState.tempValue = action.payload.imageSrc;
    newState.tempImageId = action.payload.imageId;
    break;
  case REQUEST_TEAM_IMAGE_UPDATE:
  case REQUEST_USER_IMAGE_UPDATE:
    if (action.payload.imageType !== 'TEAM_LOGO' && action.payload.imageType !== 'PROFILE') {
      return state;
    }
    newState.requesting = true;
    newState.error = null;
    break;
  case RECEIVE_TEAM_IMAGE_UPDATED:
  case RECEIVE_USER_IMAGE_UPDATED:
    if (action.payload.imageType !== 'TEAM_LOGO' && action.payload.imageType !== 'PROFILE') {
      return state;
    }
    newState.requesting = false;
    newState.error = null;
    newState.value = action.payload.newImageSource;
    newState.savedValue = action.payload.newImageSource;
    break;
  case FAIL_TEAM_IMAGE_UPDATE:
  case FAIL_USER_IMAGE_UPDATE:
    if (action.payload.args.imageType !== 'TEAM_LOGO' && action.payload.args.imageType !== 'PROFILE') {
      return state;
    }
    newState.requesting = false;
    newState.error = action.payload;
    break;
  default:
    return state;
  }

  return newState;
}

export default profileImage;
