import React from 'react';
import FeedUserTypes from 'common/feed-user-type';
import CustomizeProp from 'common/prop-types/customize';
import { AvatarTeam, AvatarUser } from 'components/shared/uniform';
import { getSportByName } from 'components/shared/uniform/sport-helpers';

class ProfileImage extends React.Component {
  static propTypes = {
    beginEditOfPicture: React.PropTypes.func,
    customize: CustomizeProp.isRequired,
    fadeOut: React.PropTypes.bool,
    feedUserType: React.PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
    forcePublicView: React.PropTypes.bool.isRequired,
    isPrivilegedUser: React.PropTypes.bool,
    isUserProfile: React.PropTypes.bool,
    onChangeProfileImage: React.PropTypes.func.isRequired,
    onErrorPicture: React.PropTypes.func,
    ownerInitial: React.PropTypes.string,
    profileLogoUri: React.PropTypes.string,
    mobileProfileLogoUri: React.PropTypes.string,
    primaryColor: React.PropTypes.string,
    primaryName: React.PropTypes.string.isRequired,
    secondaryColor: React.PropTypes.string,
    sportName: React.PropTypes.string,
    isMobile: React.PropTypes.bool,
  }

  componentDidMount() {
    const qq = window.__hudlProfiles && window.__hudlProfiles.qq;
    if (!qq) {
      return;
    }

    this.uploader = new qq.FineUploaderBasic({
      button: this.refs.qqButton,
      debug: true,
      camera: {
        ios: true,
      },
      multiple: false,
      validation: {
        acceptFiles: [
          'image/jpeg',
          'image/pjpeg',
          'image/png',
          'image/gif',
          'image/bmp',
          'image/x-windows-bmp',
        ],
        sizeLimit: 1024 * 1024 * 10,
        allowedExtensions: ['jpeg', 'jpg', 'png', 'gif', 'bmp'],
        image: {
          minHeight: 180,
          minWidth: 180,
        },
      },
      request: {
        endpoint: '/Services/AvatarUploader.ashx',
      },
      text: {
        fileInputTitle: 'Change Photo',
      },
      messages: {
        emptyError: 'The picture you uploaded is empty. Please choose a different photo.',
        minHeightImageError: 'The picture you uploaded is too small. '
          + `Please choose a photo that\'s at least 180 x 180.`,
        minWidthImageError: 'The picture you uploaded is too small. '
          + `Please choose a photo that\'s at least 180 x 180.`,
        noFilesError: 'No files were given to upload.',
        sizeError: 'The picture you uploaded is too large. Please choose a photo that\'s less than 10MB in size.',
        typeError: 'The uploaded image file needs to be a jpeg, jpg, gif, or bmp. ' +
          'Try again with one of these file types.',
      },
      callbacks: {
        onComplete: (id, fileName, response) => {
          this.setState({
            inProgress: false,
            progress: 0,
          });
          if (response.success) {
            this.props.onChangeProfileImage(response.url, response.id);
          }
        },
        onError: (id, name, errorReason) => {
          this.props.onErrorPicture(errorReason, true);
          this.setState({
            error: true,
            errorReason,
            inProgress: false,
            progress: 0,
          });
        },
        onCancel: () => {
        },
        onProgress: (id, name, uploadedBytes, totalBytes) => {
          const progressPercent = Math.floor(uploadedBytes / totalBytes * 100);
          this.setState({
            inProgress: true,
            progress: progressPercent,
          });
        },
        onSubmit: () => {
          this.setState({
            inProgress: true,
            progress: 0,
            error: false,
            errorReason: null,
          });
        },
      },
    });
  }

  beginEditProfileImage() {
    this.props.beginEditOfPicture('Profile');
  }

  cameraImage() {
    return (
      <object id="camera-icon" className="camera-icon-profile" data="https://static.hudl.com/profiles/images/icons/icon-camera.svg">
      </object>
    );
  }

  render() {
    const primaryColor = this.props.primaryColor;
    const secondaryColor = this.props.secondaryColor;
    const wrapClass = 'prof-image-wrap' + (this.props.fadeOut ? ' fade-out' : '');
    const backgroundImage = (this.props.customize.profileImage &&
      this.props.customize.profileImage.savedValue)
      || (this.props.isMobile && this.props.mobileProfileLogoUri)
      || this.props.profileLogoUri;

    if (this.props.isPrivilegedUser && !this.props.forcePublicView && this.props.feedUserType !== FeedUserTypes.Hudl) {
      // If they're not a Hudl user type, they have to be a team or user
      if (this.props.feedUserType === FeedUserTypes.User) {
        return (
          <div className={wrapClass}>
            <div className="prof-image qq-profile-button-upload"
              ref="qqButton"
              onClick={this.beginEditProfileImage.bind(this)}>
              <AvatarUser
                size="profile"
                imageUrl={backgroundImage}
                initials={this.props.ownerInitial}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                showOuterBorder
              />
              <div className="shadow"></div>
              {this.cameraImage()}
            </div>
          </div>
        );
      }

      // This is for the team user FeedUserType
      return (
        <div className={wrapClass}>
          <div className="prof-image qq-profile-button-upload"
            ref="qqButton"
            onClick={this.beginEditProfileImage.bind(this)}>
            <AvatarTeam
              size="profile"
              imageUrl={backgroundImage}
              sport={getSportByName(this.props.sportName)}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              showOuterBorder
            />
            <div className="shadow"></div>
            {this.cameraImage()}
          </div>
        </div>
      );
    }

    if (this.props.feedUserType === FeedUserTypes.User) {
      return (
        <div className={wrapClass}>
          <div className="prof-image">
            <AvatarUser
              size="profile"
              imageUrl={backgroundImage}
              initials={this.props.ownerInitial}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              showOuterBorder
            />
          </div>
        </div>
      );
    }

    return (
      <div className={wrapClass}>
        <div className="prof-image">
          <AvatarTeam
            size="profile"
            imageUrl={backgroundImage}
            sport={getSportByName(this.props.sportName)}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            showOuterBorder
          />
        </div>
      </div>
    );
  }
}

export default ProfileImage;
