import { CLOSE_DIALOG, OPEN_DIALOG } from 'common/actions';
import * as DialogTypes from 'common/dialog-types';

function currentDialog(state = { dialogType: DialogTypes.NONE }, action) {
  switch (action.type) {
  case CLOSE_DIALOG:
    return { dialogType: DialogTypes.NONE };
  case OPEN_DIALOG:
    return action.payload;
  default:
    return state;
  }
}

export default currentDialog;
