import React from 'react';
import PropTypes from 'prop-types';
import { Card, Subhead, Text } from 'components/shared/uniform';

import styles from './styles.scss';

const Bio = ({ bio }) => {
  if (!bio || !bio.length) {
    return null;
  }

  const header = (
    <Subhead level="default" className={styles['bio-card__header']}>
      Bio
    </Subhead>
  );

  return (
    <div className={styles['bio-card']} data-qa-id="bio-card">
      <Card header={header}>
        <Text level="small">
          {bio}
        </Text>
      </Card>
    </div>
  );
};

Bio.propTypes = {
  bio: PropTypes.string,
};

export default Bio;
