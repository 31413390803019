import React from 'react';

class MessagingDialog extends React.Component {
  static propTypes = {
    userId: React.PropTypes.string,
    onClose: React.PropTypes.func.isRequired,
  }

  state = {
    isOpen: true,
  }

  onComplete() {
    this.setState({ isOpen: false });
    this.props.onClose();
  }

  onError(reason) {
    throw new Error(`MessageButton: ${reason}`);
  }

  getOptions() {
    const options = {
      origin: 'AthleteProfile',
      recipientSelection: {
        recipientsPrefill: [
          {userId: this.props.userId},
        ],
      },
      message: {
        isEnabled: true,
        isVisible: true,
      },
      sendCallback: this.onComplete.bind(this),
      errorCallback: this.onError.bind(this),
      cancelCallback: this.onComplete.bind(this),
    };

    return options;
  }

  // hudl-messaging is loaded this way in window instead of via `import "hudl-messaging" 
  // so that unregistered-user-profile page doens't have to include those packages
  messagingComponents() {
    window.__hudlProfiles = window.__hudlProfiles || {};
    window.__hudlProfiles.messaging = window.__hudlProfiles.messaging || {};
    return window.__hudlProfiles.messaging;
  }

  render() {
    const {
      ModalMessageComposer,
      ComposerOptionsRecord,
    } = this.messagingComponents();
    return (
      <ModalMessageComposer
        title="Share"
        isOpen={this.state.isOpen}
        options={ComposerOptionsRecord.create(this.getOptions())}
      />
    );
  }
}

export default MessagingDialog;
