/* eslint-disable max-len */
import React from 'react';

import './feedback-survey.scss';

class FeedbackSurvey extends React.Component {
  static propTypes = {
    notFirstCard: React.PropTypes.bool,
    onDismiss: React.PropTypes.func.isRequired,
    feedbackUrl: React.PropTypes.string.isRequired,
    feedbackOnClick: React.PropTypes.func.isRequired,
  }

  componentDidMount() {
    const typeformScript = document.createElement('script');

    typeformScript.text = '(function(){var qs,js,q,s,d=document,gi=d.getElementById,' +
    "ce=d.createElement,gt=d.getElementsByTagName,id='typef_orm'," +
    "b='https://s3-eu-west-1.amazonaws.com/share.typeform.com/';" +
    "if(!gi.call(d,id)){js=ce.call(d,'script');js.id=id;js.src=b+'share.js';q=gt.call(d,'script')[0];" +
    'q.parentNode.insertBefore(js,q)}})()';
    this.refs.typeformShare.appendChild(typeformScript);
  }

  dismissAllFeedback() {
    this.props.feedbackOnClick();
  }

  render() {
    const style = {
      marginTop: this.props.notFirstCard ? '1.25rem' : '',
    };
    return (
      <div className="feeditem shared-image" style={style}>
        <div className="feedcontent_w">
          <div className="feeditem-header feed-user-data">
            <div className="profile">
              <img className="open-mini-profile" src="https://static.hudl.com/profiles/images/logos/hudl-logo.png"/>
            </div>
            <div className="feedcontent">
              <div className="feedback-intro-close" onClick={this.props.onDismiss}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" version="1.1"><g stroke="none" strokeWidth="1" fillRule="evenodd"><g transform="translate(-895.000000, -118.000000)"><g transform="translate(359.000000, 105.000000)"><g transform="translate(536.000000, 13.000000)"><path d="M0 1.5L4.5 6 0 10.5 1.5 12 6 7.5 10.5 12 12 10.5 7.5 6 12 1.5 10.5 0 6 4.5 1.5 0 0 1.5Z" /></g></g></g></g></svg>

              </div>
              <h4 className="feedback-feedmessage feedmessage">
                  <div className="fake-timeline-name">Hudl</div>
                  <span className="action">tell us what you think of the new profiles.</span>
              </h4>
            </div>
          </div>
          <div className="feeditem-content">
            <div className="feeditem-message">
            </div>
            <div className="feedattachments">
              <div className="feedattachments-wrapper">
                <div className="content">
                <div className="media-container-feedback">
                  <div className="cover-image">
                    <img className="feedback-background"
                    src="https://static.hudl.com/profiles/images/backgrounds/feedback-image.png"/>
                    <div className="image-overlay border-only"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-subtext" >
              <a href={this.props.feedbackUrl}
                data-mode="1" target="_blank"
                className="typeform-share link
                uni-btn--block uni-btn uni-btn--primary feedback"
                ref="typeformShare">Give Us Feedback</a>
            </div>
            </div>
          </div>
      </div>
    );
  }
}

export default FeedbackSurvey;
