function doNotTrack() {
  return (
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
    navigator.doNotTrack === '1' || navigator.doNotTrack === 'yes' || // Standards compliant
    !!navigator.msDoNotTrack || // IE9
    !!window.doNotTrack // Safari & IE11
  );
}

function getAdTrackingIdAndSetCookie() {
  if (doNotTrack()) {
    // Delete the _turn_uid cookie
    document.cookie = '_turn_uid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    return null;
  }

  // From https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  const currentTurnId = document.cookie.replace(/(?:(?:^|.*;\s*)_turn_uid\s*\=\s*([^;]*).*$)|^.*$/, '$1');

  if (window._turn_uid && window._turn_uid !== currentTurnId) {
    document.cookie = `_turn_uid=${window._turn_uid}; Path=/;`;
  }

  return window._turn_uid; // Possible to not be set if blocked by adblocker
}

export default {
  getAdTrackingIdAndSetCookie: getAdTrackingIdAndSetCookie,
};
