import React from 'react';
import IconNike from 'components/shared/icons/icon-nike-verified';
import AthleticismFields from 'common/athleticism-fields';
import { Button, Text } from 'components/shared/uniform';

class StatisticsList extends React.Component {
  static propTypes = {
    user: React.PropTypes.shape({
      description: React.PropTypes.string,
      jersey: React.PropTypes.string,
      positions: React.PropTypes.string,
      primaryName: React.PropTypes.string.isRequired,
      firstName: React.PropTypes.string,
      lastName: React.PropTypes.string,
      profileBannerUri: React.PropTypes.string.isRequired,
      profileLogoUri: React.PropTypes.string,
      profileUrlBase: React.PropTypes.string.isRequired,
      teams: React.PropTypes.arrayOf(React.PropTypes.shape({
        name: React.PropTypes.string.isRequired,
        location: React.PropTypes.string.isRequired,
        logoUri: React.PropTypes.string,
        primaryColor: React.PropTypes.string,
        secondaryColor: React.PropTypes.string,
        startYear: React.PropTypes.number,
        endYear: React.PropTypes.number,
        profileUrl: React.PropTypes.string.isRequired,
        teamId: React.PropTypes.string.isRequired,
      })),
      userId: React.PropTypes.string,
      featuredReelId: React.PropTypes.string,
    }).isRequired,
    about: React.PropTypes.shape({
      overview: React.PropTypes.shape({
        graduationYear: React.PropTypes.number,
        height: React.PropTypes.string,
        heightWeightVerified: React.PropTypes.bool,
        weight: React.PropTypes.string,
        nikeFootballRating: React.PropTypes.number,
        nikeFootballRatingVerified: React.PropTypes.bool,
      }),
      strengthAndSpeed: React.PropTypes.object.isRequired,
    }),
  }

  constructor(props) {
    super(props);
    this.state = {
      statsNumber: 8,
      viewLess: false,
    };
  }

  onClickMoreButton = () => {
    this.setState({
      statsNumber: this.totalStats,
      viewLess: true,
    });
  }

  onClickLessButton = () => {
    this.setState({
      statsNumber: 8,
      viewLess: false,
    });
  }

  renderStats() {
    const formatStat = (label, value, verified) => {
      return (
        <li key={label}>
          <Text color="nonessential">{label}:</Text> <Text>{value}</Text>
          {verified ? (
            <span className="nike-verified">
              <IconNike/>
            </span>
          ) : null}
        </li>
      );
    };
    let stats = [];
    const { jersey, positions } = this.props.user;
    const { graduationYear, height, weight, heightWeightVerified,
    nikeFootballRating, nikeFootballRatingVerified } = this.props.about.overview;
    const athleticism = this.props.about.strengthAndSpeed;
    if (jersey) {
      stats.push(formatStat('Jersey #', jersey));
    }
    if (positions) {
      if (positions.length < 2) {
        stats.push(formatStat('Position', positions));
      } else {
        stats.push(formatStat('Positions', positions));
      }
    }
    if (height && weight) {
      const hplusw = height + ' ' + weight;
      stats.push(formatStat('Height & Weight', hplusw, heightWeightVerified));
    } else if (height) {
      stats.push(formatStat('Height', height, heightWeightVerified));
    } else if (weight) {
      stats.push(formatStat('Weight', weight, heightWeightVerified));
    }

    if (nikeFootballRating) {
      stats.push(formatStat('Nike Football Rating', nikeFootballRating, nikeFootballRatingVerified));
    }
    for (const key in AthleticismFields) {
      if (athleticism[key]) {
        stats.push(formatStat(AthleticismFields[key].label, athleticism[key], athleticism[key + 'Verified']));
      }
    }
    if (graduationYear) {
      stats.push(formatStat('Class of', graduationYear));
    }

    this.hasStats = !!stats.length;
    this.moreStats = stats.length > 8;
    this.totalStats = stats.length;
    if (this.moreStats) {
      stats = stats.slice(0, this.state.statsNumber);
    }
    return stats;
  }
  render() {
    return (
      <div className="stats">
        <ul className="stats-list">{this.renderStats()}</ul>
        {this.moreStats ?
          (<div className="stats-more-container">
            <Button
              size="small"
              type="subtle"
              style="minimal"
              icon={this.state.viewLess ? 'uicollapseregionvertical' : 'uiexpandregionvertical'}
              onClick={this.state.viewLess ? this.onClickLessButton : this.onClickMoreButton}>
                {this.state.viewLess ? 'Less' : 'More'}
            </Button>
          </div>) : null}
        {this.hasStats ?  <div className="section-divider"></div> : null}
      </div>
    );
  }
}
export default StatisticsList;
