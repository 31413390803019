import _ from 'underscore';
import { getBaseProperties } from './logging-utility';
import { Xhr } from 'hudl-base';

function logProfileShare(ownerId, network) {
  if (!ownerId) {
    throw new Error('Missing owner ID');
  }

  if (!network) {
    throw new Error('Missing network');
  }

  const baseProperties = getBaseProperties();
  const shareRequest = _.extend(baseProperties, {
    network: network,
    ownerId: ownerId,
  });

  // No callbacks, just fire-and-forget.
  Xhr.instance.post('/api/v2/feedproducer/feed-user-log/share-profile', shareRequest);
}

export default {
  logProfileShare,
};
