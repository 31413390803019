import React from 'react';
import PropTypes from 'prop-types';
import { Card, ItemTitle, Link, Subhead, Text } from 'components/shared/uniform';
import { getTwitterProfileLink } from 'utility/twitterUtility';
import EmptyCardValue from '../EmptyCardValue';

import styles from './styles.scss';

const PersonalContactCard = ({
  city,
  email,
  name,
  phone,
  postalCode,
  relationship,
  state,
  streetAddress,
  twitter,
}) => {
  const isAthlete = !relationship;
  const qaId = `personal-contact-card-${relationship ? relationship.toLowerCase() : 'self'}`;
  return (
    <div className={styles['contact-card']} data-qa-id={qaId}>
      <Card>
        <div>
        { isAthlete
          ? <div className={styles['contact-card__header']}><Subhead>{name}</Subhead></div>
          : (
            <div className={styles['contact-card__header']}>
              <Subhead>{relationship}</Subhead>
            </div>
          )
        }
        { !isAthlete && (
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Name:</ItemTitle>
            <Text className={styles['contact-card__row-value']}>{name || <EmptyCardValue />}</Text>
          </div>
        )}
        <div className={styles['contact-card__row']}>
          <ItemTitle isCaps className={styles['contact-card__row-label']}>Phone:</ItemTitle>
          <Text className={styles['contact-card__row-value']}>{phone || <EmptyCardValue />}</Text>
        </div>
        <div className={styles['contact-card__row']}>
          <ItemTitle isCaps className={styles['contact-card__row-label']}>Email:</ItemTitle>
          <Text className={styles['contact-card__email']}>
            {email
              ? <Link href={`mailto:${email}`} type="default">{email}</Link>
              : <EmptyCardValue />
            }
          </Text>
        </div>
        { isAthlete && (
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Twitter:</ItemTitle>
            <Text className={styles['contact-card__email']}>
              {twitter
                ? <Link href={getTwitterProfileLink(twitter)} type="default">@{twitter}</Link>
                : <EmptyCardValue />
              }
            </Text>
          </div>
        )}
        { isAthlete && // we don't store an address for guardians...
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Address:</ItemTitle>
            <span className={styles['contact-card__row-value']}>
              {!streetAddress && !city && !state && !postalCode && <Text><EmptyCardValue /></Text>}
              {streetAddress && <Text>{streetAddress}</Text>}
              {city && <Text>{city}</Text>}
              {state && <Text>{state}</Text>}
              {postalCode && <Text>{postalCode}</Text>}
            </span>
          </div>
        }
        </div>
      </Card>
    </div>
  );
};

PersonalContactCard.propTypes = {
  relationship: PropTypes.string,
  name: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  twitter: PropTypes.string,
};

export default PersonalContactCard;
