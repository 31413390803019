import { UserContext, MediaUserSnowplowTracker } from 'hudl-user-tracking';

const BASE_TRACKER_SETTINGS = {
  appId: 'Hudl',
  encodeBase64: true,
  forceSecureTracker: true,
  platform: 'web',
  post: true,
  stateStorageStrategy: 'localStorage',
  userFingerprint: true,
  contexts: {
    webPage: true,
    geolocation: false,
  },
};

export const SNOWPLOW_MIN_VISIT_LENGTH = 120; // seconds
export const SNOWPLOW_HEARTBEAT_INTERVAL = 120; // seconds

export class MediaSnowplowManager {
  static _tracker;
  static _context;

  static initialize = (mediaSnowplowTrackerContext) => {
    if (!mediaSnowplowTrackerContext.isTrackerEnabled || this._tracker) {
      return;
    }
    this._context = mediaSnowplowTrackerContext;
    this._tracker = this.createTracker(this._context);
  }

  static getTrackerSettings = (isProduction, snowplowCollectorSubdomain) => {
    const domain = isProduction ? 'hudl.com' : 'thorhudl.com';
    const performanceTiming = !isProduction;
    const gaCookies = !isProduction;

    return {
      ...BASE_TRACKER_SETTINGS,
      collectorEndpoint: `${snowplowCollectorSubdomain}.${domain}`,
      cookieDomain: `.${domain}`,
      contexts: {
        ...BASE_TRACKER_SETTINGS.contexts,
        gaCookies,
        performanceTiming,
      },
    };
  }

  static createTracker = (mediaSnowplowTrackerContext) => {
    const { userId, isProduction, snowplowCollectorSubdomain } = mediaSnowplowTrackerContext;
    const trackerSettings = this.getTrackerSettings(isProduction, snowplowCollectorSubdomain);
    const config = {
      globalSnowplowName: 'hudlSnowplowMediaUserTracker',
      trackerName: 'MediaUserSnowplowTracker',
      trackerSettings,
      userId: String(userId),
    };
    return new MediaUserSnowplowTracker(config);
  };

  static trackPagePings = () => {
    if (!this._tracker) {
      return;
    }
    this._tracker.trackPagePings(SNOWPLOW_MIN_VISIT_LENGTH, SNOWPLOW_HEARTBEAT_INTERVAL);
  }

  static trackPage = (screenTrackingPageName) => {
    if (!this._tracker || !this._context) {
      return;
    }

    const {
      userId,
      role,
      backdooredUserId,
    } = this._context;

    const userContext = userId ? [new UserContext({
      user_id: String(userId),
      role,
      backdoored_user_id: String(backdooredUserId),
    })] : [];

    this._tracker.trackPage(screenTrackingPageName, userContext);
  }
}
