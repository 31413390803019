import React from 'react';
import Dialog from '../../shared/dialog/dialog';
import FollowerRow from './follower-row';
import { Spinner } from 'components/shared/uniform';

import './followers-dialog.scss';

class FollowersDialog extends React.Component {
  static propTypes = {
    onClose: React.PropTypes.func.isRequired,
    onScrollNearBottom: React.PropTypes.func.isRequired,
    addUserToModal: React.PropTypes.bool,
    title: React.PropTypes.string.isRequired,
    user: React.PropTypes.shape({
      userAsFollower: React.PropTypes.object.isRequired,
    }),
    followers: React.PropTypes.shape({
      requesting: React.PropTypes.bool.isRequired,
      allComplete: React.PropTypes.bool.isRequired,
      error: React.PropTypes.instanceOf(Error),
      oldestDateTime: React.PropTypes.instanceOf(Date),
      users: React.PropTypes.array.isRequired,
    }).isRequired,
    onFollowUnfollow: React.PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.followers.requesting || this.props.followers.allComplete || this.props.followers.error) {
      return;
    }

    document.querySelector('.react-modal-content').addEventListener('scroll', this._handleScroll.bind(this));
  }

  componentWillUnmount() {
    document.querySelector('.react-modal-content').removeEventListener('scroll', this._handleScroll.bind(this));
  }

  _handleScroll(e) {
    if (this.props.followers.requesting || this.props.followers.allComplete || this.props.followers.error) {
      return;
    }

    const element = e.target || e.srcElement;
    const scrollTop = element.scrollTop;
    const maxScroll = element.scrollHeight - element.clientHeight;
    const scrollPercentage = scrollTop / maxScroll;

    if (scrollPercentage > 0.75) {
      this.props.onScrollNearBottom();
    }
  }

  _renderFollowers() {
    let users;
    if (this.props.user && this.props.addUserToModal && this.props.user.userAsFollower) {
      users = [this.props.user.userAsFollower, ...this.props.followers.users];
    } else {
      users = this.props.followers.users;
    }
    return users.map(f => {
      return (<FollowerRow key={f.userId} follower={f} user={this.props.user}
        onFollowUnfollow={this.props.onFollowUnfollow} />);
    });
  }

  _renderSpinner() {
    if (this.props.followers.requesting) {
      return (
        <div className="team-followers-spinner">
          <Spinner size="medium" spinningState="spinning" />
        </div>
      );
    }
  }

  _renderErrorDisplay() {
    if (this.props.followers.error) {
      return (
        <div className="team-followers-error-requesting">
          Error retrieving users. Please try again later.
        </div>
      );
    }
  }

  render() {
    return (
      <Dialog onClose={this.props.onClose} title={this.props.title} className="followers-dialog">
        <div className="followers-content">
          {this._renderFollowers()}
          {this._renderSpinner()}
          {this._renderErrorDisplay()}
        </div>
      </Dialog>
    );
  }
}

export default FollowersDialog;
