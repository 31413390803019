import { TOGGLE_FORCE_PUBLIC_VIEW } from 'common/actions';

function forcePublicView(state = false, action) {
  switch (action.type) {
  case TOGGLE_FORCE_PUBLIC_VIEW:
    return action.payload.forcePublicView;
  default:
    return state;
  }
}

export default forcePublicView;
