import React from 'react';
import FollowButton from 'components/shared/buttons/follow-button';
import FeedUserType from 'common/feed-user-type';
import { AvatarTeam, AvatarUser, Text } from 'components/shared/uniform';
import { getSportByNumber } from 'components/shared/uniform/sport-helpers';

import './follower-row.scss';

class FollowBar extends React.Component {
  static propTypes = {
    follower: React.PropTypes.shape({
      feedUserType: React.PropTypes.number.isRequired,
      userId: React.PropTypes.string.isRequired,
      fullName: React.PropTypes.string.isRequired,
      jerseyNumber: React.PropTypes.string,
      positions: React.PropTypes.String,
      primarySport: React.PropTypes.number,
      schoolName: React.PropTypes.String,
      schoolLocation: React.PropTypes.String,
      followerCount: React.PropTypes.number,
      currentUserIsFollowing: React.PropTypes.bool,
      profileImageUri: React.PropTypes.string,
      primaryColor: React.PropTypes.string,
      secondaryColor: React.PropTypes.string,
      followRequestPending: React.PropTypes.bool,
      followRequestError: React.PropTypes.instanceOf(Error),
      hasUserProfile: React.PropTypes.bool,
      vanityUrl: React.PropTypes.string,
      performanceCenterUrl: React.PropTypes.string,
    }),
    user: React.PropTypes.shape({
      userId: React.PropTypes.string,
      currentUserRoles: React.PropTypes.object,
    }),
    onFollowUnfollow: React.PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
  }

  _getTeamFollowerThumbnail() {
    if (this.props.follower.feedUserType === FeedUserType.User) {
      let initials = '';
      const words = this.props.follower.fullName.trim().split(/\s+/);
      words.forEach(name => {
        initials += name.substring(0, 1);
      });

      return (
        <AvatarUser
          className="uni-margin--one--right"
          size="medium"
          imageUrl={this.props.follower.profileImageUri}
          initials={initials}
          primaryColor={this.props.follower.primaryColor}
          secondaryColor={this.props.follower.secondaryColor}
        />
      );
    }

    return (
      <AvatarTeam
        className="uni-margin--one--right"
        size="medium"
        imageUrl={this.props.follower.profileImageUri}
        sport={getSportByNumber(this.props.follower.primarySport)}
        primaryColor={this.props.follower.primaryColor}
        secondaryColor={this.props.follower.secondaryColor}
      />
    );
  }

  _getFollowButton() {
    const userIsLoggedIn = !!(this.props.user && this.props.user.userId);
    const sameUser = userIsLoggedIn && this.props.user.userId === this.props.follower.userId
      && this.props.follower.feedUserType === FeedUserType.User;

    const userIsOnTeam = userIsLoggedIn && this.props.follower.feedUserType === FeedUserType.Team
      && this.props.user.currentUserRoles.hasOwnProperty(this.props.follower.userId) !== false;

    if (userIsLoggedIn && !(sameUser || userIsOnTeam)) {
      return (
        <FollowButton
          feedUserTypeId={this.props.follower.feedUserType}
          feedUserRelatedId={this.props.follower.userId}
          isFollowing={this.props.follower.currentUserIsFollowing}
          requesting={!!this.props.follower.followRequestPending}
          error={this.props.follower.followRequestError}
          onClick={this.props.onFollowUnfollow}
          hideText
          allowUnfollow
        />
      );
    }

    return undefined;
  }

  _getFollowerSportAndSchool() {
    if (this.props.follower.schoolName || this.props.follower.schoolLocation) {
      return (
        <Text level="small">
          <span key={this.props.follower.userId + '-school-name'}
            className="team-follower-school-name">
            {this.props.follower.schoolName || ''}
          </span>
          <span key={this.props.follower.userId + '-middot'}
            className="team-follower-middot">&nbsp;&middot;&nbsp;</span>
          <span key={this.props.follower.userId + '-school-location'}
            className="team-follower-school-location">
            {this.props.follower.schoolLocation || ''}
          </span>
        </Text>
      );
    }

    return undefined;
  }

  _getFollowerName() {
    const { follower } = this.props;

    if (follower.hasUserProfile) {
      const profileUrl = `/profiles/feed-user/${follower.feedUserType}-${follower.userId}`;
      return (
        <Text>
          <a href={profileUrl} className="uni-link--implied team-follower-full-name">
              <strong>{follower.fullName}</strong>
          </a>
        </Text>
      );
    }

    return (
      <span className="team-follower-full-name">
        <Text>
          <strong>{follower.fullName}</strong>
        </Text>
      </span>
    );
  }

  render() {
    return (
      <div className="team-follower-row">
        {this._getTeamFollowerThumbnail()}
        <div className="team-follower-info">
          <div className="team-follower-info-top">
            {this._getFollowerName()}
            &nbsp;
            <span className="uni-text--micro uni-margin--half--left team-follower-jersey-number">
              {this.props.follower.jerseyNumber ? '#' + this.props.follower.jerseyNumber : ''}
            </span>
            &nbsp;
            <span className="team-follower-positions">
              <Text className="uni-margin--half--left" level="micro">
                {this.props.follower.positions || ''}
              </Text>
            </span>
          </div>
          <div className="team-follower-info-middle">
            {this._getFollowerSportAndSchool()}
          </div>
          <div className="team-follower-info-bottom">
            <span className="team-follower-followers-count">
              <Text level="micro" color="nonessential">
                {this.props.follower.followerCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Followers
              </Text>
            </span>
          </div>
        </div>
        {this._getFollowButton()}
      </div>
    );
  }
}

export default FollowBar;
