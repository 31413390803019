import React from 'react';
import classNames from 'classnames';
import CloseIcon from 'hudl-react-kickoff/icons/Close';

import './notification.scss';

function notification({type, children, onDismiss, onUndo, showUndo}) {
  const classes = classNames({
    'profile-notification': true,
    'success': type === 'success',
    'error': type === 'error',
  });
  return (
    <div className={classes}>
      {children}
      {' '}
      {showUndo
        ? <a onClick={onUndo}>Undo</a>
        : null}
      {onDismiss
        ? <CloseIcon onClick={onDismiss} />
        : null}
    </div>
  );
}

notification.propTypes = {
  type: React.PropTypes.oneOf(['success', 'error']),
  onDismiss: React.PropTypes.func,
  onUndo: React.PropTypes.func,
  showUndo: React.PropTypes.bool,
  children: React.PropTypes.oneOfType([
    React.PropTypes.node,
    React.PropTypes.arrayOf(React.PropTypes.node),
  ]),
};

export default notification;
