import postscribe from 'postscribe';

const className = 'async-script';
export default function loadAsyncScripts() {
  // Setup async scripts (such as Turn) to prevent them from blocking page load.
  const asyncScripts = document.getElementsByClassName(className);
  if (!asyncScripts || !asyncScripts.length) {
    return;
  }
  Object.values(asyncScripts).forEach(scriptDiv => {
    const src = scriptDiv.getAttribute('data-src');
    const scriptTag = `<script src="${src}"></script>`;
    // Remove the class so it doesn't get re-processed
    if (scriptDiv.classList.contains(className)) {
      scriptDiv.classList.remove(className);
    }
    // Inject the script asynchronously
    postscribe(scriptDiv, scriptTag);
  });
}
