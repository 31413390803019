import {
  CLEAR_STAGED_UPDATES,
  REQUEST_TEAM_COLOR_UPDATE,
  RECEIVE_TEAM_COLOR_UPDATED,
  STAGE_UPDATE,
  TIMEOUT_TEAM_COLOR_UPDATED,
  UNSTAGE_UPDATE,
  FAIL_TEAM_COLOR_UPDATE,
} from 'common/actions';
import * as EditTypes from 'common/edit-types';

const defaultState = {
  value: null,
  saveSuccess: false,
  saveError: false,
  error: false,
};

function color(state = {}, action) {
  const newState = $.extend(true, {}, defaultState, state);
  switch (action.type) {
  case CLEAR_STAGED_UPDATES:
    newState.value = newState.savedValue;
    newState.saveSuccess = false;
    newState.saveError = false;
    break;
  case STAGE_UPDATE:
  case UNSTAGE_UPDATE:
    const edit = action.payload;
    if (edit.type !== EditTypes.TEAM_COLOR) {
      return state;
    }

    newState.value = edit.teamColor;
    newState.saveSuccess = false;
    newState.saveError = false;
    break;
  case REQUEST_TEAM_COLOR_UPDATE:
    break;
  case RECEIVE_TEAM_COLOR_UPDATED:
    newState.value = action.payload.teamColor;
    newState.savedValue = action.payload.teamColor;
    newState.saveSuccess = true;
    newState.saveError = false;
    break;
  case FAIL_TEAM_COLOR_UPDATE:
    newState.saveSuccess = false;
    newState.saveError = true;
    break;
  case TIMEOUT_TEAM_COLOR_UPDATED:
    newState.saveSuccess = false;
    newState.saveError = false;
    break;
  default:
    return state;
  }

  return newState;
}

export default color;
