import about from './about-reducer';
import currentDialog from '../shared/dialog-reducer';
import currentUser from '../shared/user-reducer';
import customize from '../shared/customize-reducer';
import feedback from '../shared/feedback-reducer';
import forcePublicView from '../shared/force-public-view-reducer';
import followers from '../shared/followers-reducer';
import following from '../shared/following-reducer';
import games from '../shared/games-reducer';
import highlights from '../shared/highlights-reducer';
import notification from '../shared/notification-reducer';
import toast from '../shared/toast-reducer';
import user from './user-reducer';

const profileApp = {
  ads: state => state || {},
  about,
  bulkSaves: state => state || false,
  canDismissFeedItems: state => state || false,
  contactInfo: state => state || [],
  convertTimesToLocal: state => state || false,
  currentDialog,
  currentUser,
  customize,
  deviceInfoForLogging: state => state || {},
  feedback,
  feedContentItems: state => state || [],
  followers,
  following,
  forcePublicView,
  highlights,
  highlightEditorIdentityData: state => state || {},
  games,
  httpsAllowed: state => state || false,
  isMobile: state => state || false,
  hasHighlightsDisabled: state => state || false,
  notification,
  onboarding: state => state || {isDismissed: true},
  presetFilterType: state => state || 0,
  profileDateCreated: state => state || '',
  profileFuzzyDateCreated: state => state || '',
  recruiterAccess: state => state || null,
  recruitingSupportGuideUrl: state => state || null,
  seoFriendlyName: state => state || '',
  toast,
  user,
  mediaSnowplowTrackerContext: state => state || null,
  isAthleteOptedInToRecruit: state => state || false,
  athleteCommitment: state => state || {}
};

export default profileApp;
