import React from 'react';
import { connect } from 'react-redux';
import StatisticsList from './StatisticsList';
import TeamHistory from './TeamHistory';
import {
  followFeedUser,
  unfollowFeedUser,
  dismissFeedbackClick,
  dismissFeedback,
} from 'common/actions';
import Container from 'common/container-type';
import ContainerPlayer from 'common/container-player';
import ContainerSection from 'common/container-section';
import ContainerType from 'common/container-subtype';
import CustomizeProp from 'common/prop-types/customize';
import FeedUserTypes from 'common/feed-user-type';
import HighlightsProp from 'common/prop-types/highlights';
import TabWrapper from 'components/shared/profile-tabs/tab-wrapper';
import Timeline from 'components/shared/profile-tabs/timeline/timeline';

import DisplayAd from 'components/shared/display-ad';
import ads from 'utility/ads';
import DisplayAdModel from 'utility/ads/display-ad-model';
import { getPinnedFeedItem } from 'utility/feed-item-utility';
import browserDetect from '../../../utility/browser-detect';

class UserTimelineTab extends React.Component {
  static propTypes = {
    ads: React.PropTypes.object,
    about: React.PropTypes.shape({
      overview: React.PropTypes.shape({
        graduationYear: React.PropTypes.number,
        height: React.PropTypes.string,
        heightWeightVerified: React.PropTypes.bool,
        weight: React.PropTypes.string,
      }),
      strengthAndSpeed: React.PropTypes.object.isRequired,
    }),
    customize: CustomizeProp.isRequired,
    currentUser: React.PropTypes.shape({
      isPrivilegedUser: React.PropTypes.bool,
      userId: React.PropTypes.string,
    }),
    feedContentItems: React.PropTypes.arrayOf(React.PropTypes.object),
    isMobile: React.PropTypes.bool.isRequired,
    profileDateCreated: React.PropTypes.string,
    profileFuzzyDateCreated: React.PropTypes.string,
    user: React.PropTypes.shape({
      description: React.PropTypes.string,
      jersey: React.PropTypes.string,
      positions: React.PropTypes.string,
      primaryName: React.PropTypes.string.isRequired,
      firstName: React.PropTypes.string,
      lastName: React.PropTypes.string,
      profileBannerUri: React.PropTypes.string.isRequired,
      profileLogoUri: React.PropTypes.string,
      profileUrlBase: React.PropTypes.string.isRequired,
      teams: React.PropTypes.arrayOf(React.PropTypes.shape({
        name: React.PropTypes.string.isRequired,
        location: React.PropTypes.string.isRequired,
        logoUri: React.PropTypes.string,
        primaryColor: React.PropTypes.string,
        secondaryColor: React.PropTypes.string,
        startYear: React.PropTypes.number,
        endYear: React.PropTypes.number,
        profileUrl: React.PropTypes.string.isRequired,
        teamId: React.PropTypes.string.isRequired,
      })),
      userId: React.PropTypes.string,
      featuredReelId: React.PropTypes.string,
    }).isRequired,
    highlights: HighlightsProp,
    feedback: React.PropTypes.shape({
      feedbackEnabled: React.PropTypes.bool.isRequired,
      feedbackSurveyUrl: React.PropTypes.string.isRequired,
      optionSelected: React.PropTypes.bool,
    }).isRequired,
    onboarding: React.PropTypes.shape({
      isDismissed: React.PropTypes.bool,
      steps: React.PropTypes.arrayOf(React.PropTypes.string),
      currentStep: React.PropTypes.string,
    }).isRequired,
    canDismissFeedItems: React.PropTypes.bool,
    forcePublicView: React.PropTypes.bool,
    presetFilterType: React.PropTypes.number,
    dispatch: React.PropTypes.func.isRequired,
    seoFriendlyName: React.PropTypes.string.isRequired,
    onChangeTab: React.PropTypes.func.isRequired,
  }

  static contextTypes = {
    store: React.PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      showSharing: false,
    };
  }

  onFollowUnfollow(feedUserTypeId, feedUserRelatedId, isFollow) {
    const isCurrentProfile = feedUserTypeId === FeedUserTypes.User
      && feedUserRelatedId === this.props.user.userId;
    if (isFollow) {
      this.props.dispatch(followFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile));
    } else {
      this.props.dispatch(unfollowFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile));
    }
  }

  dismissFeedbackClick() {
    this.props.dispatch(dismissFeedbackClick(this.props.currentUser.userId));
  }

  dismissFeedback() {
    this.props.dispatch(dismissFeedback(this.props.currentUser.userId));
  }

  onShareClick() {
    this.setState({
      showSharing: true,
    });
  }

  onCloseShare() {
    this.setState({
      showSharing: false,
    });
  }

  renderBio() {
    const { user } = this.props;
    const bio = user.description ? user.description.trim() : '';

    this.hasBio = !!bio.length;
    if (!this.hasBio) {
      return null;
    }

    return (
      <p className="bio uni-text">{bio}</p>
    );
  }

  render() {
    const feedUser = {
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      profileUrlBase: this.props.user.profileUrlBase,
      profileLogoUri: this.props.user.profileLogoUri,
      type: FeedUserTypes.User,
      userId: this.props.user.userId,
      feedUserId: {
        type: FeedUserTypes.User,
        relatedId: this.props.user.userId,
      },
    };

    const logData = {
      container: Container.Profile,
      containerSection: ContainerSection.Timeline,
      containerType: ContainerType.User,
      player: ContainerPlayer.TimelineCard,
    };
    const isMobile = this.props.isMobile || browserDetect.isModernIPad;

    const featuredHighlightReel = this.props.user.featuredReelId && this.props.highlights.reels.find(h =>
      h.reelId === this.props.user.featuredReelId || h.legacyReelId === this.props.user.featuredReelId);
    const pinnedFeedItem = getPinnedFeedItem(this.props.user, this.props.currentUser, featuredHighlightReel, logData);
    const feedContentItems = this.props.feedContentItems || [];
    if (pinnedFeedItem) {
      feedContentItems.splice(0, 0, pinnedFeedItem);
    }

    const displayAdModel = new DisplayAdModel('div-gpt-ad-1470663650797-0', 'Display_AthleteTimeline_Desktop');

    return (
      <TabWrapper>
        <div className="timeline-tab" onClick={() => {}}>
          <div className="left-column">
            <StatisticsList
              about={this.props.about}
              user={this.props.user}
            />
            {this.renderBio()}
            {this.hasBio ?
              <div className="section-divider"></div> : null
            }
            <TeamHistory
              user={this.props.user}
            />
          </div>
          <Timeline
            ads={this.props.ads}
            canDismissFeedItems={this.props.canDismissFeedItems}
            presetFilterType={this.props.presetFilterType}
            currentUserId={this.props.currentUser.userId}
            customize={this.props.customize}
            bannerImage={this.props.user.profileBannerUri}
            feedback={this.props.feedback}
            forcePublicView={this.props.forcePublicView}
            feedContentItems={feedContentItems}
            isMobile={isMobile}
            isPrivilegedUser={!!(this.props.currentUser && this.props.currentUser.isPrivilegedUser)}
            // I don't usually like passing dispatch down, but in this case I'm trying to
            // extend the TimelineTab which can dispatch generic actions (e.g. advance onboarding
            // step or give feedback) while profile-specific tabs can dispatch more context-aware
            // actions
            dispatch={this.props.dispatch}
            dismissFeedback={this.dismissFeedback.bind(this)}
            dismissClickFeedback={this.dismissFeedbackClick.bind(this)}
            feedUser={feedUser}
            logData={logData}
            profileDateCreated={this.props.profileDateCreated}
            profileFuzzyDateCreated={this.props.profileFuzzyDateCreated}
          />
          <div className="right-column">
            {!isMobile
              ? <DisplayAd
                id={displayAdModel.getId()}
                path={displayAdModel.getPath()}
                sizes={[ads.sizes.MediumRectangle]}
                adSizeMapping={ads.mappings.all}
                />
              : null}
          </div>
        </div>
      </TabWrapper>
    );
  }
}

function select(state) {
  return state;
}

export default connect(select)(UserTimelineTab);
