const FeedContentType = {
  Unknown: 0,
  Text: 1,
  Image: 2,
  Video: 3,
  Link: 4,
  HudlMessage: 7,
  HudlLink: 20,
  SharedHighlight: 22,
  LocalizableHudlLink: 23,
};

export default FeedContentType;
