import $ from 'jquery';
import {
  RECEIVE_FEED_USER_FOLLOWED,
  RECEIVE_FEED_USER_UNFOLLOWED,
  SUCCESS_FEATURE_HIGHLIGHT,
  SUCCESS_UNFEATURE_HIGHLIGHT,
} from 'common/actions';

function user(state = {}, action) {
  const newState = $.extend(true, {}, state);
  switch (action.type) {
  // Actions around following the user
  case RECEIVE_FEED_USER_FOLLOWED:
    if (action.payload.isCurrentProfile) {
      newState.followCount += 1;
    }
    if (action.payload.userIsOnOwnProfile) {
      newState.followingCount += 1;
    }
    break;
  case RECEIVE_FEED_USER_UNFOLLOWED:
    if (action.payload.isCurrentProfile) {
      newState.followCount -= 1;
    }
    if (action.payload.userIsOnOwnProfile) {
      newState.followingCount -= 1;
    }
    break;
  case SUCCESS_FEATURE_HIGHLIGHT:
    newState.lastFeaturedReelId = newState.featuredReelId;
    newState.featuredReelId = action.payload.legacyReelId;
    break;
  case SUCCESS_UNFEATURE_HIGHLIGHT:
    newState.lastFeaturedReelId = newState.featuredReelId;
    newState.featuredReelId = null;
    break;
  default:
    return state;
  }
  return newState;
}

export default user;
