import React from 'react';
import PropTypes from 'prop-types';
import color from 'color';
import FeedUserTypes from 'common/feed-user-type';
import ProfileRibbon from 'components/shared/profile-ribbon/profile-ribbon';
import ProfileHeaderTitle from 'components/shared/profile-header-title';
import ProfileImage from 'components/shared/profile-image';
import ProfileNav from 'components/shared/profile-nav';
import CustomizeProp from 'common/prop-types/customize';

import './profile-header.scss';

class ProfileHeader extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    forcePublicView: PropTypes.bool.isRequired,
    hideNav: PropTypes.bool,
    hideFollow: PropTypes.bool,
    primaryName: PropTypes.string.isRequired,
    tagline: PropTypes.string,
    highlightViewCount: PropTypes.number.isRequired,
    highlightRecruiterViewCount: PropTypes.number.isRequired,
    followCount: PropTypes.number.isRequired,
    followingCount: PropTypes.number,
    isMobile: PropTypes.bool,
    isAthleteOptedInToRecruit: PropTypes.bool,
    isUserProfile: PropTypes.bool,
    displayFollowingCount: PropTypes.bool,
    feedUserType: PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
    feedUserId: PropTypes.string.isRequired,
    jersey: PropTypes.string,
    user: PropTypes.object,
    location: PropTypes.string,
    locationUrl: PropTypes.string,
    primaryTeam: PropTypes.shape({
      name: PropTypes.string,
      profileUrl: PropTypes.string,
      sportId: PropTypes.number,
    }),
    profileColor: PropTypes.string,
    profileLogoUri: PropTypes.string,
    mobileProfileLogoUri: PropTypes.string,
    secondaryColor: PropTypes.string,
    sportName: PropTypes.string,
    isPrivilegedUser: PropTypes.bool,
    currentTab: PropTypes.string.isRequired,
    onErrorPicture: PropTypes.func,
    onChangeTab: PropTypes.func.isRequired,
    onChangeProfileImage: PropTypes.func.isRequired,
    onFollowersCountClick: PropTypes.func.isRequired,
    onFollowingCountClick: PropTypes.func,
    onMessagingButtonClick: PropTypes.func,
    onShareButtonClick: PropTypes.func.isRequired,
    onPublicViewButtonClick: PropTypes.func.isRequired,
    onEditProfileClick: PropTypes.func.isRequired,
    onFollowUnfollow: PropTypes.func.isRequired,
    beginEditOfPicture: PropTypes.func,
    navItems: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isRecruiterOnly: PropTypes.bool,
    })),
    customize: CustomizeProp,
    onlyHighlights: PropTypes.bool,
    athleteCommitment: PropTypes.object,
    showRecruiterActivityEnabled: PropTypes.bool,
  }

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const nav = document.querySelector('.ko-nav');
    if (nav) {
      nav.style.position = 'fixed';
    }

    const mobileNav = document.querySelector('.ko-nav-mobile-header');
    if (mobileNav) {
      mobileNav.style.position = 'fixed';
    }
  }

  getInitial() {
    let initial = '';
    if (this.props.primaryName && this.props.primaryName.length) {
      const words = this.props.primaryName.trim().split(/\s+/);

      if (this.props.isUserProfile) {
        let initials = '';

        words.forEach(name => {
          initials += name.substring(0, 1);
        });

        initial = initials;
      } else {
        // Get first initial
        initial = words[0][0].toUpperCase();
      }
    }
    return initial;
  }

  render() {
    const initial = this.getInitial();
    const primaryColor = '#' + (this.props.profileColor || '38434f');
    const secondaryColor = this.props.secondaryColor ?
      '#' + this.props.secondaryColor :
      color(primaryColor).lighten(0.2).hexString();

    const profileNav = this.props.hideNav ? undefined : (
      <div className="prof-nav-wrapper" ref="subnavWrapper">
        <ProfileNav
          currentTab={this.props.currentTab}
          feedUserType={this.props.feedUserType}
          feedUserId={this.props.feedUserId}
          hideFollow={this.props.hideFollow}
          hideNav={this.props.hideNav}
          isSticky={this.state.stickyNav}
          navItems={this.props.navItems}
          onChangeTab={this.props.onChangeTab}
          onEditProfileClick={this.props.onEditProfileClick}
          onFollowUnfollow={this.props.onFollowUnfollow}
          onMessagingButtonClick={this.props.onMessagingButtonClick}
          onShareButtonClick={this.props.onShareButtonClick}
          onlyHighlights={this.props.onlyHighlights}
          ownerInitial={initial}
          primaryColor={primaryColor}
          primaryName={this.props.primaryName}
          profileLogoUri={this.props.profileLogoUri}
          mobileProfileLogoUri={this.props.mobileProfileLogoUri}
          isMobile={this.props.isMobile}
          secondaryColor={secondaryColor}
          user={this.props.user}
          ref="subnav"
        />
      </div>
    );

    return (
      <div className="parallax-layer front" ref="layer">
        <div className="prof-header">
          <div className="prof-ident-bg">
            <div className="prof-ribbon">
              <ProfileRibbon fill={primaryColor} className="prof-ribbon-primary"/>
              <ProfileRibbon fill={secondaryColor} className="prof-ribbon-secondary"/>
            </div>

            <section className="prof-ident">
              <ProfileImage
                beginEditOfPicture={this.props.beginEditOfPicture}
                customize={this.props.customize}
                fadeOut={this.state.stickyNav}
                forcePublicView={this.props.forcePublicView}
                feedUserType={this.props.feedUserType}
                isPrivilegedUser={this.props.isPrivilegedUser}
                isUserProfile={this.props.isUserProfile}
                onChangeProfileImage={this.props.onChangeProfileImage}
                onErrorPicture={this.props.onErrorPicture}
                ownerInitial={initial}
                primaryColor={this.props.profileColor}
                primaryName={this.props.primaryName}
                profileLogoUri={this.props.profileLogoUri}
                mobileProfileLogoUri={this.props.mobileProfileLogoUri}
                isMobile={this.props.isMobile}
                secondaryColor={this.props.secondaryColor}
                sportName={this.props.sportName}
              />

              <ProfileHeaderTitle
                primaryName={this.props.primaryName}
                showRecruiterActivityEnabled={this.props.showRecruiterActivityEnabled}
                tagline={this.props.tagline}
                displayFollowingCount={this.props.displayFollowingCount}
                feedUserType={this.props.feedUserType}
                feedUserId={this.props.feedUserId}
                followCount={this.props.followCount}
                followingCount={this.props.followingCount}
                forcePublicView={this.props.forcePublicView}
                hideFollow={this.props.hideFollow}
                highlightViewCount={this.props.highlightViewCount}
                highlightRecruiterViewCount={this.props.highlightRecruiterViewCount}
                isPrivilegedUser={this.props.isPrivilegedUser}
                isAthleteOptedInToRecruit={this.props.isAthleteOptedInToRecruit}
                location={this.props.location}
                locationUrl={this.props.locationUrl}
                onChangeTab={this.props.onChangeTab}
                onEditProfileClick={this.props.onEditProfileClick}
                onFollowersCountClick={this.props.onFollowersCountClick}
                onFollowingCountClick={this.props.onFollowingCountClick}
                onFollowUnfollow={this.props.onFollowUnfollow}
                onMessagingButtonClick={this.props.onMessagingButtonClick}
                onShareButtonClick={this.props.onShareButtonClick}
                primaryTeam={this.props.primaryTeam}
                user={this.props.user}
                hideNav={this.props.hideNav}
                athleteCommitment={this.props.athleteCommitment}
              />
            </section>
          </div>
        </div>
        {profileNav}
        {this.props.children}
      </div>
    );
  }
}

export default ProfileHeader;
