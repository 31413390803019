import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Environment, Icon, Link, Subhead, Text, Tooltip } from 'components/shared/uniform';
import g11n from 'hudl-g11n';

import styles from './game.scss';

const FormattedDate = g11n.ReactIntl.FormattedDate;
const FormattedMessage = g11n.ReactIntl.FormattedMessage;

const Game = ({ opponent, eventAt, hasBreakdown, thumbnailUrl, result, gameUrl, logPreviewClick,
  canDownload, downloadGameVideo, opponentTeamProfileUrl, breakdownDownloadUrl, previewMode }) => {
  const thumbnailStyle = thumbnailUrl ? {
    backgroundImage: `url('${thumbnailUrl}')`,
  } : {};

  const downloadVideoAction = downloadGameVideo && (
    <Button
      size="small"
      style="minimal"
      onClick={downloadGameVideo}
    >
      Download Video
    </Button>
  );

  let gameTitle = (
    <span className="uni-text--nonessential">
      vs. Unknown team
    </span>
  );

  let opponentName = '';
  if (opponent && (opponent.schoolName || opponent.name)) {
    opponentName = opponent.schoolName ? opponent.schoolName : opponent.name;
    gameTitle = `vs. ${opponentName}`;
  }

  const breakdownDownloadName = opponentName ? `${opponentName.trim()}_HudlTags.xml` : 'HudlTags.xml';
  const breakdownDownloadAction = breakdownDownloadUrl && (
    <a
      href={breakdownDownloadUrl}
      target="_blank"
      type="implied"
      download={breakdownDownloadName}
    >
      <Button
        size="small"
        style="minimal"
      >
        Download Tags
      </Button>
    </a>
  );

  const actions = !previewMode && canDownload && (downloadVideoAction || breakdownDownloadAction) && (
    <div className={styles.game__actions}>
      {downloadVideoAction}
      {breakdownDownloadAction}
    </div>
  );

  const playIcon = (
    <div className={styles['play-icon']}>
      <figure>
        <Icon type="play" size="medium" />
      </figure>
    </div>
  );

  const previewMessage = (
    <div className={styles['preview-message__container']} onClick={logPreviewClick}>
      <Text className={styles['preview-message__title']}>
        <FormattedMessage id="profiles.tab_games.owner.preview_only.video_thumbnail" defaultMessage="Preview Only" />
      </Text>
    </div>
  );

  return (
    <div className={styles.game__wrapper}>
      <Card>
        <a {... gameUrl ? { href: gameUrl } : {}}>
          <Environment env="dark">
            <div className={styles['game__thumbnail-container']} style={thumbnailStyle}>
              <div className={styles['game__thumbnail-overlay']}>
                <div />
                {previewMode ? previewMessage : playIcon}
                <div className={styles['game__thumbnail-info']}>
                  <div />
                  <Subhead level="small">
                    {result}
                  </Subhead>
                </div>
              </div>
            </div>
          </Environment>
        </a>

        <div className={styles.game__info}>
          <Subhead level="default" className={styles.game__title}>
            {opponentTeamProfileUrl &&
              <Link
                type="implied"
                href={opponentTeamProfileUrl}
                target="_blank"
              >
                {gameTitle}
              </Link>
            }
            {!opponentTeamProfileUrl &&
              gameTitle
            }
          </Subhead>

          <div className={styles['game__meta-columns']}>
            <div className={styles.game__meta}>
              <Text className={styles['game__meta-text']} color="nonessential" level="micro" >
                <FormattedDate
                  value={new Date(eventAt)}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              </Text>
            </div>
            <div>
              {hasBreakdown &&
                <figure>
                  <Button
                    style="minimal"
                    size="small"
                    icon={(
                      <Tooltip
                        text="Video is tagged"
                        position="left"
                        parent="button"
                      >
                        <Icon type="tag" size="small" />
                      </Tooltip>
                    )}
                  />
                </figure>
              }
            </div>
          </div>
          {actions}
        </div>
      </Card>
    </div>
  );
};

Game.propTypes = {
  opponent: PropTypes.shape({
    name: PropTypes.string,
    schoolName: PropTypes.string,
  }),
  eventAt: PropTypes.string,
  canDownload: PropTypes.bool,
  hasBreakdown: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
  result: PropTypes.string,
  gameUrl: PropTypes.string,
  breakdownDownloadUrl: PropTypes.string,
  opponentTeamProfileUrl: PropTypes.string,
  downloadGameVideo: PropTypes.func,
  eventId: PropTypes.string,
  previewMode: PropTypes.bool,
  logPreviewClick: PropTypes.func,
};

export default Game;
