import React from 'react';

const CustomizeProp = React.PropTypes.shape({
  profileImage: React.PropTypes.shape({
    value: React.PropTypes.string,
    savedValue: React.PropTypes.string,
  }),
  bannerImage: React.PropTypes.shape({
    value: React.PropTypes.string,
    savedValue: React.PropTypes.string,
  }),
  color: React.PropTypes.shape({
    value: React.PropTypes.string,
    savedValue: React.PropTypes.string,
    saveSuccess: React.PropTypes.bool.isRequired,
    saveError: React.PropTypes.bool.isRequired,
  }),
  tagline: React.PropTypes.shape({
    value: React.PropTypes.string,
    savedValue: React.PropTypes.string,
    saveSuccess: React.PropTypes.bool.isRequired,
    saveError: React.PropTypes.bool.isRequired,
    error: React.PropTypes.bool.isRequired,
    errorMessage: React.PropTypes.string,
  }),
  recruitOptIn: React.PropTypes.shape({
    value: React.PropTypes.bool,
  }),
});

export default CustomizeProp;
