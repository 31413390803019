import React from 'react';
import FollowIcon from 'components/shared/icon/follow-icon';
import FollowingIcon from 'components/shared/icon/following-icon';

import { Button } from 'components/shared/uniform';

class FollowButton extends React.Component {
  static propTypes = {
    feedUserTypeId: React.PropTypes.number.isRequired,
    feedUserRelatedId: React.PropTypes.string.isRequired,
    isFollowing: React.PropTypes.bool.isRequired,
    requesting: React.PropTypes.bool.isRequired,
    error: React.PropTypes.instanceOf(Error),
    onClick: React.PropTypes.func.isRequired,
    hideIcon: React.PropTypes.bool,
    hideText: React.PropTypes.bool,
    minimal: React.PropTypes.bool,
    primary: React.PropTypes.bool,
    size: React.PropTypes.string,
    allowUnfollow: React.PropTypes.bool.isRequired,
  }

  _onClick() {
    // Do nothing if a request is already in progress
    // Do nothing if already following and not allowed to unfollow
    if (!this.props.requesting && !(this.props.isFollowing && !this.props.allowUnfollow)) {
      // Send FeedUserId information and whether this is following or unfollowing
      this.props.onClick(this.props.feedUserTypeId, this.props.feedUserRelatedId, !this.props.isFollowing);
    }
  }

  render() {
    let type;
    if (this.props.isFollowing) {
      type = 'confirm';
    } else if (this.props.primary) {
      type = 'primary';
    } else {
      type = 'subtle';
    }

    let icon;
    if (!this.props.hideIcon) {
      icon = this.props.isFollowing ?
        <FollowingIcon className="uni-btn__icon"/> :
        <FollowIcon className="uni-btn__icon"/>;
    }

    let text;
    if (!this.props.hideText) {
      text = this.props.isFollowing ? 'Following' : 'Follow';
    }

    let status;
    if (this.props.error) {
      status = 'error';
    } else if (this.props.requesting) {
      status = 'spinning';
      text = undefined;
    }

    return (
      <span className="follow-button">
        <Button
          type={type}
          disabled={!this.props.allowUnfollow}
          icon={icon}
          onClick={() => this._onClick()}
          size={this.props.size || 'medium'}
          style={this.props.minimal ? 'minimal' : undefined}
          status={status}
          text={text}
          qaId="profile-follow-button"
        />
      </span>
    );
  }
}

export default FollowButton;
