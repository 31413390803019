const agent = navigator.userAgent || navigator.vendor || window.opera;

function isMobile() {
  // Based on code from http://detectmobilebrowsers.com/
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(agent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(agent.substr(0, 4)); // eslint-disable-line max-len
}

function isHandheldIOS() {
  return /ip(hone|od)/i.test(agent);
}

function isIOS() {
  return /ip(hone|od|ad)/i.test(agent);
}

function isAndroid() {
  return /android/i.test(agent);
}

// http://stackoverflow.com/questions/7184573/pick-up-the-android-version-in-the-browser-by-javascript
function getAndroidVersion() {
  const matches = agent.match(/Android\s([0-9\.]*)/i); // Returns: ["Android 2.2.1", "2.2.1"]
  if (matches && matches.length === 2) {
    return matches[1];
  }
  return '';
}

function isAndroid23OrLower() {
  const android = isAndroid();
  const version = getAndroidVersion();
  return android && +version.slice(0, 3) <= 2.3;
}

function isIE8() {
  const agentLower = agent.toLowerCase();
  const version = (agentLower.indexOf('msie') !== -1) ? parseInt(agentLower.split('msie')[1], 10) : false;
  return version && version === 8;
}

function isModernIPad() {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform);
}

const browserIsMobile = window.isMobile = isMobile();
const browserIsHandheldIOS = isHandheldIOS();
const browserIsIOS = isIOS();
const browserIsAndroid = isAndroid();
const androidVersion = getAndroidVersion();

export default {
  isMobile: browserIsMobile,
  isHandheldIOS: browserIsHandheldIOS,
  isIOS: browserIsIOS,
  isAndroid: browserIsAndroid,
  androidVerion: androidVersion,
  isLTEAndroid23: isAndroid23OrLower(),
  isIE8: isIE8(),
  isModernIPad: isModernIPad()
};
