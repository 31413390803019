import $ from 'jquery';
import {
  REQUEST_FOLLOW_FEED_USER,
  RECEIVE_FEED_USER_FOLLOWED,
  FAIL_FEED_USER_FOLLOW_REQUEST,
  REQUEST_UNFOLLOW_FEED_USER,
  RECEIVE_FEED_USER_UNFOLLOWED,
  FAIL_FEED_USER_UNFOLLOW_REQUEST,
} from 'common/actions';

const defaultState = {
  userId: null,
  isFollowing: false,
  pendingTeamFollowRequest: false,
  followRequestError: null,
};

function user(state = defaultState, action) {
  const newState = $.extend(true, {}, defaultState, state);
  switch (action.type) {
  case REQUEST_FOLLOW_FEED_USER:
  case REQUEST_UNFOLLOW_FEED_USER:
    if (action.payload.isCurrentProfile) {
      newState.pendingTeamFollowRequest = true;
    }
    break;
  case RECEIVE_FEED_USER_FOLLOWED:
    if (action.payload.isCurrentProfile) {
      newState.pendingTeamFollowRequest = false;
      newState.isFollowing = true;
    }
    break;
  case RECEIVE_FEED_USER_UNFOLLOWED:
    if (action.payload.isCurrentProfile) {
      newState.pendingTeamFollowRequest = false;
      newState.isFollowing = false;
    }
    break;
  case FAIL_FEED_USER_FOLLOW_REQUEST:
  case FAIL_FEED_USER_UNFOLLOW_REQUEST:
    if (action.payload.isCurrentProfile) {
      newState.pendingTeamFollowRequest = false;
      newState.followRequestError = action.payload;
    }
    break;
  default:
    return state;
  }
  return newState;
}

export default user;
