import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateHighlightMessage from './CreateHighlightMessage';
import NoVideosMessage from './NoVideosMessage';
import HighlightsProp from 'common/prop-types/highlights';
import HighlightsTab from 'components/shared/profile-tabs/highlights/highlights-tab';
import {
  openDialog,
  pinHighlight,
  unpinHighlight,
} from 'common/actions';
import * as DialogTypes from 'common/dialog-types';
import * as HighlightOwnerTypes from 'common/highlight-owner-types';
import ProfileType from 'common/container-subtype';

class UserHighlightsTab extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    forcePublicView: PropTypes.bool,
    highlights: HighlightsProp,
    highlightEditorIdentityData: PropTypes.shape({
      userId: PropTypes.string,
      teamId: PropTypes.string,
      isAdmin: PropTypes.bool,
      isAthlete: PropTypes.bool,
      isCoach: PropTypes.bool,
      sport: PropTypes.string,
    }),
    isMobile: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      primaryName: PropTypes.string.isRequired,
      featuredReelId: PropTypes.string,
    }).isRequired,
    currentUser: PropTypes.shape({
      isPrivilegedUser: PropTypes.bool,
    }),
    onChangeTab: PropTypes.func.isRequired,
  }

  getCreateHighlightMessage() {
    return (
      <CreateHighlightMessage
        highlightSupportUrl={this.props.highlights.highlightSupportUrl}
        libraryUrl={this.props.highlights.libraryUrl}
      />
    );
  }

  getNoHighlightsMessage() {
    return (
      <NoVideosMessage dispatch={this.props.dispatch}
        userId={this.props.user.userId}
        userName={this.props.user.primaryName}
        user={this.props.currentUser}
        onChangeTab={this.props.onChangeTab}
      />
    );
  }

  onShareVideoClick(id, title, videoUrl, tweetText) {
    this.props.dispatch(openDialog(
      DialogTypes.SHARE_VIDEO_DIALOG,
      {
        id,
        title,
        tweetText,
        url: videoUrl,
        type: 'Highlight',
      }));
  }

  onPinHighlight(ownerId, reelId, legacyReelId) {
    this.props.dispatch(pinHighlight(ownerId, reelId, legacyReelId, this.props.user.featuredReelId));
  }

  onUnpinHighlight(ownerId, reelId, legacyReelId) {
    this.props.dispatch(unpinHighlight(ownerId, reelId, legacyReelId, this.props.user.featuredReelId));
  }

  render() {
    return (
      <HighlightsTab
        tabType="Highlights"
        profileType={ProfileType.Athlete}
        dispatch={this.props.dispatch}
        forcePublicView={this.props.forcePublicView}
        highlights={this.props.highlights}
        highlightEditorIdentityData={this.props.highlightEditorIdentityData}
        highlightsArePrivate={false}
        ownerType={HighlightOwnerTypes.USER}
        ownerId={this.props.user.userId}
        userCanAccessPrivate={this.props.currentUser.isPrivilegedUser}
        isPrivilegedUser={this.props.currentUser.isPrivilegedUser}
        isMobile={this.props.isMobile}
        getPrivacyMessage={() => {}}
        getCreateHighlightMessage={this.getCreateHighlightMessage.bind(this)}
        getNoHighlightsMessage={this.getNoHighlightsMessage.bind(this)}
        onShareVideoClick={this.onShareVideoClick.bind(this)}
        canPinVideos
        onPinHighlight={this.onPinHighlight.bind(this)}
        onUnpinHighlight={this.onUnpinHighlight.bind(this)}
        featuredReelId={this.props.user.featuredReelId}
      />
    );
  }
}

function select(state) {
  return state;
}

export default connect(select)(UserHighlightsTab);
