import React from 'react';

const HighlightsProp = React.PropTypes.shape({
  createHighlightUrl: React.PropTypes.string,
  highlightSupportUrl: React.PropTypes.string,
  libraryUrl: React.PropTypes.string,
  reels: React.PropTypes.arrayOf(React.PropTypes.object),
  seasons: React.PropTypes.arrayOf(React.PropTypes.shape({
    orderUrl: React.PropTypes.string,
    year: React.PropTypes.number.isRequired,
  })),
  sort: React.PropTypes.string,
  view: React.PropTypes.string,
});

export default HighlightsProp;
