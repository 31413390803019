/* eslint-disable react/no-multi-comp */
import React, { PureComponent, PropTypes } from 'react';
import FeedUserTypes from 'common/feed-user-type';

const avatarPlaceholder = 'https://sc.hudl.com/images/thumb-user.svg';

class EndOfTimeline extends PureComponent
{
  static propTypes = {
    dateCreated: PropTypes.string.isRequired,
    fuzzyDateCreated: PropTypes.string,
    feedUser: React.PropTypes.shape({
      firstName: React.PropTypes.string,
      lastName: React.PropTypes.string,
      profileUrlBase: React.PropTypes.string,
      profileLogoUri: React.PropTypes.string,
      type: React.PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
      userId: React.PropTypes.string,
      teamId: React.PropTypes.string,
      schoolName: React.PropTypes.string,
      teamName: React.PropTypes.string,
    }),
  }

  render() {
    const name = this.props.feedUser.type === FeedUserTypes.Team ?
      this.props.feedUser.schoolName + ' - ' + this.props.feedUser.teamName :
      this.props.feedUser.firstName + ' ' + this.props.feedUser.lastName;

    const profileUrl = this.props.feedUser.type === FeedUserTypes.Team ?
      window.location.origin + '/team/' + this.props.feedUser.teamId :
      `${window.location.origin}${this.props.feedUser.profileUrlBase}`;

    const profileImage = this.props.feedUser.profileLogoUri || avatarPlaceholder;

    return (
      <div className="feeditem shared-image">
        <div className="feedcontent_w">
          <div className="feeditem-header feed-user-data"
            data-feed-user-type={this.props.feedUser.type}
            data-feed-user-related-id={this.props.feedUser.userId}>
            <div className="profile">
              <a className="open-mini-profile hudl-logo" href={profileUrl}>
                <img src={profileImage} alt={name}/>
              </a>
            </div>

            <div className="feedcontent">
              <h4 className="feedmessage">
                <a className="name open-mini-profile" href={profileUrl}>{name}</a>
              </h4>
              <abbr className="timestamp">
                {this.props.fuzzyDateCreated}
              </abbr>
            </div>
          </div>
          <div className="feeditem-content">
            <div className="feedattachments">
              <div className="feedattachments-wrapper">
                <div className="content">
                  <div className="media-container-timeline">
                    <div className="cover-image">
                      <div className="cover-image-overlay">
                        <img className="joined-hudl-logo" src="https://static.hudl.com/profiles/images/logos/hudl-mark-joined.png" />
                        <div className="end-of-timeline-message">
                          <p className="joined">Joined Hudl</p>
                          <p className="joined-date">{this.props.dateCreated}</p>
                        </div>
                      </div>
                      <img className="joined-background" src="https://static.hudl.com/profiles/images/backgrounds/gray-angles-joined.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EndOfTimeline;
