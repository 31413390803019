import React from 'react';
import PropTypes  from 'prop-types';
import { AvatarTeam, Card, Link, Subhead, Text } from 'components/shared/uniform';

// TODO: Should this really be in uniform?
import { getSportByName } from 'components/shared/uniform/sport-helpers';

import styles from './styles.scss';

const TeamHistoryCard = ({ teams, tabLink, usePersonalizedTitle, athleteName }) => {
  if (!teams || !teams.length) {
    return null;
  }

  const rows = [];

  // Render a row for each team in the user's history
  for (const team of teams) {
    let logoDiv;
    logoDiv = (
      <AvatarTeam
        className="uni-margin--half--right"
        size="medium"
        imageUrl={team.profileImageUri}
        sport={getSportByName(team.sportName)}
        primaryColor={team.primaryColor}
        secondaryColor={team.secondaryColor}
      />
    );

    // Add team to list, use profileUrl for key since it ought to be unique
    rows.push(
      <div key={`team-history-${team.profileUrl}`} className={styles['team-history-card__team']}>
        {logoDiv}
        <div className={styles['team-history-card__team-info']}>
          <Link
            href={tabLink ? `${team.profileUrl}/${tabLink}` : team.profileUrl}
            type="implied"
            target={tabLink ? '_blank' : '_self'}
          >
            <span className={styles['team-history-card__organization']}>
              <Subhead level="subtle">
                {team.organizationName || ''}
              </Subhead>
            </span>
            <span className={styles['team-history-card__team-name']}>
              <Text level="small">
                {team.teamName || ''}
              </Text>
            </span>
          </Link>
          <Text level="micro" color="nonessential">
            {team.formattedStartAndEnd || ''}
          </Text>
        </div>
        <Text level="micro" color="nonessential" className={styles['team-history-card__jersey']}>
          {team.jerseyNumber  ? '#' + team.jerseyNumber : ''}
        </Text>
        <Text level="micro" color="nonessential" className={styles['team-history-card__positions']}>
          {team.positions ? team.positions.join(', ') : ''}
        </Text>
      </div>
    );
  }

  const header = (
    <Subhead level="default" className={styles['team-history-card__header']}>
      {usePersonalizedTitle && athleteName ? `${athleteName}'s Teams` : 'Team History'}
    </Subhead>
  );

  return (
    <div className={styles['team-history-card']} data-qa-id="team-history-card">
      <Card header={header}>
        <div className={styles['team-history-card__list']}>
          {rows}
        </div>
      </Card>
    </div>
  );
};

TeamHistoryCard.propTypes = {
  teams: PropTypes.array,
  athleteName: PropTypes.string,
  tabLink: PropTypes.string,
  usePersonalizedTitle: PropTypes.bool,
};

export default TeamHistoryCard;
