import React from 'react';
import PropTypes from 'prop-types';
import { Headline, Text } from 'components/shared/uniform';

import g11n from 'hudl-g11n';

import styles from './styles.scss';

const NoRecruiterAccess = ({ size }) => {
  const title = g11n.format('profiles.recruit.incomplete_profile.title');

  const content = size === 'large'
    ? <Headline level="2">{title}</Headline>
    : <Text level="large" color="contrast">{title}</Text>;

  return (
    <div className={styles['no-recruiter-access']} data-qa-id="no-recruiter-access">
      {content}
    </div>
  );
};

NoRecruiterAccess.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
};

export default NoRecruiterAccess;
