import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Service } from 'hudl-base';
import { ReactIntl } from 'hudl-g11n';
const { FormattedMessage } = ReactIntl;

import UserRoles from 'common/enum/UserRoles';
import { Card, ItemTitle, Link, Note, Subhead, Text } from 'components/shared/uniform';
import EmptyCardValue from '../EmptyCardValue';

import styles from './styles.scss';

const TeamContactCard = ({
  city,
  currentUserRoleForTeam,
  email,
  contactName,
  phone,
  postalCode,
  orgName,
  sportName,
  state,
  streetAddress,
  teamId,
  teamName,
}) => {
  const teamProfileService = new Service('teamProfile', { teamId });
  const teamProfileUrl = teamProfileService.getUrl();

  return (
    <div className={styles['contact-card']} data-qa-id={`team-contact-card-${teamId}`}>
      <Card>
        <div>
          <div className={styles['contact-card__header']}>
            <ItemTitle isCaps>{sportName}</ItemTitle>
            <Link type="implied" href={teamProfileUrl} target="_blank">
              <Subhead>{orgName}&nbsp;-&nbsp;{teamName}</Subhead>
            </Link>
          </div>
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Name:</ItemTitle>
            <Text className={styles['contact-card__row-value']}>{contactName || <EmptyCardValue />}</Text>
          </div>
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Phone:</ItemTitle>
            <Text className={styles['contact-card__row-value']}>{phone || <EmptyCardValue />}</Text>
          </div>
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Email:</ItemTitle>
            <Text className={styles['contact-card__email']}>
              {email
                ? <Link href={`mailto:${email}`} type="default">{email}</Link>
                : <EmptyCardValue />
              }
            </Text>
          </div>
          <div className={styles['contact-card__row']}>
            <ItemTitle isCaps className={styles['contact-card__row-label']}>Address:</ItemTitle>
            <span className={styles['contact-card__row-value']}>
              {!streetAddress && !city && !state && !postalCode && <Text><EmptyCardValue /></Text>}
              {streetAddress && <Text>{streetAddress}</Text>}
              {city && <Text>{city}</Text>}
              {state && <Text>{state}</Text>}
              {postalCode && <Text>{postalCode}</Text>}
            </span>
          </div>
        </div>

        { currentUserRoleForTeam && (
          <div className={styles['contact-card__prompt']}
            data-qa-id={`team-contact-card__role-prompt-${currentUserRoleForTeam}`}>
            { currentUserRoleForTeam === UserRoles.Administrator && (
              <Note size="small">
                <Text level="small"> {/* TODO: This <Text> can be replaced by <Note size="small"> with uniform 4 */}
                  <FormattedMessage
                    id="profiles.recruit.team_contact_prompt.admin" values={{
                      teamRecruitingContactLink: (
                        <Link
                          href={`/recruiting/manage/team/${teamId}`}
                          target="_blank"
                          qaId="team-contact-card__role-prompt-link"
                        >
                          <FormattedMessage id="profiles.recruit.team_contact_prompt.admin_link" />
                        </Link>
                      ),
                    }}
                  />
                </Text>
              </Note>
            )}

            {(currentUserRoleForTeam === UserRoles.Participant || currentUserRoleForTeam === UserRoles.Coach) && (
              <Note size="small">
                <Text level="small"> {/* TODO: This <Text> can be replaced by <Note size="small"> with uniform 4 */}
                  <FormattedMessage id="profiles.recruit.team_contact_prompt.coaches_and_athletes" />
                </Text>
              </Note>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

TeamContactCard.propTypes = {
  city: PropTypes.string,
  contactName: PropTypes.string,
  currentUserRoleForTeam: PropTypes.number,
  email: PropTypes.string,
  orgName: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  sportName: PropTypes.string,
  state: PropTypes.string,
  streetAddress: PropTypes.string,
  teamId: PropTypes.string,
  teamName: PropTypes.string,
};

export const mapStateToProps = (state, props) => {
  const currentUserRoleForTeam = state.currentUser &&
    state.currentUser.currentUserRoles[props.teamId];

  const team = state.user.teams.find(t => t.teamId === props.teamId);

  return {
    currentUserRoleForTeam,
    sportName: team && team.sportName,
  };
};

export default connect(mapStateToProps)(TeamContactCard);
