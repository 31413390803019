import Cookies from 'cookies-js';

// Ripped from Modernizr
function hasLocalStorage() {
  const val = 'modernizr';
  try {
    localStorage.setItem(val, val);
    localStorage.removeItem(val);
    return true;
  } catch (e) {
    return false;
  }
}
const useLocalStorage = hasLocalStorage();

const StorageUtility = {
  set: (key, value) => {
    if (useLocalStorage) {
      window.localStorage.setItem(key, value);
    } else {
      Cookies.set(key, value);
    }
  },

  get: (key) => {
    if (useLocalStorage) {
      return window.localStorage.getItem(key);
    }
    return Cookies.get(key);
  },
};

export default StorageUtility;
