import $ from 'jquery';
import {
  DISMISS_FEEDBACK,
  FEEDBACK_SELECTED,
} from 'common/actions';

function feedback(state = {}, action) {
  const newState = $.extend(true, {}, state);
  switch (action.type) {
  case DISMISS_FEEDBACK:
    newState.feedbackEnabled = false;
    break;
  case FEEDBACK_SELECTED:
    newState.optionSelected = true;
    break;
  default:
    return state;
  }
  return newState;
}

export default feedback;
