let scrollPosition;

// Disables scrolling on the page
export function disablePageScroll() {
  const parallax = document.querySelector('.parallax');
  if (parallax) {
    return;
  }

  scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  Object.assign(window.document.body.style, {
    overflowY: 'scroll',
    position: 'fixed',
    top: (scrollPosition * -1) + 'px',
    width: '100%',
  });
}

// Enable scrolling on the page
export function enablePageScroll() {
  const parallax = document.querySelector('.parallax');
  if (parallax) {
    return;
  }

  Object.assign(window.document.body.style, {
    overflowY: '',
    position: '',
    top: '',
    width: '',
  });

  window.document.body.scrollTop = scrollPosition;
}
