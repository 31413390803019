import React from 'react';
import classNames from 'classnames';
import FeedUserTypes from 'common/feed-user-type';
import $ from 'jquery';
import CustomizeProp from 'common/prop-types/customize';
import { Easer } from 'functional-easing';

const bannerExpandMax = 250;
const mobileBannerHeight = 102;
const easer = new Easer().using('in-sine');

class ProfileBanner extends React.Component {
  static propTypes = {
    beginEditOfPicture: React.PropTypes.func,
    customize: CustomizeProp.isRequired,
    feedUserType: React.PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
    forcePublicView: React.PropTypes.bool.isRequired,
    isLoggedIn: React.PropTypes.bool,
    isMobile: React.PropTypes.bool,
    isPrivilegedUser: React.PropTypes.bool,
    onChangeBannerImage: React.PropTypes.func,
    onErrorPicture: React.PropTypes.func,
    profileBannerUri: React.PropTypes.string.isRequired,
    mobileProfileBannerUri: React.PropTypes.string.isRequired,
  }

  constructor() {
    super();
    this.state = {
      bannerExpand: 0,
    };
  }

  componentDidMount() {
    this.initializeUploader();

    if (this.props.isMobile) {
      const frontLayer = document.querySelector('.parallax-layer.front');
      if (frontLayer) {
        this.mobileFrontLayerOffset = parseFloat(frontLayer.offsetTop);
      }
      this.addTouchEvents();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.bannerExpand !== nextState.bannerExpand ||
           this.props.customize !== nextProps.customize;
  }

  componentDidUpdate() {
    if (this.props.customize.bannerImage && this.props.customize.bannerImage.savedValue) {
      $('.prof-header-image').css('background-image',
        `url(${this.props.customize.bannerImage.savedValue})`);
    }
  }

  initializeUploader() {
    const qq = window.__hudlProfiles && window.__hudlProfiles.qq;
    if (!qq) {
      return;
    }

    this.uploader = new qq.FineUploaderBasic({
      button: document.querySelector('.qq-banner-button-upload'),
      debug: true,
      camera: {
        ios: true,
      },
      multiple: false,
      validation: {
        acceptFiles: [
          'image/jpeg',
          'image/pjpeg',
          'image/png',
          'image/gif',
          'image/bmp',
          'image/x-windows-bmp',
        ],
        sizeLimit: 1024 * 1024 * 10,
        allowedExtensions: ['jpeg', 'jpg', 'png', 'gif', 'bmp'],
        image: {
          minHeight: 340,
          minWidth: 1200,
        },
      },
      request: {
        endpoint: '/Services/AvatarUploader.ashx',
      },
      text: {
        fileInputTitle: 'Change Photo',
      },
      messages: {
        emptyError: 'The picture you uploaded is empty. Please choose a different photo.',
        minHeightImageError: 'The picture you uploaded is too small. '
          + `Please choose a photo that\'s at least 340 x 1200.`,
        minWidthImageError: 'The picture you uploaded is too small. '
          + `Please choose a photo that\'s at least 340 x 1200.`,
        noFilesError: 'No files were given to upload.',
        sizeError: 'The picture you uploaded is too large. Please choose a photo that\'s less than 10MB in size.',
        typeError: 'The uploaded image file needs to be a jpeg, jpg, gif, or bmp. ' +
          'Try again with one of these file types.',
      },
      callbacks: {
        onComplete: (id, fileName, response) => {
          this.setState({
            inProgress: false,
            progress: 0,
          });
          if (response.success) {
            this.props.onChangeBannerImage(response.url, response.id);
          }
        },
        onError: (id, name, errorReason) => {
          this.props.onErrorPicture(errorReason, false);
          this.setState({
            error: true,
            errorReason,
            inProgress: false,
            progress: 0,
          });
        },
        onProgress: (id, name, uploadedBytes, totalBytes) => {
          const progressPercent = Math.floor(uploadedBytes / totalBytes * 100);
          this.setState({
            inProgress: true,
            progress: progressPercent,
          });
        },
        onSubmit: () => {
          this.setState({
            inProgress: true,
            progress: 0,
            error: false,
            errorReason: null,
          });
        },
      },
    });
  }

  addTouchEvents() {
    // Event handlers for exposing the header image on mobile
    let touchStartY;
    let isExpanding;
    const parallax = document.querySelector('.parallax');
    const frontLayer = document.querySelector('.parallax-layer.front');

    parallax.addEventListener('touchstart', e => {
      touchStartY = e.touches[0].clientY;
    }, { passive: true });

    parallax.addEventListener('touchmove', e => {
      // Check if we're dragging from the top of the page
      let dragAmount = e.touches[0].clientY - touchStartY;
      if (!isExpanding) {
        if (parallax.scrollTop > 0) {
          touchStartY = e.touches[0].clientY;
          dragAmount = 0;
        }

        isExpanding = dragAmount > 0 && parallax.scrollTop === 0;
      }

      if (!isExpanding) return;

      // Update the banner size
      e.preventDefault();
      if (dragAmount < 0) {
        dragAmount = 0;
        isExpanding = false;
      }

      const expandPercent = Math.min(dragAmount, bannerExpandMax) / bannerExpandMax;
      const expandAmount = easer(expandPercent) * bannerExpandMax;

      this.setState({
        bannerExpand: mobileBannerHeight + expandAmount,
      }, () => {
        frontLayer.style.top = (this.mobileFrontLayerOffset + expandAmount / 2) + 'px';
      });
    });

    window.addEventListener('touchend', () => {
      if (!isExpanding) return;
      isExpanding = false;
      this.collapseBanner.call(this);
    }, { passive: true });
  }

  collapseBanner() {
    // Snap the banner back to its original size
    const frontLayer = document.querySelector('.parallax-layer.front');
    const expandAmount = this.state.bannerExpand - mobileBannerHeight;
    const expandPercent = this.state.bannerExpand / bannerExpandMax;
    const frameRate = 1000 / 60;
    const snapTime = expandPercent * 200; // animate max 200ms
    const startTime = new Date().getTime();
    let timeElapsed = 0;

    const animateCollapse = () => {
      timeElapsed = new Date().getTime() - startTime;
      if (timeElapsed > snapTime) {
        this.setState({
          bannerExpand: mobileBannerHeight,
        }, () => {
          frontLayer.style.top = this.mobileFrontLayerOffset + 'px';
        });
        return;
      }

      const newAmount = (1 - easer(timeElapsed / snapTime)) * expandAmount;
      this.setState({
        bannerExpand: mobileBannerHeight + newAmount,
      }, () => {
        frontLayer.style.top = (this.mobileFrontLayerOffset + (newAmount / 2)) + 'px';
      });

      setTimeout(animateCollapse.bind(this), frameRate);
    };
    animateCollapse();
  }

  profileBannerStyle() {
    if (this.props.isMobile) {
      return {
        height: (this.state.bannerExpand || mobileBannerHeight) + 'px',
      };
    }

    return {};
  }

  profileImageStyle() {
    const profileCoverStyle = {};
    if (this.props.mobileProfileBannerUri || this.props.profileBannerUri) {
      const profileBannerUri = (this.props.isMobile && this.props.mobileProfileBannerUri)
        || this.props.profileBannerUri;
      profileCoverStyle.backgroundImage = `url(${profileBannerUri})`;
    }
    return profileCoverStyle;
  }

  beginEditBannerImage() {
    this.props.beginEditOfPicture('Banner');
  }

  cameraImage() {
    return (
      <object className="camera-icon-banner" id="camera-icon"
      data="https://static.hudl.com/profiles/images/icons/icon-camera.svg">
      </object>
    );
  }

  render() {
    const classes = classNames({
      'prof-banner': true,
      'qq-banner-button-upload': this.props.isPrivilegedUser,
    });

    if (this.props.isPrivilegedUser && !this.props.forcePublicView
    && this.props.feedUserType !== FeedUserTypes.Hudl) {
      return (
        <div className="parallax-layer back" style={this.profileBannerStyle()}>
          <div className={classes}
            onClick={this.beginEditBannerImage.bind(this)}>
            <div className="prof-header-image" style={this.profileImageStyle()} ref="image">
              <div className="prof-header-image-shade"></div>
              {this.cameraImage()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="parallax-layer back" style={this.profileBannerStyle()}>
        <div className={classes}>
          <div className="prof-header-image" style={this.profileImageStyle()} ref="image"></div>
        </div>
      </div>
    );
  }
}
export default ProfileBanner;
