import { connect } from 'react-redux';
import EssentialsCard from './EssentialsCard';

const mapStateToProps = (state) => {
  const { jersey, positions } = state.user;
  const {
    graduationYear,
    height,
    weight,
    heightWeightVerified,
    nikeFootballRating,
    nikeFootballRatingVerified,
    twitter,
  } = state.about.overview;

  return {
    graduationYear,
    height,
    heightWeightVerified,
    jersey,
    nikeFootballRating,
    nikeFootballRatingVerified,
    positions,
    twitter,
    weight,
  };
};

export default connect(mapStateToProps)(EssentialsCard);
