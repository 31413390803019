import { connect } from 'react-redux';
import BioCard from './BioCard';

const mapStateToProps = (state) => {
  return {
    bio: state.user.description,
  };
};

export default connect(mapStateToProps)(BioCard);
