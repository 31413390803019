function findMouseFlowUserCookie() {
  const mfUserCookieName = 'mf_user=';
  const cookies = document.cookie.split(';');
  const mfUserCookie = cookies.find(c => c.trim().startsWith(mfUserCookieName));
  if (mfUserCookie) {
    return mfUserCookie.trim().substring(mfUserCookieName.length, mfUserCookie.length);
  }
  return false;
}

export function setupMouseFlow(srcUrl) {
  window._mfq = window._mfq || [];
  const mf = document.createElement('script');
  mf.type = 'text/javascript';
  mf.async = true;
  mf.src = srcUrl || '//cdn.mouseflow.com/projects/d40dedb4-7d23-4681-ba4c-960621340b29.js';
  document.getElementsByTagName('head')[0].appendChild(mf);
}

/*
 * Sets up mouse flow mfRate percent of time.
 * mfRate - Number between 0 and 1 representing percentage of time to use MouseFlow.
 * useCookie - If true, we will check for the mf_user cookie value and always use MouseFlow if that is present.
 */
export function setupRateLimitedMouseFlow(srcUrl, mfRate = 0, useCookie = false) {
  const rateThresholdPassed = Math.random() < mfRate;
  const cookieFound = !!findMouseFlowUserCookie();
  if (rateThresholdPassed || (useCookie && cookieFound)) {
    setupMouseFlow(srcUrl);
  }
}
