/* eslint-disable max-len */
import React from 'react';

const ProfileRibbon = (props) => {
  const fill = props.fill;
  return (
    <div>
      <div className={props.className}>
        <svg viewBox="0 0 768 50">
          <g id="athlete-Web-Responsive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Mobile" stroke="none" strokeWidth="1" fillRule="evenodd" className="ribbon-mob" fill={fill}>
              <polygon id="primary-color-copy" points="0 4 187.5 19 375 4 375 34 187.5 49 0 34"></polygon>
            </g>
            <g id="Tablet" stroke="none" strokeWidth="1" fillRule="evenodd" className="ribbon-tab" fill={fill}>
              <polygon id="primary-color" points="1.25 0 385.5 20 769.75 0 769.75 30 385.5 50 1.25 30"></polygon>
            </g>
          </g>
        </svg>
      </div>
      <div className={props.className}>
        <div className="ribbon-desk" style={{backgroundColor: fill}}></div>
      </div>
    </div>
  );
};

ProfileRibbon.propTypes = {
  className: React.PropTypes.string,
  fill: React.PropTypes.string.isRequired,
};

export default ProfileRibbon;
