import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedUserTypes from 'common/feed-user-type';
import LocalizedMessage from 'common/localized-message';
import ProfileHeaderButtons from 'components/shared/profile-header-buttons';
import IconRecruiter from 'components/shared/icons/icon-recruiter';
import './profile-nav.scss';

class ProfileNav extends Component {
  static propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isRecruiterOnly: PropTypes.bool,
    })),
    currentTab: PropTypes.string.isRequired,
    feedUserId: PropTypes.string.isRequired,
    feedUserType: PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
    hideFollow: PropTypes.bool,
    hideNav: PropTypes.bool,
    isSticky: PropTypes.bool,
    onChangeTab: PropTypes.func.isRequired,
    onEditProfileClick: PropTypes.func,
    onFollowUnfollow: PropTypes.func,
    onMessagingButtonClick: PropTypes.func,
    onShareButtonClick: PropTypes.func,
    onlyHighlights: PropTypes.bool,
    ownerInitial: PropTypes.string,
    primaryColor: PropTypes.string,
    primaryName: PropTypes.string,
    profileLogoUri: PropTypes.string,
    mobileProfileLogoUri: PropTypes.string,
    isMobile: PropTypes.bool,
    secondaryColor: PropTypes.string,
    user: PropTypes.object,
  }

  _handleClick(name) {
    this.props.onChangeTab(name);
  }

  renderNavItems() {
    let currentTab = this.props.currentTab;

    if (!this.props.navItems.find(navItem => navItem.name.toLowerCase() === currentTab)) {
      currentTab = 'timeline';
    }

    const navigationItems = this.props.navItems.map((navItem, index) => {
      const key = (navItem.name.toLowerCase() === 'highlights' && this.props.onlyHighlights) ?
        'profiles.tab_videos' : navItem.key;

      const icon = navItem.isRecruiterOnly && (
        <div className="prof-subnav-item__icon">
          <IconRecruiter size="small" includeTooltip/>
        </div>
      );
      const qaId = `prof-subnav-item-${navItem.name.toLowerCase()}`;

      if (currentTab === navItem.name.toLowerCase()) {
        return (
          <span key={index}
            className="prof-subnav-item active"
            data-qa-id={qaId}
            data-index={index}
            role="tab"
            aria-selected="true">
            <LocalizedMessage id={key}/>
            {icon}
          </span>
        );
      }

      return (
        <span
          key={index}
          className="prof-subnav-item"
          data-qa-id={qaId}
          onClick={this._handleClick.bind(this, navItem.name.toLowerCase())}
          data-index={index}
          role="tab"
          aria-selected="false">
          <LocalizedMessage id={key}/>
          {icon}
        </span>
      );
    });

    return navigationItems;
  }

  renderProfileImage() {
    if (this.props.profileLogoUri || this.props.mobileProfileLogoUri) {
      const profileLogoUri = (this.props.isMobile && this.props.mobileProfileLogoUri)
        || this.props.profileLogoUri;
      return <img className="prof-subnav-avatar" src={profileLogoUri}/>;
    }

    const avatarStyle = {
      backgroundColor: this.props.primaryColor,
      borderColor: this.props.secondaryColor,
      borderStyle: 'solid',
      borderWidth: '2px',
    };
    return (
      <div className="prof-subnav-avatar" style={avatarStyle}>
        {this.props.ownerInitial}
      </div>
    );
  }

  render() {
    const profileImage = this.renderProfileImage();
    const navigationItems = this.renderNavItems();
    return (
      <nav className={'prof-subnav' + (this.props.isSticky ? ' sticky' : '')} role="tablist">
        <div className="prof-subnav-inner">
          <div className="prof-subnav-left">
            {profileImage}
            <span className="prof-subnav-name">
              {this.props.primaryName}
            </span>
          </div>
          <div className="prof-subnav-middle">
            {navigationItems}
          </div>
          <div className="prof-subnav-right">
            <ProfileHeaderButtons
              isNavbar
              {...this.props}
            />
          </div>
        </div>
      </nav>
    );
  }
}

export default ProfileNav;
