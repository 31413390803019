import React from 'react';

import './slider.scss';

class Slider extends React.Component {
  static propTypes = {
    min: React.PropTypes.number.isRequired,
    max: React.PropTypes.number.isRequired,
    onChange: React.PropTypes.func.isRequired,
    imageType: React.PropTypes.string,
  }

  constructor(props) {
    super(props);
  }

  onDragOrBarClick(e) {
    let event = e;
    if (event && event.originalEvent && event.originalEvent.touches) {
      event = event.originalEvent.touches[0];
    }
    const boundingRect = this.bar.getBoundingClientRect();
    let x = event.clientX;
    if (x < boundingRect.left - 8) {
      x = boundingRect.left - 8;
    } else if (x > boundingRect.left + boundingRect.width - 8) {
      x = boundingRect.left + boundingRect.width - 8;
    }

    const percentLeft = Math.max((x + 8 - boundingRect.left) / boundingRect.width, 0);
    const displayPercentLeft = (x - boundingRect.left) / boundingRect.width;
    if (/mobile|tablet|mobi/i.test(navigator.userAgent) &&
    (this.props.imageType === 'USER_BANNER'
    || this.props.imageType === 'TEAM_PHOTO')) {
      this.props.onChange(percentLeft * (this.props.min - this.props.max) + this.props.max);
    } else {
      this.props.onChange(percentLeft * (this.props.max - this.props.min) + this.props.min);
    }
    this.marker.style.left = (displayPercentLeft * 100) + '%';

    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    return false;
  }

  onDragStart(e) {
    let event = e;
    if (event && event.originalEvent && event.originalEvent.touches) {
      event = event.originalEvent.touches[0];
    }

    this.boundDrag = this.onDragOrBarClick.bind(this);
    $(document).on('touchmove mousemove', this.boundDrag);
    $(document).one('touchend mouseup', this.onDragEnd.bind(this));
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  onDragEnd(e) {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    if (!this.boundDrag) return false;

    $(document).off('touchmove mousemove', this.boundDrag);
    delete this.boundDrag;
    this.boundDrag = undefined;

    let event = e;
    if (event && event.originalEvent && event.originalEvent.changedTouches) {
      event = event.originalEvent.changedTouches[0];
    }

    this.onDragOrBarClick(event);
    return false;
  }

  onPlusMinus(isPlus) {
    const boundingRect = this.bar.getBoundingClientRect();
    const currentPercent = (parseFloat(this.marker.style.left) || 0) / 100;
    const lowerLimit = -8 / boundingRect.width;
    const upperLimit = (boundingRect.width - 8) / boundingRect.width;
    let displayPercent;
    if (currentPercent < lowerLimit && !isPlus) {
      displayPercent = lowerLimit;
    } else if (currentPercent > upperLimit && isPlus) {
      displayPercent = upperLimit;
    } else {
      if (isPlus) {
        displayPercent = Math.min(upperLimit, currentPercent + 0.05);
      } else {
        displayPercent = Math.max(lowerLimit, currentPercent - 0.05);
      }
    }

    const percent = (displayPercent * boundingRect.width + 8) / boundingRect.width;

    this.props.onChange(percent * (this.props.max - this.props.min) + this.props.min);
    this.marker.style.left = (displayPercent * 100) + '%';
  }

  render() {
    return (
      <div className="slider" unselectable="on" draggable="false">
        <div className="minus" unselectable="on" onClick={this.onPlusMinus.bind(this, false)}>-</div>
        <div className="drag-area" onClick={this.onDragOrBarClick.bind(this)}>
          <div className="bar" ref={ref => this.bar = ref}></div>
          <div className="marker"
            ref={ref => this.marker = ref}
            onMouseDown={this.onDragStart.bind(this)}
            onTouchStart={this.onDragStart.bind(this)}
            >
          </div>
        </div>
        <div className="plus" unselectable="on" onClick={this.onPlusMinus.bind(this, true)}>+</div>
      </div>
    );
  }
}

export default Slider;
