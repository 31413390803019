import React from 'react';
import { Text } from 'components/shared/uniform';
import ExpandableList from './ExpandableList';

class ExpandableTextList extends React.Component {
  static propTypes = {
    items: React.PropTypes.arrayOf(React.PropTypes.string),
    header: React.PropTypes.string,
    isExpandable: React.PropTypes.bool,
    showCount: React.PropTypes.bool,
  }

  renderItems(items) {
    return (
      <div>
        {items.map((item) => {
          return (
            <Text level="small">• {item}</Text>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <ExpandableList
        onRenderItems={this.renderItems}
        {...this.props}
      />
    );
  }
}

export default ExpandableTextList;
