import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './itemsLoadingExperience.scss';

class ItemsLoadingExperience extends Component {
  static propTypes = {
    itemsLoading: PropTypes.number,
  }

  static defaultProps = {
    itemsLoading: 3,
  }

  renderItem = (key) => (
    <div key={`loading-item-${key}`} className={`games-card pre-data pre-data--${key}`}>
      <div className="pre-data-video">
        <div className="thumbnail empty"></div>
        <div className="thumbnail-margin-mask"></div>

        <div className="title-split-container">
          <div className="title-split-mask"></div>
        </div>
        <div className="title-margin-mask"></div>

        <div className="metadata-split-container">
          <div className="metadata-split-mask"></div>
        </div>
        <div className="metadata-margin-mask"></div>

        <div className="tags-split-container">
          <div className="tags-split-mask"></div>
        </div>
      </div>
    </div>
  );
  renderItems = (itemsLoading) => {
    const videos = [];
    for (let i = 1; i <= itemsLoading; i++) {
      videos.push(this.renderItem(i));
    }

    return videos;
  };

  render() {
    return (
      <div className="games-group items-loading">
        {this.renderItems(this.props.itemsLoading)}
      </div>
    );
  }
}

export default ItemsLoadingExperience;
