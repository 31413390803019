import React from 'react';
import g11n from 'hudl-g11n';

import { Tooltip } from 'components/shared/uniform';

const IconRecruiter = (props) => {
  const icon = (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      fillRule="evenodd"
      width="16px"
      height="16px"
      className={props.className + ' uni-icon uni-icon--' + props.size}
      data-qa-id="recruit-only-icon"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        fill="#353F4A"
      />
      {/* eslint-disable max-len */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8H17.5408C17.5408 8 18.5994 8.21514 19.6813 9.01325C20.6911 9.75824 21.7263 11.0177 21.8021 12.9073C21.9336 16.1828 19.7402 17.4768 19.7402 17.4768L23 23H18.4048L15.6163 18.4305H14.065V23H10V8ZM16.7749 14.8146H14.065V13.2053V11.596H16.7749C16.7749 11.596 17.6782 11.9735 17.6782 13.2053C17.6782 14.4371 16.7749 14.8146 16.7749 14.8146Z"
        fill="white"
      />
      {/* eslint-enable max-len */}
    </svg>
  );

  if (!props.includeTooltip) {
    return icon;
  }

  return (
    <Tooltip position="bottom" text={g11n.format('profiles.recruit.tooltip_visible_to_recruiters')} parent="div" >
      {icon}
    </Tooltip>
  );
};

IconRecruiter.propTypes = {
  size: React.PropTypes.oneOf(['small', 'medium', 'large']),
  className: React.PropTypes.string,
  includeTooltip: React.PropTypes.bool,
};

export default IconRecruiter;
