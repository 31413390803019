import {
  DISMISS_NOTIFICATION,
  FAIL_FEATURE_HIGHLIGHT,
  FAIL_UNFEATURE_HIGHLIGHT,
  SUCCESS_FEATURE_HIGHLIGHT,
  SUCCESS_UNFEATURE_HIGHLIGHT,
} from 'common/actions';

function feedback(state = {}, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
  case DISMISS_NOTIFICATION:
    newState.type = null;
    newState.message = null;
    newState.active = false;
    newState.showUndo = false;
    break;
  case FAIL_FEATURE_HIGHLIGHT:
    newState.active = true;
    newState.type = 'error';
    newState.message = 'Failed to pin video to your timeline.';
    newState.showUndo = false;
    break;
  case FAIL_UNFEATURE_HIGHLIGHT:
    newState.active = true;
    newState.type = 'error';
    newState.message = 'Failed to unpin video from your timeline.';
    newState.showUndo = false;
    break;
  case SUCCESS_FEATURE_HIGHLIGHT:
    newState.active = true;
    newState.type = 'success';
    newState.message = 'Video pinned to your timeline.';
    newState.showUndo = action.payload.showUndo;
    newState.undoAction = action.type;
    break;
  case SUCCESS_UNFEATURE_HIGHLIGHT:
    newState.active = true;
    newState.type = 'success';
    newState.message = 'Video unpinned from your timeline.';
    newState.showUndo = action.payload.showUndo;
    newState.undoAction = action.type;
    break;
  default:
    return state;
  }
  return newState;
}

export default feedback;
