import React from 'react';
import numeral from 'numeral';
import g11n from 'hudl-g11n';

import FeedUserTypes from 'common/feed-user-type';
import ProfileHeaderButtons from 'components/shared/profile-header-buttons';
import { Link, Headline, Text } from 'components/shared/uniform';

const { FormattedMessage } = g11n.ReactIntl;

class ProfileHeaderTitle extends React.Component {
  static propTypes = {
    primaryName: React.PropTypes.string.isRequired,
    tagline: React.PropTypes.string,
    followCount: React.PropTypes.number.isRequired,
    followingCount: React.PropTypes.number,
    displayFollowingCount: React.PropTypes.bool,
    feedUserType: React.PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
    feedUserId: React.PropTypes.string.isRequired,
    forcePublicView: React.PropTypes.bool.isRequired,
    hideFollow: React.PropTypes.bool,
    hideNav: React.PropTypes.bool,
    highlightViewCount: React.PropTypes.number.isRequired,
    highlightRecruiterViewCount: React.PropTypes.number.isRequired,
    isAthleteOptedInToRecruit: React.PropTypes.bool,
    isPrivilegedUser: React.PropTypes.bool,
    location: React.PropTypes.string,
    locationUrl: React.PropTypes.string,
    onChangeTab: React.PropTypes.func.isRequired,
    onEditProfileClick: React.PropTypes.func,
    onFollowersCountClick: React.PropTypes.func.isRequired,
    onFollowingCountClick: React.PropTypes.func,
    onFollowUnfollow: React.PropTypes.func.isRequired,
    onMessagingButtonClick: React.PropTypes.func,
    onShareButtonClick: React.PropTypes.func.isRequired,
    primaryTeam: React.PropTypes.shape({
      name: React.PropTypes.string,
      profileUrl: React.PropTypes.string,
      sportId: React.PropTypes.number,
    }),
    user: React.PropTypes.object,
    athleteCommitment: React.PropTypes.object,
    showRecruiterActivityEnabled: React.PropTypes.bool,
  }

  constructor(props) {
        super(props);
        this.showRecruiterViews = this.props.feedUserId == this.props.user.userId &&
            this.props.isAthleteOptedInToRecruit &&
            this.props.showRecruiterActivityEnabled && this.props.feedUserType === FeedUserTypes.User;
        this.profileStatsStyle = isMobile ? 'mobile' : 'desktop';
  }

  getFollowCount() {
    if (this.props.hideFollow) {
      return null;
    }

    const followersCount = numeral(this.props.followCount).format();

    if (this.props.followCount) {
      return (
        <span className={`prof-ident-meta-item ${this.profileStatsStyle}`} data-qa-id="profile-follower-count">
          <Text level={isMobile ? "micro" : "default"}>
            <a className="uni-link--implied" href="#" onClick={this.props.onFollowersCountClick}>
              <strong>{followersCount}</strong>
              <span className="item-label"> follower{this.props.followCount === 1 ? '' : 's'}</span>
            </a>
          </Text>
        </span>
      );
    }

    return (
      <span className={`prof-ident-meta-item ${this.profileStatsStyle}`} data-qa-id="profile-follower-count">
        <Text level={isMobile ? "micro" : "default"}>
          <strong>{followersCount}</strong>
          <span className="item-label"> follower{this.props.followCount === 1 ? '' : 's'}</span>
        </Text>
      </span>
    );
  }

  getFollowersCount() {
    if (!this.props.displayFollowingCount) {
      return null;
    }

    const followingCount = numeral(this.props.followingCount).format();

    if (this.props.followingCount) {
      return (
        <span className={`prof-ident-meta-item ${this.profileStatsStyle}`} data-qa-id="profile-following-count">
          <Text level={isMobile ? "micro" : "default"} >
            <a className="uni-link--implied" href="#" onClick={this.props.onFollowingCountClick}>
              <strong>{followingCount}</strong>
              <span className="item-label"> following</span>
            </a>
          </Text>
        </span>
      );
    }

    return (
      <span className={`prof-ident-meta-item ${this.profileStatsStyle}`} data-qa-id="profile-following-count">
        <Text level={isMobile ? "micro" : "default"}>
          <strong>{followingCount}</strong>
          <span className="item-label"> following</span>
        </Text>
      </span>
    );
  }

  getLocation() {
    return this.props.locationUrl
      ? <Link type="implied" href={this.props.locationUrl}>{this.props.location}</Link>
      : this.props.location;
  }

  onClickViews(e) {
    e.preventDefault();
    this.props.onChangeTab('videos');
    return false;
  }

  renderBioItems() {
    if (!this.props.primaryTeam && !this.props.location) {
      return null;
    }

    return (
      <div className="prof-ident-bio-items">
        {this.props.primaryTeam && this.props.primaryTeam.name ? (
          <div className="prof-ident-bio-item">
            <Text level="large" hideOverflow>
              <Link type="implied" href={this.props.primaryTeam.profileUrl}>
                {this.props.primaryTeam.name}
              </Link>
            </Text>
          </div>
        ) : null}
        {this.props.athleteCommitment?.committed ? (
            <div className="prof-ident-bio-item">
                <Text level="large" hideOverflow>
                    <FormattedMessage id="profiles.recruit.commitment.committed"/>
                </Text>
            </div>
        ) : null}
        {this.props.location ? (
          <div className="prof-ident-bio-item">
            <Text level="large" hideOverflow>{this.getLocation()}</Text>
          </div>
        ) : null}
      </div>
    );
  }

  renderButtons(isMobile) {
    return (
      <ProfileHeaderButtons
        isMobile={isMobile}
        {...this.props}
      />
    );
  }

  renderRecruiterViewCount() {
    if (this.showRecruiterViews)
    {
        return (
            <span className={"prof-ident-meta-item "+this.profileStatsStyle} data-qa-id="profile-recruiter-highlight-views">
                <Text level={isMobile ? "micro" : "default"} >
                    <strong>{numeral(this.props.highlightRecruiterViewCount).format()}</strong>
                    <span className="item-label"> recruiter highlight view{this.props.highlightRecruiterViewCount === 1 ? '' : 's'}</span>
                </Text>
            </span>
        );
    }
   }

   renderPublicViewCount() {
     const highlightViewCount = this.showRecruiterViews ? this.props.highlightViewCount : (this.props.highlightViewCount + (this.props.highlightRecruiterViewCount || 0));
     return (
        <span className={"prof-ident-meta-item "+this.profileStatsStyle} data-qa-id="public-highlight-view-count">
            <Text level={isMobile ? "micro" : "default"}>
                <a className="uni-link--implied" href="#" onClick={(e) => { this.onClickViews(e); }}>
                    <strong>{numeral(highlightViewCount).format()}</strong>
                    <span className="item-label"> public highlight view{this.props.highlightViewCount === 1 ? '' : 's'}</span>
                </a>
            </Text>
        </span>
     )
    }

  render() {
    return (
      <div data-qa-id="prof-header-title">
        <div className="prof-ident-title">
          <div className="prof-ident-name">
            <Headline level="1">{this.props.primaryName}</Headline>
          </div>
          {this.renderButtons()}
        </div>
        <p className="prof-ident-bio">
          {this.props.tagline}
        </p>

        {this.renderBioItems()}

        {this.renderButtons(true)}
        {!this.props.hideNav ? (
          <nav className={`prof-ident-meta ${this.profileStatsStyle}`}>
            {this.renderRecruiterViewCount()}
            {this.renderPublicViewCount()}
            {this.getFollowCount()}
            {this.getFollowersCount()}
          </nav>
        ) : null}
      </div>
    );
  }
}

export default ProfileHeaderTitle;
