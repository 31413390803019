import localforage from 'localforage/dist/localforage';

localforage.config({
  name: 'hudl',
  storeName: 'profiles',
  version: 1.0,
});

localforage.hudlGetMany = function getMany() {
  const args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
  const promises = [];
  args.forEach(argument => {
    promises.push(localforage.getItem(argument));
  });

  return Promise.all(promises);
};

export default localforage;
