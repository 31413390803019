/* eslint-disable max-len */
// Should be kept in sync with https://github.com/hudl/hudl-highlights/blob/master/Hudl.Highlights.Client/Dto/CommunityContentLog/ContainerType.cs
export default {
  Unknown: 0,
  Home: 1,
  Profile: 2,
  VideoPage: 3,
  EmbeddablePlayer: 4,
  Explore: 5,
  OwnedSocial: 6,
  Partner: 7,
  PerformanceCenter: 8,
  VideoManagement: 9,
};
