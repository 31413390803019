import React from 'react';
import PropTypes from 'prop-types';
import { Headline, Link, Text } from 'components/shared/uniform';
import ProfileType from 'common/container-subtype';

import styles from './styles.scss';

const cta = (
  <span>
    Contact <Link href="mailto:sales@recruit.co" type="article">sales@recruit.co</Link> to upgrade
  </span>
);

const RecruitUpsell = ({ size, profileType }) => {
  const subject = profileType === ProfileType.Team ? 'team' : 'athlete';
  const title = <span>{`Your subscription doesn’t cover access to recruiter content for this ${subject}.`}</span>;

  if (size === 'large') {
    return (
      <div className={styles['no-recruiter-access']} data-qa-id="upsell-recruiter-access">
        <Headline level="2">
          {title}
        </Headline>
        <Text level="large" className={styles['no-recruiter-access__contact']}>
          {cta}
        </Text>
      </div>
    );
  }

  return (
    <div className={styles['no-recruiter-access']} data-qa-id="upsell-recruiter-access">
      <Text level="large" color="contrast">
        {title}
      </Text>
      <Text level="small" className={styles['no-recruiter-access__contact']}>
        {cta}
      </Text>
    </div>
  );
};

RecruitUpsell.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  profileType: PropTypes.oneOf([ProfileType.User, ProfileType.Team]),
};

export default RecruitUpsell;
