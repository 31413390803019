import React from 'react';
import { Headline, Text } from 'components/shared/uniform';
import TeamHistoryCard from 'components/user-profile/cards/TeamHistoryCard';

import g11n from 'hudl-g11n';

import styles from './RestrictedAccess.scss';

const RestrictedAccess = () => {
  return (
    <div className={styles['games-tab__restricted-access']} data-qa-id="no-recruiter-access">
      <Headline level="2">
        {g11n.format('profiles.tab_games.restricted_access.title')}
      </Headline>
      <Text level="large" color="subtle" className="uni-pad--quarter--top">
        {g11n.format('profiles.tab_games.restricted_access.subtitle')}
      </Text>
      <div className={styles['games-tab__restricted-access-teams-list']}>
        <TeamHistoryCard
          tabLink="games"
          usePersonalizedTitle
        />
      </div>
    </div>
  );
};

export default RestrictedAccess;
