import { CommunityContentType } from 'hudl-feed';
import FeedContentType from 'common/feed-content-type';
import FeedUserTypes from 'common/feed-user-type';

const avatarPlaceholder = 'https://sc.hudl.com/images/thumb-user.svg';

export function getPinnedFeedItem(profileUser, currentUser, reel, logData) {
  if (!profileUser || !reel) {
    return null;
  }

  const showReactions = !!(currentUser && currentUser.userId);
  if (!reel || !reel.videoFiles
    || !reel.videoFiles.length) {
    return undefined;
  }
  const authorId = { type: FeedUserTypes.User, relatedId: profileUser.userId};

  const communityContentId = {
    relatedId: reel.reelId,
    secondaryRelatedId: profileUser.userId,
    type: CommunityContentType.UserHighlight,
  };
  const feedContent = {
    feedUsers: [{
      feedUserId: authorId,
      nameComponents: {
        f: profileUser.firstName,
        l: profileUser.lastName,
      },
      profileImages: [{
        size: 0,
        url: profileUser.profileLogoUri || avatarPlaceholder,
      }],
    }],
    content: {
      feedContentId: 'pinned-feed-item',
      authorId,
      ownerId: authorId,
      communityContentId,
      dateCreated: new Date(reel.unixTime * 1000).toISOString(),
      item: {
        action: null,
        highlights: [{
          reelId: reel.reelId || reel.legacyReelId,
          ownerId: { type: FeedUserTypes.User, relatedId: profileUser.userId},
          title: reel.title,
          thumbnail: {
            url: reel.thumbnailUrl,
          },
          videoFiles: reel.videoFiles,
          durationMs: reel.durationMs,
        }],
      },
      showReactions,
      type: FeedContentType.SharedHighlight,
    },
    isPinned: !!profileUser.featuredReelId,
    isFeatured: false,
    isTimeline: true,
    hideFollowButton: true,
    logData,
    aggregateUserTagCount: 0,
    totalTaggedUsers: 0,
  };

  return feedContent;
}

export function getAuthor(feedItem) {
  const { content, feedUsers } = feedItem;
  const authorId = (content.authorId && content.authorId.relatedId) ||
    (content.ownerId && content.ownerId.relatedId);
  if (!authorId) return null;

  const author = feedUsers.find(u => u.feedUserId.relatedId === authorId);

  return author;
}
