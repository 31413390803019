import React from 'react';

const WebNav = ({ children, secondaryNavData }) => {
  const HudlUiWebNavPlugin = window.__hudlui_WebNav;

  if (HudlUiWebNavPlugin) {
    const HudlUiWebNav = HudlUiWebNavPlugin.WebNav;
    const hudlUiWebNavData = HudlUiWebNavPlugin.getData();
    const hudlUiWebNavi18nData = HudlUiWebNavPlugin.geti18n();

    const webnavNode = HudlUiWebNavPlugin.getSsrDomNode();
    webnavNode && webnavNode.parentNode && webnavNode.parentNode.removeChild(webnavNode);

    return (
      <div className="prof-flex-container">
        <HudlUiWebNav {...hudlUiWebNavData}
          i18n={hudlUiWebNavi18nData}
          secondaryNavData={secondaryNavData}
        />
        {children}
      </div>
    );
  }

  return (
    <div className="prof-flex-container">
      {children}
    </div>
  );
};

WebNav.propTypes = {
  children: React.PropTypes.element,
  secondaryNavData: React.PropTypes.object,
};

export default WebNav;
