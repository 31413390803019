import React from 'react';

export default () => {
  return (
    <svg
      width="35"
      height="12"
      viewBox="0 0 35 12"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-780.000000, -758.000000)">
          <g transform="translate(50.000000, 50.000000)">
            <g transform="translate(-117.000000, 0.000000)">
              <g transform="translate(392.000000, 556.000000)">
                <g transform="translate(227.000000, 11.000000)">
                  <g transform="translate(228.000000, 141.000000)">
                    {/* eslint-disable max-len */}
                    <path d="M33.3 7.9L31 7.9C30.9 7.9 30.8 7.8 30.8 7.6L31.5 5.3C31.5 5.1 31.4 5 31.3 5L30.7 5C30.5 5 30.4 5.1 30.3 5.3L29.7 7.6C29.6 7.8 29.5 7.9 29.3 7.9L27 7.9C26.9 7.9 26.7 8 26.7 8.2L26.5 8.8C26.5 9 26.6 9.1 26.7 9.1L29 9.1C29.1 9.1 29.2 9.2 29.2 9.4L28.5 11.7C28.5 11.9 28.6 12 28.7 12L29.3 12C29.5 12 29.6 11.9 29.7 11.7L30.3 9.4C30.4 9.2 30.5 9.1 30.7 9.1L33 9.1C33.1 9.1 33.3 9 33.3 8.8L33.5 8.2C33.5 8 33.4 7.9 33.3 7.9M4.9 0C2.9 2.1 0 5.4 0 8.4 0 10.2 1 12 4.1 12 5.9 12 8.2 11.2 9.5 10.6 10.7 10.1 34.5 0 34.5 0L9.6 6.6C8.6 6.9 7.6 7 6.9 7 3.2 7 2.7 3.4 4.9 0Z"/>
                    {/* eslint-enable max-len */}
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
