import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as AboutSectionTypes from 'common/about-section-types';

import TabWrapper from 'components/shared/profile-tabs/tab-wrapper';
import AthleticismCard from 'components/user-profile/cards/AthleticismCard';
import AcademicCard from 'components/user-profile/cards/AcademicCard';
import BioCard from 'components/user-profile/cards/BioCard';
import EssentialsCard from 'components/user-profile/cards/EssentialsCard';
import PinnedHighlightCard from 'components/user-profile/cards/PinnedHighlightCard';
import TeamHistoryCard from 'components/user-profile/cards/TeamHistoryCard';

import styles from './styles.scss';


class UserAboutTab extends React.Component {
  static propTypes = {
    activeTab: PropTypes.oneOf(Object.keys(AboutSectionTypes).map(key => AboutSectionTypes[key])),
  }

  state = {};

  componentWillReceiveProps(nextProps) {
    // Get use the height of the active tab to set a min-height on the next tab
    // This prevents the scrollbar from jumping up when going from a long tab to a short tab.
    if (nextProps.activeTab !== this.props.activeTab && this.refs.content) {
      this.setState({
        minHeight: this.refs.content.clientHeight,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab
      && this.props.activeTab !== AboutSectionTypes.OVERVIEW) {
      if (this.refs.headerElement && this.refs.headerElement.scrollIntoView) {
        this.refs.headerElement.scrollIntoView();
      }
    }
  }

  renderCards(...cards) {
    return cards.map((card, index) => (
      <div key={index} className={styles['about-tab__row']}>
        {card}
      </div>
    ));
  }

  render() {
    const contentStyles = {};
    if (this.state.minHeight) {
      contentStyles.minHeight = this.state.minHeight + 'px';
    }

    return (
      <TabWrapper>
        <main className="tab-panel" data-qa-id="about-tab">
          <div className={styles['about-tab__panel']} style={contentStyles}>
            <div className={styles['about-tab__sidebar']} >
              {this.renderCards(<PinnedHighlightCard/>, <EssentialsCard />)}
            </div>
            <div ref="content" className={styles['about-tab__content']}>
              {this.renderCards(<BioCard />, <AcademicCard />, <AthleticismCard />, <TeamHistoryCard />)}
            </div>
          </div>
        </main>
      </TabWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.about.activeTab,
  };
};

export default connect(mapStateToProps)(UserAboutTab);
