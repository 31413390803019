import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';

import Container from 'common/container-type';
import ContainerPlayer from 'common/container-player';
import ContainerSection from 'common/container-section';
import ContainerType from 'common/container-subtype';

import PinnedHighlightCard from './PinnedHighlightCard';

const mapStateToProps = (state) => {
  const logData = {
    container: Container.Profile,
    containerSection: ContainerSection.Timeline,
    containerType: ContainerType.User,
    player: ContainerPlayer.TimelineCard,
  };

  return {
    currentUser: state.currentUser,
    highlights: state.highlights,
    logData,
    profileUser: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickViewAll: () => dispatch(routerActions.push(window.location.pathname.replace('about', 'highlights'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(PinnedHighlightCard);
