/* eslint-disable max-len */
import React from 'react';

const FollowingIcon = (props) => {
  return (
    <svg className={props.className} viewBox="-2 -4 34 32">
      <g transform="translate(-199.000000, 0.000000)">
        <path d="M218.865266,12.6271213 C223.31398,13.6998495 224,16.8430826 224,20.553614 C224,25.1487953 206,25.1487953 206,20.553614 C206,16.8430826 206.68602,13.6998495 211.134734,12.6271213 C211.962165,14.0010871 213.384369,14.9077723 215,14.9077723 C216.615631,14.9077723 218.037835,14.0010871 218.865266,12.6271213 L218.865266,12.6271213 Z M220.235354,5.6053281 C220.235354,2.50959087 217.943121,0 215.115509,0 C212.287897,0 209.995664,2.50959087 209.995664,5.6053281 C209.995664,8.70106532 212.287897,12.9964158 215.115509,12.9964158 C217.943121,12.9964158 220.235354,8.70106532 220.235354,5.6053281 Z M201.828427,13.8890873 L202.535534,14.5961941 L208.192388,8.93933983 L206.778175,7.52512627 L202.535534,11.767767 L200.414214,9.64644661 L199,11.0606602 L201.828427,13.8890873 L201.828427,13.8890873 Z" id="following"></path>
      </g>
    </svg>
  );
};

FollowingIcon.propTypes = {
  className: React.PropTypes.string,
};

export default FollowingIcon;
