import { connect } from 'react-redux';
import AthleticismCard from './AthleticismCard';

const mapStateToProps = (state) => {
  return {
    athleticism: state.about.strengthAndSpeed,
  };
};

export default connect(mapStateToProps)(AthleticismCard);
