import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'underscore';

import { HighlightFeedItem } from 'hudl-feed';
import { Button, Icon } from 'components/shared/uniform';
import { getAuthor, getPinnedFeedItem } from 'utility/feed-item-utility';

import styles from './styles.scss';

const PinnedHighlightCard = ({ currentUser, highlights, logData, onClickViewAll, profileUser }) => {
  if (!highlights.reels || highlights.reels.length === 0 || highlights.hasHighlightsDisabled) {
    return null;
  }

  const featuredHighlight = profileUser.featuredReelId && highlights.reels.find(h =>
    h.reelId === profileUser.featuredReelId || h.legacyReelId === profileUser.featuredReelId);

  // Fallback to the most recent highlight reel if the user hasn't picked a "featured highlight"
  let pinnedHighlight = featuredHighlight;
  if (!pinnedHighlight) {
    const newestHighlights = sortBy(highlights.reels, highlight => {
      return highlight.unixTime * -1;
    });
    pinnedHighlight = newestHighlights[0];
  }

  const pinnedFeedItem = getPinnedFeedItem(profileUser, currentUser, pinnedHighlight, logData);

  if (!pinnedFeedItem) {
    return null;
  }

  const viewAllButton = (
    <Button
      icon={<Icon type="highlights" />}
      onClick={onClickViewAll}
      style="minimal"
      type="secondary"
    >
      View All
    </Button>
  );

  const highlightFeedItemProps = {
    ...pinnedFeedItem,
    author: getAuthor(pinnedFeedItem),
    shareButton: viewAllButton, // hijack share button prop
  };

  return (
    <div data-qa-id="pinned-highlight-card" className={styles['pinned-highlight-card']}>
      <HighlightFeedItem {...highlightFeedItemProps} />
    </div>
  );
};

PinnedHighlightCard.propTypes = {
  currentUser: PropTypes.object,
  highlights: PropTypes.object,
  logData: PropTypes.object,
  onClickViewAll: PropTypes.func.isRequired,
  profileUser: PropTypes.object,
};

export default PinnedHighlightCard;
