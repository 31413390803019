export const NONE = 'NONE';
export const DELETE_HIGHLIGHT_DIALOG = 'DELETE_HIGHLIGHT_DIALOG';
export const FOLLOWERS_DIALOG = 'FOLLOWERS_DIALOG';
export const FOLLOWING_DIALOG = 'FOLLOWING_DIALOG';
export const EDIT_PROFILE_IMAGE_DIALOG = 'EDIT_PROFILE_IMAGE_DIALOG';
export const EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG = 'EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG';
export const EDIT_BANNER_IMAGE_DIALOG = 'EDIT_BANNER_IMAGE_DIALOG';
export const EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG = 'EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG';
export const MESSAGING_DIALOG = 'MESSAGING_DIALOG';
export const LEAVE_THIS_PAGE_DIALOG = 'LEAVE_THIS_PAGE_DIALOG';
export const SHARE_PROFILE_DIALOG = 'SHARE_PROFILE_DIALOG';
export const SHARE_VIDEO_DIALOG = 'SHARE_VIDEO_DIALOG';
