const UserRoles = {
  None: -1,
  Fan: 0,
  Participant: 1,
  Coach: 2,
  Administrator: 3,
  Recruiter: 4,
  Insider: 5,
  Technique: 6,
};

export default UserRoles;
