import * as ClassTypes from 'common/class-types';
import * as EditTypes from 'common/edit-types';
import * as OrganizationTypes from 'common/organization-types';

// Map our JS Class types (which are human-readable strings) to
// their .NET TeamLevel enum values as they are in Hudl.SharedTypes
function convertTeamLevelToDotNetEnum(teamLevel) {
  switch (teamLevel) {
  case ClassTypes.FRESHMAN:
    return 3;
  case ClassTypes.SOPHOMORE:
    return 2;
  case ClassTypes.JUNIOR_VARSITY:
    return 1;
  case ClassTypes.VARSITY:
    return 0;
  default: // TeamLevel.Other
    return 4;
  }
}

export function convertDotNetEnumToClassType(dotNetTeamLevel) {
  switch (dotNetTeamLevel) {
  case 0:
    return ClassTypes.VARSITY;
  case 1:
    return ClassTypes.JUNIOR_VARSITY;
  case 2:
    return ClassTypes.SOPHOMORE;
  case 3:
    return ClassTypes.FRESHMAN;
  case 4:
  default:
    return ClassTypes.VARSITY;
  }
}

export function convertDotNetEnumToOrganizationType(dotNetOrgType) {
  switch (dotNetOrgType) {
  case 0: // OrganizationType.HighSchool
    return OrganizationTypes.HIGH;
  case 1: // OrganizationType.College
  case 5: // OrganizationType.Juco
    return OrganizationTypes.UNIVERSITY;
  case 6: // OrganizationType.MiddleSchool
    return OrganizationTypes.MIDDLE;
  case 3: // OrganizationType.Youth
    return OrganizationTypes.YOUTH;
  case 7: // OrganizationType.Club to
    return OrganizationTypes.CLUB;
  case 4: // OrganizationType.Pro to
    return OrganizationTypes.PRO;
  default: // ???
    return OrganizationTypes.HIGH;
  }
}

function convertEditTypeToDotNetEnum(editType) {
  switch (editType) {
  case EditTypes.TEAM_NAME:
    return 0; // UpdatedTeamProfileValue.TeamName
  case EditTypes.LEAGUE_NAME:
    return 1; // UpdatedTeamProfileValue.LeagueName
  case EditTypes.TEAM_CLASS:
    return 2; // UpdatedTeamProfileValue.TeamLevel
  case EditTypes.TEAM_COLOR:
    return 3; // UpdatedTeamProfileValue.PrimaryTeamColor
  case EditTypes.TAGLINE:
    return 4; // UpdatedTeamProfileValue.TeamTagline
  case EditTypes.LOGO_IMAGE:
    return 5; // UpdatedTeamProfileValue.ProfileImage
  case EditTypes.BANNER_IMAGE:
    return 6; // UpdatedTeamProfileValue.BannerImage
  default:
    throw new Error('Attempted to convert unknown EditType to UpdatedTeamProfileValue enum');
  }
}

function convertDotNetEnumToEditType(dotNetUpdatedValue) {
  switch (dotNetUpdatedValue) {
  case 0: // UpdatedTeamProfileValue.TeamName
    return EditTypes.TEAM_NAME;
  case 1: // UpdatedTeamProfileValue.LeagueName
    return EditTypes.LEAGUE_NAME;
  case 2: // UpdatedTeamProfileValue.TeamLevel
    return EditTypes.TEAM_CLASS;
  case 3: // UpdatedTeamProfileValue.PrimaryTeamColor
    return EditTypes.TEAM_COLOR;
  case 4: // UpdatedTeamProfileValue.TeamTagline
    return EditTypes.TAGLINE;
  case 5: // UpdatedTeamProfileValue.ProfileImage
    return EditTypes.LOGO_IMAGE;
  case 6: // UpdatedTeamProfileValue.BannerImage
    return EditTypes.BANNER_IMAGE;
  default:
    throw new Error('Attempted to convert unknown UpdatedTeamProfileValue enum to EditType');
  }
}

function convertImageArgsToDotNet(args) {
  const newArgs = {};
  for (const key in args) {
    if (args.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);
      newArgs[newKey] = args[key];
    }
  }

  // CroppedDataUrl not needed on endpoint and this string is huge as it encodes the entire image
  if (newArgs.CroppedDataUrl) {
    delete newArgs.CroppedDataUrl;
  }

  newArgs.DestinationWidth = newArgs.ImageType === 'TEAM_LOGO' ? 500 : newArgs.Width;
  newArgs.DestinationHeight = newArgs.ImageType === 'TEAM_LOGO' ? 500 : newArgs.Height;
  return newArgs;
}

const defaultRequest = {
  TeamId: null,
  UpdatedValues: [],
  TeamName: null,
  LeagueName: null,
  TeamLevel: convertTeamLevelToDotNetEnum(ClassTypes.FRESHMAN),
  PrimaryTeamColor: null,
  TeamTagline: null,
  ProfileImage: null,
  BannerImage: null,
};

export function buildBulkUpdateRequest(edits) {
  const requestBody = Object.assign({}, defaultRequest);
  // Override updated values so we always start with new array
  requestBody.UpdatedValues = [];
  for (const edit of edits) {
    requestBody.UpdatedValues.push(convertEditTypeToDotNetEnum(edit.type));

    switch (edit.type) {
    case EditTypes.BANNER_IMAGE:
      requestBody.BannerImage = convertImageArgsToDotNet(edit.args);
      break;
    case EditTypes.LEAGUE_NAME:
      requestBody.LeagueName = edit.leagueName;
      break;
    case EditTypes.LOGO_IMAGE:
      requestBody.ProfileImage = convertImageArgsToDotNet(edit.args);
      break;
    case EditTypes.TAGLINE:
      requestBody.TeamTagline = edit.teamTagline;
      break;
    case EditTypes.TEAM_CLASS:
      requestBody.TeamLevel = convertTeamLevelToDotNetEnum(edit.teamClass);
      break;
    case EditTypes.TEAM_COLOR:
      requestBody.PrimaryTeamColor = edit.teamColor;
      break;
    case EditTypes.TEAM_NAME:
      requestBody.TeamName = edit.teamName;
      break;
    default:
      break;
    }
  }

  return requestBody;
}

export function convertBulkUpdateResponse(response) {
  const newResponse = {};
  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      newResponse[newKey] = response[key];
    }
  }

  const successfulUpdates = [];
  const failedUpdates = [];

  for (const success of newResponse.successfulUpdates) {
    successfulUpdates.push(convertDotNetEnumToEditType(success));
  }

  for (const failure of newResponse.failedUpdates) {
    failedUpdates.push(convertDotNetEnumToEditType(failure));
  }

  newResponse.successfulUpdates = successfulUpdates;
  newResponse.failedUpdates = failedUpdates;

  return newResponse;
}
