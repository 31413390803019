import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Toast } from 'components/shared/uniform';

const ToastManager = (props) => {
  // Required props
  if (!props || !props.message || !props.onDismiss || !props.type) {
    return null;
  }

  return <Toast {...props} />;
};

// Uniform toast props
ToastManager.propTypes = {
  type: PropTypes.oneOf(['information', 'warning', 'critical', 'confirmation', 'success']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  action: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    handler: PropTypes.func,
    href: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    qaId: PropTypes.string,
  }),
  qaId: PropTypes.string,
  onDismiss: PropTypes.func,
};

const mapStateToProps = (state) => state.toast || {};

export default connect(mapStateToProps)(ToastManager);
