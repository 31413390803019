import React from 'react';
import { Button } from 'components/shared/uniform';

const ShareButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      icon="share"
      size={props.size || 'medium'}
      style={props.minimal ? 'minimal' : null}
      text={props.hideText ? undefined : 'Share'}
      type={props.type}
    />
  );
};

ShareButton.propTypes = {
  onClick: React.PropTypes.func.isRequired,
  hideText: React.PropTypes.bool,
  minimal: React.PropTypes.bool,
  size: React.PropTypes.string,
  type: React.PropTypes.string.isRequired,
};

export default ShareButton;
