import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import HighlightType from './highlight-type';
import * as DialogTypes from 'common/dialog-types';
import { openDialog } from 'common/actions';
import FireIcon from 'components/shared/icon/fire-icon';
import PinIcon from 'components/shared/icon/pin-icon';
import embeddedData from 'hudl-base/src/embedded-data';
import { disablePageScroll, enablePageScroll } from 'utility/display-helpers';
import { Button, Link, Text, Tooltip, Note } from 'components/shared/uniform';
import g11n from 'hudl-g11n';

class Highlight extends React.Component {
  static propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    highlightEditorIdentityData: React.PropTypes.shape({
      userId: React.PropTypes.string,
      teamId: React.PropTypes.string,
      isAdmin: React.PropTypes.bool,
      isAthlete: React.PropTypes.bool,
      isCoach: React.PropTypes.bool,
      sport: React.PropTypes.string,
    }),
    canPinVideos: React.PropTypes.bool,
    durationMs: React.PropTypes.number.isRequired,
    editUrl: React.PropTypes.string,
    showActionButtons: React.PropTypes.bool.isRequired,
    useUniversalReelEditor: React.PropTypes.bool.isRequired,
    universalEditorFeatures: React.PropTypes.arrayOf(React.PropTypes.number),
    ownerType: React.PropTypes.number.isRequired,
    ownerId: React.PropTypes.string.isRequired,
    isPrivilegedUser: React.PropTypes.bool.isRequired,
    userCanAccessPrivate: React.PropTypes.bool.isRequired,
    isRendering: React.PropTypes.bool.isRequired,
    reelId: React.PropTypes.string.isRequired,
    legacyReelId: React.PropTypes.string.isRequired,
    onShareVideoClick: React.PropTypes.func.isRequired,
    onPinHighlight: React.PropTypes.func,
    onUnpinHighlight: React.PropTypes.func,
    isFeaturedHighlight: React.PropTypes.bool,
    title: React.PropTypes.string.isRequired,
    thumbnailUrl: React.PropTypes.string,
    tweetText: React.PropTypes.string,
    type: React.PropTypes.number.isRequired,
    unixTime: React.PropTypes.number.isRequired,
    videoUrl: React.PropTypes.string.isRequired,
    views: React.PropTypes.number.isRequired,
    isAutoGen: React.PropTypes.bool.isRequired,
    reactionCount: React.PropTypes.number.isRequired,
    isDraft: React.PropTypes.bool,
  }

  static defaultProps = {
    universalEditorFeatures: [],
    onPinHighlight: () => {},
    onUnpinHighlight: () => {},
    canPinVideos: false,
  }

  constructor() {
    super();
    this.getDateCreated = this.getDateCreated.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.getReactionCount = this.getReactionCount.bind(this);
    this.getViews = this.getViews.bind(this);
    this.pinHighlight = this.pinHighlight.bind(this);
    this.unpinHighlight = this.unpinHighlight.bind(this);
  }

  editHighlightWithUniversalEditor = (e) => {
    e.preventDefault();
    const {
      ownerType,
      ownerId,
      highlightEditorIdentityData,
      universalEditorFeatures: featurePrivileges,
    } = this.props;

    const options = {
      ownerType,
      ownerId,
      analyticsIdentifyData: highlightEditorIdentityData,
      featurePrivileges,
      endpoints: {
        uploadCredentials: embeddedData.services.highlightsUploadCredentials,
        presign: embeddedData.services.highlightsPresign,
      },
    };

    window.HighlightsEditor.loadReelById(this.props.reelId, options,
      () => location.reload(),
      () => enablePageScroll(),
      () => disablePageScroll()
    );
  }

  getActionButtons() {
    const {isDraft} = this.props;
    const buttons = [
      (<Button
        key="share"
        size="small"
        type="secondary"
        style="minimal"
        collapsePadding="horizontal"
        onClick={this.shareVideo.bind(this)}
        icon="share"
        qaId="Share Reel"
        disabled={isDraft}>
        Share
      </Button>),
    ];

    if (this.props.showActionButtons) {
      const useUniversalReelEditor = this.props.useUniversalReelEditor;
      const type = this.props.type;
      const deleteData = {
        highlightType: this.props.type,
        legacyReelId: this.props.legacyReelId,
      };

      if (type === HighlightType.premium || type === HighlightType.game) {
        if (useUniversalReelEditor) {
          buttons.push(
            <Button
              key="edit"
              size="small"
              type="secondary"
              style="minimal"
              collapsePadding="horizontal"
              onClick={this.editHighlightWithUniversalEditor}
              icon="edit"
              qaId="Edit Reel">
              Edit
            </Button>
          );
        } else {
          buttons.push(
            <Button
              key="edit"
              size="small"
              type="secondary"
              style="minimal"
              collapsePadding="horizontal"
              onClick={()=> {window.location = this.props.editUrl;}}
              icon="edit"
              qaId="Edit Reel">
              Edit
            </Button>
          );
        }
      }

      buttons.push(
        <Button
          key="delete"
          size="small"
          type="secondary"
          style="minimal"
          collapsePadding="horizontal"
          icon="delete"
          qaId="Delete Reel"
          onClick={(e) => {
            e.preventDefault();
            this.props.dispatch(openDialog(DialogTypes.DELETE_HIGHLIGHT_DIALOG, deleteData));
          }}>
          Delete
        </Button>
      );

      if (this.props.canPinVideos) {
        if (!this.props.isFeaturedHighlight) {
          buttons.push(
            <Tooltip position="top" text="Pin to the top of your timeline." parent="icon">
              <Button
                key="pin"
                size="small"
                type="secondary"
                style="minimal"
                collapsePadding="horizontal"
                onClick={this.pinHighlight}
                icon={<PinIcon className="uni-btn__icon" />}
                qaId="Pin Reel"
                disabled={isDraft}>
                Pin
              </Button>
            </Tooltip>
          );
        } else {
          buttons.push(
            <Tooltip position="top" text="Unpin from the top of your timeline." parent="icon">
              <Button
                key="pin"
                size="small"
                type="secondary"
                style="minimal"
                collapsePadding="horizontal"
                icon={<PinIcon className="uni-btn__icon" />}
                onClick={this.unpinHighlight}
                qaId="Unpin Reel">
                Unpin
              </Button>
            </Tooltip>
          );
        }
      }
    }

    return buttons;
  }

  getDateCreated() {
    function capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    let dateCreated;
    if (this.props.unixTime > 0) {
      dateCreated = capitalize(moment.unix(this.props.unixTime).fromNow());
    }

    return dateCreated;
  }

  getDuration() {
    if (this.props.durationMs === 0) {
      return null;
    }
    const durationSeconds = Math.round(this.props.durationMs / 1000.0);
    let seconds = Math.floor(durationSeconds % 60);
    const minutes = Math.floor(durationSeconds / 60);

    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return `${minutes}:${seconds}`;
  }

  getReactionCount() {
    if (!this.props.reactionCount) return null;
    const reactionCount = numeral(this.props.reactionCount).format();
    return (
      <div className="meta-block uni-text--micro">
        <div className="meta-icon fire-icon"><FireIcon /></div> {reactionCount}
      </div>
    );
  }

  getViews() {
    const num = numeral(this.props.views).format();
    const label = this.props.views === 1 ? 'View' : 'Views';
    return num + ' ' + label;
  }

  pinHighlight(e) {
    e.preventDefault();
    this.props.onPinHighlight(this.props.ownerId, this.props.reelId, this.props.legacyReelId);
  }

  unpinHighlight(e) {
    e.preventDefault();
    this.props.onUnpinHighlight(this.props.ownerId, this.props.reelId, this.props.legacyReelId);
  }

  shareVideo(e) {
    e.preventDefault();
    this.props.onShareVideoClick(this.props.reelId, this.props.title, this.props.videoUrl, this.props.tweetText);
    return false;
  }

  getNonEssentialHighlightInfo() {
    if (this.props.isDraft) {
      const FormattedMessage = g11n.ReactIntl.FormattedMessage;
      return (
        <div className="draft">
          <Note size="small" type="warning">
            <FormattedMessage
              id="profiles.highlights.draft.description"
              defaultMessage="This is a draft highlight and needs to be published to make it public."
            />
          </Note>
        </div>
      );
    }
    return (
      <div className="meta-columns">
        <div className="meta meta-left">
          {this.getReactionCount()}
          {this.props.isFeaturedHighlight
            ? (<div className="meta-block uni-text--micro">
                <div className="meta-icon pin-icon"><PinIcon /></div> Pinned
              </div>)
            : null}
        </div>
        <div className="meta meta-right uni-text--micro uni-text--nonessential">
          <span className="meta-text">{this.getDuration()}</span>
          <span className="meta-text">{this.getDateCreated()}</span>
          <span className="meta-text">{this.getViews()}</span>
        </div>
      </div>
    );
  }

  render() {
    const {isDraft, title, videoUrl} = this.props;
    const thumbnailStyle = this.props.thumbnailUrl ? {
      backgroundImage: 'url(' + this.props.thumbnailUrl + ')',
    } : {};
    let renderingSpinner;
    let renderingText;
    if (this.props.isRendering) {
      if (!this.props.isAutoGen && this.props.isPrivilegedUser) {
        thumbnailStyle.backgroundImage = undefined;
        renderingSpinner = <div className="rendering-spinner"></div>;
        renderingText = <p className="rendering-text">Highlight still rendering</p>;
      } else {
        return false;
      }
    }

    return (
      <div className="video-wrapper">
        <div className="video" data-reel-id={this.props.reelId}>
          <a href={isDraft ? null : videoUrl}>
            <div className="thumbnail" style={thumbnailStyle}>
              <div className="thumbnail-gradient">
                {renderingSpinner}
                {renderingText}
              </div>
            </div>
          </a>

          <div className="video-info">
            <Text level="large" className="title">
              { isDraft ?
                title :
                (<Link type="implied" href={videoUrl} className="video-link">
                  {title}
                </Link>)
              }
            </Text>
            {this.getNonEssentialHighlightInfo()}
          </div>
          <div className="video-actions uni-text--micro">
            {this.getActionButtons()}
          </div>
        </div>
      </div>
    );
  }
}

export default Highlight;
