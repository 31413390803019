import { data } from 'hudl-base';

export const IS_RECRUIT_SHARING_ENABLED = data.getNullable('recruitProfileSharingIsEnabled') || false;

export const checkCanSaveProspect = (state) => {
  const recruiterAccess = state.recruiterAccess;

  return recruiterAccess
    && recruiterAccess.teamIdForSavingProspect
    && recruiterAccess.athleteUserId;
};
