import React from 'react';
import { Service } from 'hudl-base';
import { removeHighlight } from 'common/actions';
import { Alert } from 'components/shared/uniform';

import './delete-highlight-dialog.scss';

class DeleteHighlightDialog extends React.Component {
  static propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    highlightType: React.PropTypes.number.isRequired,
    legacyReelId: React.PropTypes.string.isRequired,
    onClose: React.PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.deleteHighlight = this.deleteHighlight.bind(this);
  }

  deleteHighlight() {
    new Service('deleteHighlight', { legacyReelId: this.props.legacyReelId })
      .delete()
      .fail(() => {
        // FIXME - Show a message to the user
      })
      .done(() => {
        this.props.dispatch(removeHighlight(this.props.legacyReelId));
        this.props.onClose();
      });
  }

  render() {
    return (
      <Alert
        isOpen
        text={`You\'re about to delete this highlight reel from your profile.
          You\'ll need to recreate it from your starred clips if you decide you want it back.
          Are you sure you want to remove it?`}
        actions={[
          {
            type: 'subtle',
            label: 'Cancel',
            onClick: this.props.onClose,
          },
          {
            type: 'destroy',
            label: 'Yes, Delete',
            onClick: this.deleteHighlight,
          },
        ]}
      />
    );
  }
}

export default DeleteHighlightDialog;
