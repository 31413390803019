/* eslint react/no-multi-comp: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { AvatarTeam, Subhead, SingleSelect, Text } from 'components/shared/uniform';
import teamShape from 'components/shared/profile-tabs/games/teamShape';
import { groupBy } from 'underscore';
import { getSportByName } from 'components/shared/uniform/sport-helpers';

import styles from './team-selection.scss';

/* eslint-disable react/prop-types */
const teamOptionView = ({ optionData }) => {
  const team = optionData.team;
  const teamLogo = (
    <AvatarTeam
      className="uni-margin--half--right"
      size="small"
      imageUrl={team.teamLogoUri}
      sport={getSportByName(team.sportName)}
      primaryColor={team.primaryColor}
      secondaryColor={team.secondaryColor}
    />
  );

  return (
    <div className={styles['team-selection__team']}>
      {teamLogo}
      <div>
        <span className={styles['team-selection__organization']}>
          <Text level="small">
            {team.organizationName || ''}
          </Text>
        </span>
        <span className={styles['team-selection__team-name']}>
          <Text color="nonessential" level="micro">
            {team.teamName || ''}
          </Text>
        </span>
      </div>
    </div>
  );
};
/* eslint-enable react/prop-types */

const TeamSelection = ({ teams, selectedOption, onChange, disabled }) => {
  if (!teams || !teams.length || teams.length < 2) {
    return null;
  }

  const groupedBySport = groupBy(teams, team => team.sportName);
  const groupedBySportOptions = {};

  Object.keys(groupedBySport).forEach(sport => {
    const sportTeamGroup = groupedBySport[sport];

    const sportTeamOptions = sportTeamGroup
      .map(tg => ({
        value: tg.teamId,
        label: tg.teamName,
        team: tg,
      }));

    groupedBySportOptions[sport] = {
      label: sport,
      options: sportTeamOptions,
    };
  });

  return (
    <div className={styles['team-selection']}>
      <SingleSelect
        value={selectedOption}
        options={groupedBySportOptions}
        customOption={teamOptionView}
        hideDismiss
        label={<Subhead level="small">Team</Subhead>}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

TeamSelection.propTypes = {
  teams: PropTypes.arrayOf(teamShape),
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TeamSelection;
