import { connect } from 'react-redux';
import TeamHistoryCard from './TeamHistoryCard';

const mapStateToProps = (state) => {
  return {
    teams: state.about.overview.teams,
    athleteName: state.user.firstName,
  };
};

export default connect(mapStateToProps)(TeamHistoryCard);
