import PropTypes from 'prop-types';

const gameShape = PropTypes.shape({
  opponent: PropTypes.shape({
    name: PropTypes.string,
    schoolName: PropTypes.string,
  }),
  eventAt: PropTypes.string,
  cutups: PropTypes.arrayOf(PropTypes.shape({
    thumbnailPath: PropTypes.string,
  })),
  seasonLabel: PropTypes.seasonLabel,
  event: PropTypes.shape({
    eventId: PropTypes.string,
    teamId: PropTypes.string,
    breakdownId: PropTypes.string,
  }),
});

export default gameShape;
