import { TOAST__DISMISS, TOAST__SHOW } from 'common/actions';

export default function toastReducer(state = null, action) {
  switch (action.type) {
  case TOAST__SHOW:
    return action.payload;
  case TOAST__DISMISS:
    return null;
  default:
    return state;
  }
}
