import React from 'react';
import PropTypes from 'prop-types';
import { Card, Subhead } from 'components/shared/uniform';
import AthleticismFields from 'common/athleticism-fields';
import CardStat from '../CardStat';
import ExpandableTextList from '../ExpandableLists/ExpandableTextList';

import styles from './styles.scss';

const AthleticismCard = ({ athleticism }) => {
  if (!athleticism) {
    return null;
  }

  const fields = [];
  for (const fieldKey in AthleticismFields) {
    if (!AthleticismFields.hasOwnProperty(fieldKey)) {
      continue;
    }

    const field = AthleticismFields[fieldKey];

    // Get value from athleticism tab using the key in AthleticismFields
    let value = athleticism[fieldKey];

    // If we have a value, add the cell
    if (value) {
      // Check if field should ever be verified, and if so, check if the
      // field in props has the verifyKey set to true.
      const isVerified = !!field.verifyKey && athleticism[field.verifyKey];

      // If the field has units, append those to the value
      if (field.units) {
        value = value + field.units;
      }

      // Add field to array
      fields.push(
        <CardStat
          label={field.label}
          value={value}
          isVerified={isVerified}
        />
      );
    }
  }

  const achievements = athleticism.achievements || [];

  if (!fields.length && !achievements.length) {
    return null;
  }

  const header = (
    <Subhead level="default" className={styles['athleticism-card__header']}>
      Athleticism
    </Subhead>
  );

  return (
    <div className={styles['athleticism-card']} data-qa-id="athleticism-card">
      <Card header={header}>
        <div className={styles['athleticism-card__body']}>
          {fields}
        </div>
        <div>
          <ExpandableTextList
            header="Athletic Achievements"
            items={achievements}
            isDefaultOpen={achievements.length === 1}
          />
        </div>
      </Card>
    </div>
  );
};

AthleticismCard.propTypes = {
  athleticism: PropTypes.object,
};

export default AthleticismCard;
