// Common functions shared between the community-content-logger and feed-user-logger
import AdTrackingUtil from './ad-tracking-utility';
import ContainerPlayer from 'common/container-player';
import ContainerType from 'common/container-type';

let sessionReferrerUrl = '';

export function getReferrerRoot(referrerUrl) {
  if (!referrerUrl) return '';
  let hostname = '';

  try {
    const a = document.createElement('a');
    a.href = referrerUrl;
    hostname = a.hostname;
  } catch (err) {
    return '';
  }

  const parts = hostname.split('.');

  // Note: .co.uk and .k12.$$.us are by far our most common compound TLDs
  if (hostname.match(/.co.uk$/)) {
    return parts.slice(parts.length - 3).join('.');
  } else if (hostname.match(/.k12.*.us$/)) {
    return parts.slice(parts.length - 4).join('.');
  }

  return parts.slice(parts.length - 2).join('.');
}

function getSessionReferrerUrl() {
  const referrerUrl = document.referrer;

  try {
    const sessionReferrerJson = window.sessionStorage.getItem('communityContentLogger.sessionReferrer');
    if (sessionReferrerJson) {
      sessionReferrerUrl = JSON.parse(sessionReferrerJson);
    } else {
      const referrerRoot = getReferrerRoot(referrerUrl);
      if (referrerRoot !== 'hudl.com' && referrerRoot !== 'thorhudl.com') {
        sessionReferrerUrl = referrerUrl;
        window.sessionStorage.setItem('communityContentLogger.sessionReferrer', JSON.stringify(sessionReferrerUrl));
      }
    }
  } catch (e) {
    console.error(e);
    sessionReferrerUrl = '';
  }

  return sessionReferrerUrl;
}

export function getBaseProperties() {
  const isInIframe = parent !== window;
  const referrerUrl = document.referrer;
  const containerUrl = isInIframe ? document.referrer : window.location.href;

  const adTrackingId = AdTrackingUtil.getAdTrackingIdAndSetCookie();
  let player = ContainerPlayer.EmbeddablePlayer;
  let container = ContainerType.EmbeddablePlayer;
  let containerSection = '';
  let containerType = '';

  if (containerUrl) {
    if (containerUrl.match(/video\//) || containerUrl.match(/(athlete)\/\d+\/highlights\/\d+/)) {
      player = ContainerPlayer.VideoPage;
      container = ContainerType.VideoPage;
    } else if (containerUrl.match(/highlights\/manage/)) {
      player = ContainerPlayer.VideoManagementPlayer;
      container = ContainerType.VideoManagement;
      containerSection = 'Videos';
    } else if (containerUrl.match(/athlete\/\d+/) || containerUrl.match(/athlete\/o\/\d+/)
      || containerUrl.match(/profile\/\d+/)) {
      player = ContainerPlayer.TimelineCard;
      container = ContainerType.Profile;
      containerSection = 'Timeline';
      containerType = 'User';
    } else if (containerUrl.match(/team\/\d+/) || containerUrl.match(/team\/v2/)) {
      player = ContainerPlayer.TimelineCard;
      container = ContainerType.Profile;
      containerSection = 'Timeline';
      containerType = 'Team';
    } else if (containerUrl.match(/dashboard\/\d+/) || containerUrl.match(/home\/\d+/)
      || containerUrl.match(/\/home$/)) {
      player = ContainerPlayer.TimelineCard;
      container = ContainerType.Home;
      containerSection = 'Feed';
    } else if (containerUrl.match(/\/page\//)) {
      player = ContainerPlayer.TimelineCard;
      container = ContainerType.Profile;
      containerSection = 'Timeline';
      containerType = 'Author';
    } else if (containerUrl.match(/explore\//) || containerUrl.match(/\/explore/)) {
      player = ContainerPlayer.TimelineCard;
      container = ContainerType.Explore;
    }
  }

  return {
    adTrackingId,
    player,
    container,
    containerSection,
    containerType,
    referrerUrl,
    sessionReferrerUrl,
  };
}

sessionReferrerUrl = getSessionReferrerUrl();

export default {
  getBaseProperties,
  getReferrerRoot,
};
