import StorageUtility from './storage-utility';

const RecentTeamIdsKey = 'recentTeamIds';
const MaxRecentTeams = 5;

const PersonalizationUtility = {
  addRecentTeamId: (teamId) => {
    if (!teamId) {
      return;
    }

    let recentTeamIds = PersonalizationUtility.getRecentTeamIds();

    if (!recentTeamIds.some(t => t === teamId)) {
      recentTeamIds.push(teamId);

      if (recentTeamIds.length > MaxRecentTeams) {
        const numToRemove = recentTeamIds.length - MaxRecentTeams;
        recentTeamIds = recentTeamIds.slice(numToRemove);
      }
      StorageUtility.set(RecentTeamIdsKey, recentTeamIds);
    }
  },
  getRecentTeamIds: () => {
    const recentTeamIdsString = StorageUtility.get(RecentTeamIdsKey) || '';

    if (!recentTeamIdsString) {
      return [];
    }

    const recentTeamIds = recentTeamIdsString.split(',');
    return recentTeamIds;
  },
};

export default PersonalizationUtility;
