import React from 'react';
import './button-group.scss';

class ButtonGroup extends React.Component {
  static propTypes = {
    buttons: React.PropTypes.arrayOf(React.PropTypes.shape({
      label: React.PropTypes.string.isRequired,
      value: React.PropTypes.string.isRequired,
      isSelected: React.PropTypes.bool,
    })).isRequired,
    onButtonPressed: React.PropTypes.func.isRequired,
  }

  getButtons() {
    const selectedButton = this.props.buttons.find(button => button.isSelected) || this.props.buttons[0];

    return this.props.buttons.map(button => {
      const isSelected = selectedButton.value === button.value;

      return (
        <div
          key={button.value}
          className={'button-group-toggle' + (isSelected ? ' button-group-toggle--selected' : '')}
          onClick={() => this.props.onButtonPressed(button.value)}>
          {button.label}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="button-group uni-btn__group--combo">
        {this.getButtons()}
      </div>
    );
  }
}

export default ButtonGroup;
