/* eslint-disable max-len */
import React from 'react';

const FollowIcon = (props) => {
  return (
    <svg className={props.className} viewBox="-2 -4 34 32">
      <g transform="translate(-247.000000, 0.000000)">
        <path d="M252,8 L252,5 L250,5 L250,8 L247,8 L247,10 L250,10 L250,13 L252,13 L252,10 L255,10 L255,8 L252,8 Z M265.865266,12.6271213 C270.31398,13.6998495 271,16.8430826 271,20.553614 C271,25.1487953 253,25.1487953 253,20.553614 C253,16.8430826 253.68602,13.6998495 258.134734,12.6271213 C258.962165,14.0010871 260.384369,14.9077723 262,14.9077723 C263.615631,14.9077723 265.037835,14.0010871 265.865266,12.6271213 L265.865266,12.6271213 Z M267.235354,5.6053281 C267.235354,2.50959087 264.943121,0 262.115509,0 C259.287897,0 256.995664,2.50959087 256.995664,5.6053281 C256.995664,8.70106532 259.287897,12.9964158 262.115509,12.9964158 C264.943121,12.9964158 267.235354,8.70106532 267.235354,5.6053281 Z" id="follow"></path>
      </g>
    </svg>
  );
};

FollowIcon.propTypes = {
  className: React.PropTypes.string,
};

export default FollowIcon;
