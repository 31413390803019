import React from 'react';
import classNames from 'classnames';
import { Modal, Note, Spinner, Text } from 'components/shared/uniform';

import './image-upload-modal.scss';

const isEdge = window.navigator.userAgent.toLowerCase().indexOf('edge') !== -1;
class ImageUploadModal extends React.Component {
  static propTypes = {
    onClose: React.PropTypes.func.isRequired,
    onImageUploaded: React.PropTypes.func.isRequired,
    title: React.PropTypes.string,
    className: React.PropTypes.string.isRequired,
    recommendedWidth: React.PropTypes.number.isRequired,
    recommendedHeight: React.PropTypes.number.isRequired,
    minWidth: React.PropTypes.number.isRequired,
    minHeight: React.PropTypes.number.isRequired,
    hideCroppedWarning: React.PropTypes.bool,
    isSquare: React.PropTypes.bool,
    errorMessage: React.PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
      progress: 0,
    };
  }

  componentDidMount() {
    const qq = window.__hudlProfiles && window.__hudlProfiles.qq;
    if (!qq) {
      return;
    }

    this.dragAndDropModule = new qq.DragAndDrop({
      dropZoneElements: [this.dropZoneNode],
      classes: {
        dropActive: 'drag-over',
      },
      callbacks: {
        processingDroppedFiles: () => {
          this.setState({
            inProgress: true,
            progress: 0,
            error: false,
            errorReason: null,
          });
        },
        processingDroppedFilesComplete: (files) => {
          this.uploader.addFiles(files);
        },
      },
    });

    this.uploader = new qq.FineUploaderBasic({
      button: this.imageSelectButton,
      debug: true,
      camera: {
        ios: true,
      },
      multiple: false,
      validation: {
        acceptFiles: [
          'image/jpeg',
          'image/pjpeg',
          'image/png',
          'image/gif',
          'image/bmp',
          'image/x-windows-bmp',
        ],
        sizeLimit: 1024 * 1024 * 10,
        allowedExtensions: ['jpeg', 'jpg', 'png', 'gif', 'bmp'],
        image: {
          minHeight: this.props.minHeight,
          minWidth: this.props.minWidth,
        },
      },
      request: {
        endpoint: '/Services/AvatarUploader.ashx',
      },
      messages: {
        emptyError: 'The picture you uploaded is empty. Please choose a different photo.',
        minHeightImageError: 'The picture you uploaded is too small. '
          + `Please choose a photo that\'s at least ${this.props.minWidth} x ${this.props.minHeight}.`,
        minWidthImageError: 'The picture you uploaded is too small. '
          + `Please choose a photo that\'s at least ${this.props.minWidth} x ${this.props.minHeight}.`,
        noFilesError: 'No files were given to upload.',
        sizeError: 'The picture you uploaded is too large. Please choose a photo that\'s less than 10MB in size.',
        typeError: 'The uploaded image file needs to be a jpeg, jpg, gif, or bmp. ' +
          'Try again with one of these file types.',
      },
      callbacks: {
        onComplete: (id, fileName, response) => {
          this.setState({
            inProgress: false,
            progress: 0,
          });

          if (response.success) {
            this.props.onImageUploaded(response.url, response.id);
          }
        },
        onError: (id, name, errorReason) => {
          this.setState({
            error: true,
            errorReason,
            inProgress: false,
            progress: 0,
          });
        },
        onProgress: (id, name, uploadedBytes, totalBytes) => {
          const progressPercent = Math.floor(uploadedBytes / totalBytes * 100);
          this.setState({
            inProgress: true,
            progress: progressPercent,
          });
        },
        onSubmit: () => {
          this.setState({
            inProgress: true,
            progress: 0,
            error: false,
            errorReason: null,
          });
        },
      },
    });
  }

  renderUploadArea() {
    const classes = classNames({
      'upload-area': true,
      'in-progress': this.state.inProgress,
      'is-square': !!this.props.isSquare,
    });

    let croppedWarning;
    if (!this.props.hideCroppedWarning) {
      croppedWarning = (
        <p className="image-upload-additional-info">
          Some areas may be cropped based on screen size.
        </p>
      );
    }

    return (
      <div className={classes}>
        <div className="image-upload-instructions">
          <Text>
            <div className="drag-instructions">
              Drag and drop an image
            </div>
            <div className="drop-instructions">
              Drop image from your computer here
            </div>
            <div className="or-instruction">
              or
            </div>
            <span className="upload-an-image uni-link--default" ref={ref => this.imageSelectButton = ref}>
              {isEdge ? 'Upload an image' : 'find the file'}
            </span>
            <span className="or-instruction"> on your computer.</span>
          </Text>
        </div>
        <div className="image-upload-in-progress">
          <Spinner size="Large" spinningState="spinning" />
        </div>
        <div className="image-upload-requirements-text">
          <Text level="micro">
            <p className="image-upload-min-dimensions">
              Recommended image size is at least {this.props.recommendedWidth} x {this.props.recommendedHeight} pixels.
            </p>
            <p className="image-upload-max-file-size">
              Maximum file size is 10MB.
            </p>
            {croppedWarning}
          </Text>
        </div>
      </div>
    );
  }

  render() {
    let error;
    if (this.state.error) {
      error = <Note type="critical">{this.state.errorReason}</Note>;
    }
    if (this.props.errorMessage) {
      error = <Note type="critical">{this.props.errorMessage}</Note>;
    }

    return (
      <Modal
        isOpen
        title={this.props.title || 'Upload an Image'}
        showCancelAction
        onClose={this.props.onClose}
      >
        {error}
        <div className="image-upload-content">
          <div className="image-upload-area" ref={(ref) => this.dropZoneNode = ref}>
            {this.renderUploadArea()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ImageUploadModal;
