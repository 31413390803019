// this is the order in which they will show up on the timeline tab of profiles
// the key should be the name in StrengthAndSpeed.cs and the value is the string
// that should be displayed as the title
const athleticismFields = {
  nikeFootballRating: {
    label: 'Nike Football Rating',
    verifyKey: 'nikeFootballRatingVerified',
  },
  forty: {
    label: '40 Yard Dash',
    verifyKey: 'fortyVerified',
  },
  shuttle: {
    label: 'Shuttle',
    verifyKey: 'shuttleVerified',
  },
  vertical: {
    label: 'Vertical',
    verifyKey: 'verticalVerified',
    units: '"',
  },
  powerball: {
    label: 'Powerball',
    verifyKey: 'powerballVerified',
    units: '\'',
  },
  bench: {
    label: 'Bench',
    verifyKey: 'benchVerified',
  },
  benchPressReps: {
    label: '185lb Bench Press (Reps)',
    verifyKey: 'benchPressRepsVerified',
  },
  meter100: {
    label: '100 Meter Dash',
  },
  meter400: {
    label: '400 Meter Dash',
  },
  squat: {
    label: 'Squat (lbs)',
  },
  deadLift: {
    label: 'Deadlift (lbs)',
  },
  clean: {
    label: 'Clean (lbs)',
  },
  // Volleyball-specific metrics
  standingReach: {
    label: 'Standing Reach (Inches)',
  },
  standingBlockingReach: {
    label: 'Standing Blocking Reach (Inches)',
  },
  verticalJumpOneArm: {
    label: 'Vertical Jump 1 Arm (Inches)',
  },
  verticalJumpingBlockTwoArms: {
    label: 'Block Touch 2 Arms (Inches)',
  },
  approachJumpTouchOneArm: {
    label: 'Approach Touch 1 Arm (Inches)',
  },
  sixTouchesSidelineToSideline: {
    label: '6 Touches Sideline to Sideline',
  },
};

export default athleticismFields;
