import $ from 'jquery';
import {
  SUCCESS_ATHLETE_GAMES,
  FAIL_ATHLETE_GAMES,
  LOAD_ATHLETE_GAMES,
  CLEAR_ATHLETE_GAMES,
  CHANGE_GAMES_TEAM_VIEW,
  CHANGE_GAMES_SEASON_VIEW,
} from 'common/actions';

function games(state = {}, action) {
  const newState = $.extend(true, {}, state);
  switch (action.type) {
  case LOAD_ATHLETE_GAMES:
    newState.loading = true;
    newState.error = false;
    newState.fullGames = null;
    break;
  case CLEAR_ATHLETE_GAMES:
    newState.fullGames = null;
    break;
  case SUCCESS_ATHLETE_GAMES:
    newState.error = false;
    newState.loading = false;
    newState.fullGames = action.payload;

    if (newState.fullGames) {
      const selectedTeam = newState.teams[newState.selectedTeamId];
      const selectedSeason = selectedTeam.seasons[newState.selectedSeasonId];

      if (!newState.fullGames[selectedSeason.label]) {
        let seasonWithGames = null;
        Object.values(selectedTeam.seasons).forEach((season) => {
          if (season.label === selectedSeason.label) {
            return;
          }
          if (newState.fullGames[season.label]) {
            if (seasonWithGames && seasonWithGames.year > season.year) {
              return;
            }

            seasonWithGames = season;
          }
        });
        if (seasonWithGames) {
          newState.selectedSeasonId = seasonWithGames.seasonId;
        }
      }
    }
    break;
  case FAIL_ATHLETE_GAMES:
    newState.error = true;
    newState.loading = false;
    newState.fullGames = action.payload;
    break;
  case CHANGE_GAMES_TEAM_VIEW:
    newState.selectedTeamId = action.payload.teamId;
    newState.selectedSeasonId = action.payload.seasonId;
    break;
  case CHANGE_GAMES_SEASON_VIEW:
    newState.selectedSeasonId = action.payload.seasonId;
    break;
  default:
    return state;
  }
  return newState;
}

export default games;
