import React from 'react';

const CreateHighlightMessage = (props) => {
  return (
    <div className="tab-blank-state">
      <h1>You haven't created any highlights. Get started now.</h1>

      <div className="image-group">
        <figure>
          <img src="https://static.hudl.com/profiles/images/highlights/mark-highlight_2x.png"/>
          <figcaption>
            Highlight awesome plays
          </figcaption>
        </figure>
        <figure>
          <img src="https://static.hudl.com/profiles/images/highlights/add-spot-shadow_2x.png"/>
          <figcaption>
            Add outstanding effects
          </figcaption>
        </figure>
        <figure>
          <img src="https://static.hudl.com/profiles/images/highlights/save-highlight_2x.png"/>
          <figcaption>
            Public to the world
          </figcaption>
        </figure>
      </div>

      <p>
        Highlights you create will show up here.<br/>
        You can then combine several into an awesome reel<br/>
        to share with teammates, recruiters, and fans.
      </p>

      <br/>
      <a className="uni-btn uni-btn--primary uni-btn--small" href={props.libraryUrl}>Highlight your best plays</a>
      <br/>
      <br/>
      <a className="uni-btn uni-btn--subtle" href={props.highlightSupportUrl}>Learn more about highlights</a>
    </div>
  );
};

CreateHighlightMessage.propTypes = {
  highlightSupportUrl: React.PropTypes.string.isRequired,
  libraryUrl: React.PropTypes.string.isRequired,
};

export default CreateHighlightMessage;
