import React from 'react';
import {
  openDialog,
  tempBannerImageUploaded,
  tempProfileImageUploaded,
} from 'common/actions';

import * as DialogTypes from 'common/dialog-types';
import CustomizeProp from 'common/prop-types/customize';
import FeedbackSurvey from './feedback/feedback-survey';
import FeedUserTypes from 'common/feed-user-type';
import ReactDOM from 'react-dom';

import EndOfTimeline from 'components/shared/end-of-timeline/end-of-timeline';
import * as HudlFeed from 'hudl-feed';
const { UserTimeline } = HudlFeed;
const { addFeedItems, receiveUserTimeline } = HudlFeed.actions.creators;

import './timeline.scss';

class Timeline extends React.Component {
  static propTypes = {
    bannerImage: React.PropTypes.string.isRequired,
    canDismissFeedItems: React.PropTypes.bool,
    currentUserId: React.PropTypes.string,
    customize: CustomizeProp.isRequired,
    desktopDisplayAdId: React.PropTypes.string,
    dismissClickFeedback: React.PropTypes.func,
    dismissFeedback: React.PropTypes.func,
    dispatch: React.PropTypes.func.isRequired,
    feedback: React.PropTypes.shape({
      feedbackEnabled: React.PropTypes.bool.isRequired,
      feedbackSurveyUrl: React.PropTypes.string.isRequired,
      optionSelected: React.PropTypes.bool,
    }).isRequired,
    feedContentItems: React.PropTypes.arrayOf(React.PropTypes.object),
    feedUser: React.PropTypes.shape({
      firstName: React.PropTypes.string,
      lastName: React.PropTypes.string,
      profileUrlBase: React.PropTypes.string,
      profileLogoUri: React.PropTypes.string,
      type: React.PropTypes.oneOf(Object.keys(FeedUserTypes).map(key => FeedUserTypes[key])),
      userId: React.PropTypes.string,
      teamId: React.PropTypes.string,
      teamName: React.PropTypes.string,
      feedUserId: React.PropTypes.object.isRequired,
    }),
    forcePublicView: React.PropTypes.bool,
    isMobile: React.PropTypes.bool.isRequired,
    isPrivilegedUser: React.PropTypes.bool.isRequired,
    logData: React.PropTypes.object,
    mobileDisplayAdId: React.PropTypes.string,
    pinnedFeedItem: React.PropTypes.element,
    profileDateCreated: React.PropTypes.string,
    profileFuzzyDateCreated: React.PropTypes.string,
    presetFilterType: React.PropTypes.number,
    ads: React.PropTypes.object,
  }

  static contextTypes = {
    store: React.PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      scrollable: null,
    };
  }

  componentWillMount() {
    const { store } = this.context;
    let feedItems = this.props.feedContentItems || [];
    const { feedUserId } = this.props.feedUser;

    if (this.props.isMobile) {
      // Limit to 5 posts on mobile
      feedItems = feedItems.slice(0, 5);
    }

    store.dispatch(addFeedItems(feedItems));
    store.dispatch(receiveUserTimeline(feedUserId.type, feedUserId.relatedId, feedItems));
  }

  componentDidMount() {
    this._setScrollableParent();
  }

  _setScrollableParent() {
    const { scrollable } = this.state;
    if (!scrollable) {
      // Violates setState in componentDidMount, but React DOM needs
      // a mounted component.
      this.setState({ scrollable: this._findScrollableParent() });
    }
  }

  _findScrollableParent() {
    let node = ReactDOM.findDOMNode(this);
    while (node &&
      node.parentNode !== null &&
      (!node.classList ||
        !node.classList.contains('parallax'))) {
      node = node.parentNode;
    }
    return node;
  }

  onLogoUploaded(imageSrc, imageId) {
    const dispatch = this.props.dispatch;
    dispatch(tempProfileImageUploaded(imageSrc, imageId));
    dispatch(openDialog(DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG));
  }

  onBannerUploaded(imageSrc, imageId) {
    const dispatch = this.props.dispatch;
    dispatch(tempBannerImageUploaded(imageSrc, imageId));
    dispatch(openDialog(DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG));
  }

  renderFeedbackSurvey(notFirst) {
    return (
      <FeedbackSurvey
        key="feedback-intro-card"
        notFirstCard={notFirst}
        onDismiss={this.props.dismissFeedback}
        feedbackOnClick={this.props.dismissClickFeedback}
        feedbackUrl={this.props.feedback.feedbackSurveyUrl}
      />
    );
  }

  renderContents() {
    // TODO: EH - we need to clean this stuff up since it's mostly unused
    // const contents = [];
    // if (this.props.pinnedFeedItem) {
    //   contents.push(this.props.pinnedFeedItem);
    // }
    // if (this.props.feedback.feedbackEnabled) {
    //   contents.push(this.renderFeedbackSurvey(false));
    // } else if (this.props.feedback.feedbackEnabled) {
    //   contents.push(this.renderFeedbackSurvey(false));
    // } else if (this.props.isPrivilegedUser
    // && !this.props.forcePublicView) {
    //   document.querySelector(userEndOfTimeline).style.display = '';
    // }

    const { store } = this.context;
    const { scrollable } = this.state;

    // We don't display an end of timeline card for Authors at the moment
    let endOfTimelineCard = null;
    if (this.props.feedUser.type !== FeedUserTypes.Hudl) {
      endOfTimelineCard = (
        <EndOfTimeline
          key="end-of-timeline"
          feedUser={this.props.feedUser}
          dateCreated={this.props.profileDateCreated}
          fuzzyDateCreated={this.props.profileFuzzyDateCreated}
        />
      );
    }

    const miniProfileEnabled = false;
    const canDismissFeedCards = this.props.canDismissFeedItems || false;
    const presetFilterType = this.props.presetFilterType || 0;

    // This is a bit hacky to render a empty div, but
    // allows the component to mount which will allow us to find
    // the scrollable parent.
    return scrollable
      ? (
        <UserTimeline
          adTag={this.props.ads}
          canDismissFeedItems={canDismissFeedCards}
          filterType={presetFilterType}
          forcePublicView={this.props.forcePublicView}
          feedUserId={this.props.feedUser.feedUserId}
          endOfTimeline={endOfTimelineCard}
          logData={this.props.logData}
          miniProfileEnabled={miniProfileEnabled}
          waypointScrollableAncestor={scrollable}
          store={store} />
      )
      : <div></div>;
  }

  render() {
    return (
      <div className="profile-feed compact-margin">
        {this.props.pinnedFeedItem ? this.props.pinnedFeedItem : null}
        {this.renderContents()}
      </div>
    );
  }
}

export default Timeline;
