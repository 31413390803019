import { data } from 'hudl-base';

export default class DisplayAdModel {
  constructor(id, path) {
    this._id = id;
    this._path = path;
  }

  getId() {
    return this._id;
  }

  getPath() {
    const map = data.get('ads').overridePaths || {};
    return map[this._path.toLowerCase()] || this._path;
  }
}
