import PropTypes from 'prop-types';
import seasonShape from './seasonShape';

const teamShape = PropTypes.shape({
  seasons: PropTypes.arrayOf(seasonShape),
  defaultSeasonId: PropTypes.string,
  shouldUpsellRecruit: PropTypes.bool,
  canUseBreakdownData: PropTypes.bool,
  canUseRecruitVideoExperience: PropTypes.bool,
  teamName: PropTypes.string,
  teamId: PropTypes.string,
  recruitTeamId: PropTypes.string,
  organizationName: PropTypes.string,
  teamLogoUri: PropTypes.string,
  sportName: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
});

export default teamShape;
