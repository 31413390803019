import { Modal, Subhead, Text, Button } from 'hudl-uniform-ui-components';
import React, { Component } from 'react';
import g11n from 'hudl-g11n';
import style from './style.scss';

const FormattedMessage = g11n.ReactIntl.FormattedMessage;
class DeleteClipsModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal
                isOpen={!this.props.isOpen}
                onClose={() => this.props.onClose(true)}
                title={<FormattedMessage id="profiles.delete_clip_modal.title" defaultMessage="Delete Highlight Clips"/>}
                actions={<Button
                    onClick={() => {
                        this.props.onClose(true);
                    }}
                    type="primary"
                    text={<FormattedMessage id="profiles.delete_clip_modal.button" defaultMessage="Got it!"/>}
                />}
                showCancelAction={false}
            >
                <div className={style.ContentWrapper}>
                    <img alt="" className={style.ImageStyle}/>
                    <div className={style.Content}>
                        <Subhead>
                            {<FormattedMessage id="profiles.delete_clip_modal.header" defaultMessage="Manage Your Highlights"/>}
                        </Subhead>
                        <Text className={style.TextContainer}>
                            {<FormattedMessage id="profiles.delete_clip_modal.body" defaultMessage="You can now delete clips that have been saved to your highlights.
                            The clip will also be removed from any published reels."/>}
                        </Text>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default DeleteClipsModal;
