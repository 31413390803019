import React from 'react';
import ClipIcon from 'hudl-react-kickoff/icons/Clip';
import FollowButton from 'components/shared/buttons/follow-button';
import {
  followFeedUser,
  unfollowFeedUser,
} from 'common/actions';

const UniformUiComponents = window.__uniformui_Components;

class NoVideosMessage extends React.Component {
  static propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    userId: React.PropTypes.string.isRequired,
    userName: React.PropTypes.string.isRequired,
    user: React.PropTypes.object,
    onChangeTab: React.PropTypes.func.isRequired,
  }

  _onFollowUnfollow(feedUserType, feedUserRelatedId, isFollow) {
    if (isFollow) {
      this.props.dispatch(followFeedUser(feedUserType, feedUserRelatedId, true));
    } else {
      this.props.dispatch(unfollowFeedUser(feedUserType, feedUserRelatedId, true));
    }
  }

  render() {
    let canFollow = false;
    let message;
    if (this.props.user.userId && !this.props.user.isFollowing) {
      // If the user can follow the team but isn't
      canFollow = true;
      message = (
        <span>
          <p>
            Follow them to add them to your feed and always know when they post something.
          </p>
          <p>
            Until then, why don't you find out more about them?
          </p>
        </span>
      );
    } else {
      message = (
        <span>
          <p>
            Check back later. Until then, why don't you find out more about them?
          </p>
        </span>
      );
    }

    return (
      <div className="tab-blank-state">
        <h1>{this.props.userName} has no Highlights</h1>
        <ClipIcon className="clip-icon blank-icon"/>
        <p>
          {this.props.userName} hasn't shared any highlights yet.
        </p>
        {message}
        <div className="btn-row">
          <UniformUiComponents.Button
            onClick={(e) => {
              e.preventDefault();
              this.props.onChangeTab('about');
            }}
            size="small"
            type={canFollow ? 'secondary' : 'primary'}
            text="Learn More"
          />
          {
            canFollow ?
              <FollowButton
                feedUserTypeId={3} // user
                feedUserRelatedId={this.props.userId}
                isFollowing={this.props.user.isFollowing}
                requesting={!!this.props.user.pendingTeamFollowRequest}
                error={this.props.user.followRequestError}
                onClick={this._onFollowUnfollow.bind(this)}
                hideText={false}
                size="small"
                primary
                allowUnfollow
              />
              : null
          }
        </div>
      </div>
    );
  }
}

export default NoVideosMessage;
