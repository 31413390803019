import $ from 'jquery';
import {
  CHANGE_ABOUT_TAB,
  EXPAND_PAST_TEAMS,
} from 'common/actions';

function about(state = {}, action) {
  const newState = $.extend(true, {}, state);
  switch (action.type) {
  case CHANGE_ABOUT_TAB:
    newState.activeTab = action.payload.tab;
    break;
  case EXPAND_PAST_TEAMS:
    newState.pastTeamsExpanded = true;
    break;
  default:
    return state;
  }
  return newState;
}

export default about;
